import React, { ReactElement, useEffect, useState } from "react";
import "./MesClients.scss";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { SiPypy } from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import { data } from "../../constants";
import {
  RiAddLine,
  RiCalendar2Line,
  RiSearchLine,
  RiUser3Line,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Select, { GroupBase, SingleValue } from "react-select";
import userAddIcon from "../../assets/images/svg/user-t1.svg";
import { BootyPagination } from "../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import CrownIcon from "../../assets/CrownIcon";
import EmailIcon from "../../assets/EmailIcon";
import ClientEmailIcon from "../../assets/images/svg/mail-orange.svg";
import KeyIcon from "../../assets/images/svg/key-orange.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  Company,
  IApplication,
  ICABINET,
  IPermission,
  User,
} from "../../interfaces";
import axios from "axios";
import config from "../../config";
import { ErrorLogger } from "../../util/errorLogger";
import {
  COMPANY_STATUT_OPTIONS,
  CrudPermissions,
  JURID_FORMES,
  OptionType,
  PLAN_OPTIONS,
  UserTypes,
  VAT_REGIME_OPTIONS,
} from "../../util/context";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { usePermissions } from "../../util/hooks/usePermissions";
import {
  deleteCompany_List,
  setClientsList,
  setCompanUserStatus_List,
} from "../../store/reducers/clients-list/clientsSlice";
import {
  deleteCompany_Company,
  fetchAllCompanies,
  setCompanyUserStatus_Company,
} from "../../store/reducers/companies-list/companiesSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import * as yup from "yup";

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

type FormValues = {
  regimeTVA?: string | null;
  userStatus?: string | null;
  legalForm?: string | null;
  pack?: string | null;
  text?: string;
};

type ColType = {
  name: string;
  selector?: (row: any) => any;
  sortable?: boolean;
  button?: boolean;
  cell?: any;
  omit?: (row: any) => any;
};

type CreateCompanyFormValues = {
  pack: OptionType | null;
  applicationId: string | null;
};

type CreateUserFormValues = {
  application: OptionType | null;
  gender: OptionType | null;
  user_email: string | null;
  user_firstName: string | null;
  user_lastName: string | null;
  password: string | null;
  user_phone: string | null;
  repeat_password: string | null;
  role: string | null;
  company: CreateCompanyFormValues | null;
};

const emailSchema = yup.string().email();

const passwordSchema = yup
  .string()
  .matches(
    new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
  );

export interface ClientsProps {}
const MesClients: React.FC<ClientsProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [singleCompany, setSingleCompany] = useState<Company | null>();
  const [loading, setLoading] = useState(false);
  const [deleteCompanyLoading, setDeleteCompanyLoading] = useState(false);
  const [hideActions, setHideActions] = React.useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const ontoggle = (company: Company) => {
    setSingleCompany(company);
    setOpen(true);
  };
  const { clientsList } = useSelector(
    (state: { clientsList: Company[] }) => state.clientsList
  ) as unknown as { clientsList: Company[] };

  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const [localCompanies, setLocalCompanies] = useState<Company[]>([]);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, permissions } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };

  const { hasCrudPermission } = usePermissions();
  let api = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        name: "Entreprise",
        selector: (row: any) => row.name,
        sortable: true,
      },
      {
        name: "E-mail",
        selector: (row: any) => row.email,
        sortable: true,
      },
      {
        name: "Téléphone",
        selector: (row: any) =>
          row.phone && row.phone !== ""
            ? row.phone
            : row.clients.length > 0
            ? row.clients[0].phone && row.clients[0].phone !== ""
              ? row.clients[0].phone
              : ""
            : "",
      },
      {
        name: "Régime de TVA",
        selector: (row: any) =>
          VAT_REGIME_OPTIONS.find((elt) => elt.value === row.regimeTVA)?.label,
      },
      // {
      //   name: "Date de création",
      //   selector: (row: any) => row.createdAt,
      //   sortable: true,
      // },
      {
        name: "Status",
        selector: (row: Company) => row.status,
        sortable: true,
        cell: (row: { id: string; userStatus: string }) => (
          <>
            <Select
              options={COMPANY_STATUT_OPTIONS}
              classNamePrefix="select"
              className="custom-select form-primary outline small basic-multi-select"
              maxMenuHeight={180}
              menuPlacement="auto"
              onChange={async (e) => await updateCompanyUserStatus(e, row)}
              defaultValue={
                row.userStatus &&
                COMPANY_STATUT_OPTIONS.find(
                  (elt) => elt.value === row.userStatus
                )
              }
            />
          </>
        ),
      },
      {
        name: "Action",
        button: true,
        omit:
          permissions.length > 0 &&
          !hasCrudPermission(permissions, CrudPermissions.DeleteAccounts),
        cell: (row: Company) => (
          <div className="table-action">
            <button className="btn btn-red" onClick={() => ontoggle(row)}>
              <BsTrashFill />
            </button>
          </div>
        ),
      },
    ],
    [permissions]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({});
  const {
    control: createUserControl,
    register: createUserRegister,
    handleSubmit: createUserHandleSubmit,
    reset: createUserReset,
    formState: { errors: createUserErrors },
  } = useForm<CreateUserFormValues>({});

  const resetUserCreationForm = () => {
    return createUserReset({
      application: null,
      gender: null,
      user_email: null,
      user_firstName: null,
      user_lastName: null,
      password: null,
      user_phone: null,
      repeat_password: null,
      role: null,
      company: null,
    });
  };

  const createUser: SubmitHandler<CreateUserFormValues> = async (
    form: CreateUserFormValues
  ) => {
    try {
      if (
        !form.user_email ||
        !form.password ||
        !form.company ||
        !form.company?.pack
      ) {
        setErrorMessage({
          type: "create_user_empty_form",
          message: "Veuillez remplir tous les champs",
        });
        return;
      }

      if (!emailSchema.isValidSync(form.user_email)) {
        setErrorMessage({
          type: "create_user_empty_form",
          message: "Vous devez fournir une adresse email valide",
        });
        return;
      }
      if (!passwordSchema.isValidSync(form.password)) {
        setErrorMessage({
          type: "create_user_empty_form",
          message:
            "Le mot de passe doit contenir au moins 8 caractères, au moins un majuscule, un minuscule, un chiffre et un caractère spécial de '! @ # $% ^ & *'",
        });
        return;
      }

      setCreateLoading(true);

      if (form.user_email) {
        const { data } = await api.post(
          `/api/User/All`,
          {
            where: {
              email: form.user_email,
              application_id: APPLICATION_ID,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (data.data && Array.isArray(data.data) && data.data.length) {
          setCreateLoading(false);
          return setErrorMessage({
            type: "email_exists",
            message: "Cet email existe déjà",
          });
        }
      }

      if (
        form.password?.toLowerCase() !== form.repeat_password?.toLowerCase()
      ) {
        setCreateLoading(false);
        setErrorMessage({
          type: "passwords_not_match",
          message: "Les mots de passe ne correspondent pas",
        });
        return;
      }

      let payload: any = {
        company: {},
      };

      if (form.user_firstName) payload.firstName = form.user_firstName;
      if (form.user_email) {
        payload.email = form.user_email;
        payload.company.email = form.user_email;
      }
      if (form.user_lastName) payload.lastName = form.user_lastName;
      if (form.user_phone) {
        payload.phone = form.user_phone;
        payload.company.phone = form.user_phone;
      }
      if (form.password) payload.password = form.password;
      if (form.gender) payload.gender = form.gender.value;

      payload.company.applicationId = APPLICATION_ID;
      payload.application_id = APPLICATION_ID;

      if (form.company?.pack) {
        payload.company.pack = form.company?.pack.value;
      }

      payload.company.bankSynced = "100";

      await api.post(
        `/api/auth/register`,
        { activated: true, role: UserTypes.Client, ...payload },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setUserOpen(false);
      resetUserCreationForm();
      dispatch(fetchAllCompanies());
      setCreateLoading(false);
    } catch (error: any) {
      ErrorLogger("creating user", error);
      setCreateLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (form: any) => {
    try {
      setLoading(true);
      let keys = Object.keys(form);
      let where: any = keys.reduce((acc, curr) => {
        let obj: { [prop: string]: string } = { ...acc };

        if (form[curr]) {
          obj[curr] = form[curr]["value"];
        }

        return obj;
      }, {});

      if (creds.role === UserTypes.Cabinet) {
        where.cabinets = {
          id: user.id,
        };
      }

      if (!form.text) {
        const { data } = await api.post(
          `/api/Company/All`,
          {
            where,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        setLocalCompanies(data.data);
      } else {
        const { data } = await api.post(
          `/api/Company/All`,
          {
            where: {
              ...where,
              name: {
                iLike: `%${form.text}%`,
              },
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        setLocalCompanies(data.data);
      }
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("company filter form", error);
    }
  };

  const deleteCompany = async () => {
    try {
      setDeleteCompanyLoading(true);
      await api.post(
        `/api/Company/Update`,
        {
          id: singleCompany?.id,
          activated: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/Company/deleteWhere`,
        {
          where: {
            id: [singleCompany?.id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/User/update`,
        {
          id: singleCompany?.clients![0].id,
          activated: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/auth/destroyExternalCreds`,
        {
          user_ids: [singleCompany?.clients![0].id],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await api.post(
        `/api/User/deleteWhere`,
        {
          where: {
            id: singleCompany?.clients![0].id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      const data = clientsList.filter(
        (company) => company.id !== singleCompany?.id
      );
      if (creds.role === UserTypes.Cabinet) {
        dispatch(
          deleteCompany_List({
            id: singleCompany?.id,
          })
        );
      } else {
        dispatch(
          deleteCompany_Company({
            id: singleCompany?.id,
          })
        );
      }
      setSingleCompany(null);
      setOpen(false);
      setDeleteCompanyLoading(false);
    } catch (error: any) {
      setDeleteCompanyLoading(false);
      ErrorLogger("deleting a company", error);
      setSingleCompany(null);
      setOpen(false);
      if (creds.role === UserTypes.Cabinet) {
        dispatch(
          deleteCompany_List({
            id: singleCompany?.id,
          })
        );
      } else {
        dispatch(
          deleteCompany_Company({
            id: singleCompany?.id,
          })
        );
      }
    }
  };

  const updateCompanyUserStatus = async (
    e: string | OptionType | null,
    company: Partial<Company>
  ) => {
    try {
      const { data } = await api.post(
        `/api/Company/Update`,
        {
          id: company.id,
          userStatus: (e as OptionType)?.value,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.updated.length) {
        if (creds.role === UserTypes.Cabinet) {
          dispatch(
            setCompanUserStatus_List({
              id: company.id,
              userStatus: (e as OptionType)?.value,
            })
          );
        } else {
          dispatch(
            setCompanyUserStatus_Company({
              id: company.id,
              userStatus: (e as OptionType)?.value,
            })
          );
        }
      }
    } catch (error: any) {
      ErrorLogger("updating company user status", error);
    }
  };

  const { ref: textRef, ...text } = register("text");

  // create user form
  const { ref: user_firstNameRef, ...user_firstName } =
    createUserRegister("user_firstName");
  const { ref: user_lastNameRef, ...user_lastName } =
    createUserRegister("user_lastName");
  const { ref: user_phoneRef, ...user_phone } =
    createUserRegister("user_phone");
  const { ref: user_emailRef, ...user_email } =
    createUserRegister("user_email");
  const { ref: passwordRef, ...password } = createUserRegister("password");
  const { ref: repeat_passwordRef, ...repeat_password } =
    createUserRegister("repeat_password");

  return (
    <div className="page">
      <div className="action-top-nav">
        <Button
          color="warning"
          className="btn-creat-user"
          onClick={() => setUserOpen(true)}
        >
          <img src={userAddIcon} alt="icon" />
          <span>Créer un client</span>
        </Button>
      </div>
      <div className="top-content">
        <Row>
          <Col lg={8} md={12}>
            <BannerTop banner={data.mesclients} />
          </Col>
          <Col lg={4} md={12}>
            <UserInfo user={user} />
          </Col>
        </Row>
      </div>

      <Card className="card-filter">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="align-items-end">
            <Col lg={9} md={12}>
              <div className="filterInner">
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Régime de TVA</Label>
                      <Controller
                        name="regimeTVA"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              VAT_REGIME_OPTIONS as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Forme juridique</Label>
                      <Controller
                        name="legalForm"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              JURID_FORMES as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Pack</Label>
                      <Controller
                        name="pack"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              PLAN_OPTIONS as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="exampleEmail">Statut</Label>
                      <Controller
                        name="userStatus"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              COMPANY_STATUT_OPTIONS as unknown as readonly (
                                | string
                                | GroupBase<string>
                              )[]
                            }
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="form-icon icon-end">
                      <Label for="mc">Mot clé</Label>
                      <Input
                        {...text}
                        innerRef={textRef}
                        id="mc"
                        name="text"
                        placeholder="Mot clé"
                        type="text"
                        className="form-secondary"
                      />
                      <span className="icon icon-secondary">
                        <RiSearchLine />
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={3} md={12}>
              <div className="actionsFilter mb-3">
                <Button color="secondary">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  type="button"
                  onClick={async () => {
                    reset({
                      regimeTVA: null,
                      userStatus: null,
                      legalForm: null,
                      pack: null,
                      text: "",
                    });
                    setLocalCompanies([]);
                  }}
                  outline
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Card>

      <Card className="card-Table table-primary invoice-table">
        {localCompanies.length > 0 ? (
          <DataTable
            columns={columns}
            data={localCompanies}
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            pagination
            paginationPerPage={20}
            paginationComponent={(props) => {
              const customProps = { ...props, color: "primary" };
              return <BootyPagination {...customProps} />;
            }}
            onRowClicked={(row: Partial<Company>, e: any) => {
              navigate(`/client/${row.id}`);
            }}
            progressPending={loading}
            progressComponent={
              <>
                <Spinner color="light" type="grow">
                  Loading...
                </Spinner>
                <Spinner color="light" type="grow">
                  Loading...
                </Spinner>
                <Spinner color="light" type="grow">
                  Loading...
                </Spinner>
              </>
            }
          />
        ) : (
          <DataTable
            columns={columns}
            data={
              creds.role === UserTypes.Cabinet ? clientsList : companiesList
            }
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            pagination
            paginationPerPage={20}
            onRowClicked={(row: Partial<Company>, e: any) => {
              navigate(`/client/${row.id}`);
            }}
            paginationComponent={(props) => {
              const customProps = { ...props, color: "primary" };
              return <BootyPagination {...customProps} />;
            }}
            progressPending={loading}
            progressComponent={
              <>
                <Spinner color="light" type="grow">
                  Loading...
                </Spinner>
                <Spinner color="light" type="grow">
                  Loading...
                </Spinner>
                <Spinner color="light" type="grow">
                  Loading...
                </Spinner>
              </>
            }
          />
        )}
      </Card>
      <div className="openbtn text-center">
        <Modal
          className="modal-danger"
          isOpen={open}
          toggle={() => {
            setSingleCompany(null);
            setOpen(false);
            setDeleteCompanyLoading(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleCompany(null);
              setOpen(false);
              setDeleteCompanyLoading(false);
            }}
          >
            Supprimer un client
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer le client{" "}
                {singleCompany?.name || singleCompany?.email}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setDeleteCompanyLoading(false);
                setSingleCompany(null);
                setOpen(false);
              }}
              disabled={deleteCompanyLoading}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => {
                await deleteCompany();
              }}
              disabled={deleteCompanyLoading}
            >
              {deleteCompanyLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/*create user*/}
        <Modal
          className="modal-warning modal-dialog-centered modal-lg"
          isOpen={userOpen}
          toggle={() => {
            resetUserCreationForm();
            setUserOpen(false);
            setCreateLoading(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              resetUserCreationForm();
              setUserOpen(false);
              setCreateLoading(false);
            }}
          >
            Créer un nouvel client
          </ModalHeader>
          <form onSubmit={createUserHandleSubmit(createUser)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        innerRef={user_emailRef}
                        {...user_email}
                        placeholder="Email"
                        type="text"
                        className="form-warning"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-warning ">
                        <img src={ClientEmailIcon} alt="icon" />
                      </span>
                    </FormGroup>
                    {errorMessage?.type === "email_exists" && (
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pack">Pack</Label>
                      <Controller
                        name="company.pack"
                        control={createUserControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={PLAN_OPTIONS}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-warning"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Mot de passe utilisateur</Label>
                      <Input
                        id="pwd"
                        innerRef={passwordRef}
                        {...password}
                        placeholder="Mot de passe"
                        type="password"
                        className="form-warning"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-warning ">
                        <img src={KeyIcon} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="cpwd">Confirmer le mot de passe</Label>
                      <Input
                        id="cpwd"
                        innerRef={repeat_passwordRef}
                        {...repeat_password}
                        placeholder="Confirmer le mot de passe"
                        type="password"
                        className="form-warning"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-warning ">
                        <img src={KeyIcon} alt="icon" />
                      </span>
                    </FormGroup>
                    {errorMessage?.type === "passwords_not_match" && (
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    )}
                  </Col>
                  {errorMessage?.type === "create_user_empty_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="warning"
                outline
                disabled={createLoading}
                onClick={() => {
                  resetUserCreationForm();
                  setUserOpen(false);
                  setCreateLoading(false);
                }}
              >
                Annuler
              </Button>
              <Button color="warning" type="submit" disabled={createLoading}>
                {createLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Enregistrer"
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default MesClients;
