import axios from "axios";
import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import config from "../../../config";
import { ICategory, ISubCategory, ITransaction } from "../../../interfaces";
import { CarbKeys, PayDiffKeys } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import { useTVA } from "../../../util/hooks/useTVA";

const { API_URL } = config[process.env.NODE_ENV];

const Decaissements = ({
  categs,
  transaction,
  callback,
  immobCheckCallback,
  carbCheckCallback,
  diffPayCheckCallback,
  setRefreshCategs,
}: {
  categs: ICategory[];
  transaction: ITransaction | null | undefined;
  callback: () => void;
  setRefreshCategs: (value: React.SetStateAction<boolean>) => void;
  immobCheckCallback: any;
  diffPayCheckCallback: any;
  carbCheckCallback: any;
}) => {
  const [activeCategIndex, setActiveCategIndex] = useState<number>(0);
  const [currentCategs, setCurrentCategs] = useState<ICategory[]>([]);
  const [descShown, setDescShown] = useState<{
    [prop: number]: boolean;
  }>({});
  useEffect(() => {
    setCurrentCategs(
      !transaction?.diffPay_id
        ? categs
        : categs.filter((elt) => elt.name !== "Paiements différés/groupés")
    );
  }, [categs]);
  const { setTVA } = useTVA();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };
  let api = useAxios();

  const updateTransaction = async (categ: ISubCategory) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id: transaction?.id,
          subCategoryId: categ.id,
          category: categ.key,
          vat:
            categ.TVA &&
            !isNaN(parseFloat(categ.TVA)) &&
            parseFloat(categ.TVA) !== 0
              ? setTVA(transaction?.amount!, parseFloat(categ.TVA) / 100)
              : null,
          status: transaction?.status === 101 ? 100 : transaction?.status,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (
        categ.amount &&
        categ.rule &&
        Math.abs(transaction?.amount!) >= Math.abs(parseFloat(categ.amount)) &&
        String(categ.rule) === "true" &&
        categ.redirectCategory_id
      ) {
        await immobCheckCallback(categ,transaction);
        return;
      }


      await callback();

      if (
        categ.key === PayDiffKeys.OUT100 ||
        categ.key === PayDiffKeys.OUT101
      ) {
        await diffPayCheckCallback(transaction);
        return;
      }

      if (
        categ.key &&
        categ.key === CarbKeys.OUT028
      ) {
        await carbCheckCallback(transaction);
      }
    } catch (error: any) {
      ErrorLogger("updating a transaction's catgeory", error);
    }
  };

  return (
    <>
      {currentCategs && currentCategs.length > 1 && (
        <>
          <Row>
            <Col md={5} sm={12}>
              <div className="remunerations-block">
                <ul>
                  {currentCategs.map((elt, key) => (
                    <li
                      className={key === activeCategIndex ? "active" : ""}
                      key={key}
                    >
                      <span
                        className="name-cat-span"
                        onClick={() => setActiveCategIndex(key)}
                      >
                        {elt.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={7} sm={12}>
              <div className="list-encaissements-choix">
                {currentCategs[activeCategIndex].sub_categories?.map(
                  (elt, key) => (
                    <div
                      className="choix-item"
                      key={key}
                      onClick={async () => {
                        setRefreshCategs(true);
                        await updateTransaction(elt);
                      }}
                      onMouseEnter={() =>
                        setDescShown((prevState) => ({
                          ...prevState,
                          [key]:
                            elt.description && elt.description !== ""
                              ? true
                              : false,
                        }))
                      }
                      onMouseLeave={() =>
                        setDescShown((prevState) => ({
                          ...prevState,
                          [key]: false,
                        }))
                      }
                    >
                      <p>{elt.name}</p>
                      {descShown![key] && <p>{elt.description}</p>}
                    </div>
                  )
                )}
              </div>
            </Col>
          </Row>
        </>
      )}
      {currentCategs && currentCategs.length === 1 && (
        <div className="list-encaissements-choix">
          {currentCategs[0].sub_categories?.map((elt, key) => (
            <div
              className="choix-item"
              key={key}
              onClick={async () => {
                setRefreshCategs(true);
                await updateTransaction(elt);
              }}
              onMouseEnter={() =>
                setDescShown((prevState) => ({
                  ...prevState,
                  [key]:
                    elt.description && elt.description !== "" ? true : false,
                }))
              }
              onMouseLeave={() =>
                setDescShown((prevState) => ({
                  ...prevState,
                  [key]: false,
                }))
              }
            >
              <p>{elt.name}</p>
              {descShown![key] && <p>{elt.description}</p>}
            </div>
          ))}
        </div>
      )}
      {currentCategs && currentCategs.length < 1 && <></>}
    </>
  );
};

export default Decaissements;
