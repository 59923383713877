import axios from "axios";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import config from "../../../config";
import { ICategory, ISubCategory, ITransaction } from "../../../interfaces";
import { TransactionMediums } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import { useTVA } from "../../../util/hooks/useTVA";

const { API_URL } = config[process.env.NODE_ENV];

const Encaissements = ({
  categs,
  transaction,
  callback,
  setRefreshCategs,
}: {
  categs: ICategory[];
  transaction: ITransaction | null | undefined;
  callback: () => void;
  setRefreshCategs: (value: React.SetStateAction<boolean>) => void;
}) => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const { setTVA } = useTVA();
  let api = useAxios();

  const updateTransaction = async (categ: ISubCategory) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id: transaction?.id,
          subCategoryId: categ.id,
          category: categ.key,
          vat:
            categ.TVA &&
            !isNaN(parseFloat(categ.TVA)) &&
            parseFloat(categ.TVA) !== 0
              ? setTVA(transaction?.amount!, parseFloat(categ.TVA) / 100)
              : null,
          status: transaction?.status === 101 ? 100 : transaction?.status,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await callback();
    } catch (error: any) {
      ErrorLogger("updating a transaction's catgeory", error);
    }
  };
  return (
    <div className="list-encaissements-choix">
      {categs &&
        categs.length === 1 &&
        categs.map((elt) =>
          elt.sub_categories
            ?.filter((elt) => {
              if (transaction?.medium === TransactionMediums.Manual) {
                return !["IN-017", "IN-016", "IN-019", "IN-022"].includes(
                  elt.key
                );
              } else {
                return elt;
              }
            })
            .map((elt, key) => (
              <div
                className="choix-item"
                key={key}
                onClick={async () => {
                  setRefreshCategs(true);
                  await updateTransaction(elt);
                }}
              >
                {elt.name}
              </div>
            ))
        )}
    </div>
  );
};

export default Encaissements;
