export default {
  development: {
    API_URL: "http://localhost:3500",
    // API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: "app_SL8uRNVLT",
    BaseURL: "http://localhost:3000",
    APPLICATION_NAME: "Noly Compta",
    PRIMCOLOR: process.env.REACT_APP_PRIMCOLOR,
    SECCOLOR: process.env.REACT_APP_SECCOLOR,
    TERCOLOR: process.env.REACT_APP_TERCOLOR,
    LOGO: "https://noly-media-storage-dev.s3.eu-west-3.amazonaws.com/05-13-2022-logo-dw.png",
  },
  production: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: "app_SL8uRNVLT",
    BaseURL: "https://app.noly-compta.fr",
    APPLICATION_NAME: "Noly Compta",
    PRIMCOLOR: process.env.REACT_APP_PRIMCOLOR,
    SECCOLOR: process.env.REACT_APP_SECCOLOR,
    TERCOLOR: process.env.REACT_APP_TERCOLOR,
    LOGO: "https://noly-media-storage-dev.s3.eu-west-3.amazonaws.com/05-13-2022-logo-dw.png",
  },
  test: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: "app_SL8uRNVLT",
    BaseURL: "https://app.noly-compta.fr",
    APPLICATION_NAME: "Noly Compta",
    PRIMCOLOR: process.env.REACT_APP_PRIMCOLOR,
    SECCOLOR: process.env.REACT_APP_SECCOLOR,
    TERCOLOR: process.env.REACT_APP_TERCOLOR,
    LOGO: "https://noly-media-storage-dev.s3.eu-west-3.amazonaws.com/05-13-2022-logo-dw.png",
  },
};
