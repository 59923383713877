import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { SubmitHandler, useForm } from "react-hook-form";
import { BsTrashFill } from "react-icons/bs";
import { IoMdCreate } from "react-icons/io";
import { RiDownload2Fill, RiFileList3Line } from "react-icons/ri";
import { toast, ToastContainer } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import AttacheIcon from "../../assets/AttacheIcon";
import ViewIcon from "../../assets/ViewIcon";
import { Company, GedDocs, IS3, User } from "../../interfaces";
import { deleteFileFromList } from "../../pages/ClientInfo/InformationClient";
import { ErrorLogger } from "../../util/errorLogger";
import FilePicker from "../FilePicker";
import FileViewer from "../FileViewer";
import { BootyPagination } from "../table/pagination";
import moment from "moment";
import "moment/locale/fr";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GedDocTypes, UserTypes } from "../../util/context";

type GeneralFormValues = {
  name: string | null;
};

export type AdditonalData = {
  type: string;
  [key: string]: string | number | boolean;
};

const OtherDocs = ({
  modelId,
  data,
  setRelaod,
  token,
  api,
  additionalData,
}: {
  modelId: string;
  data: GedDocs[];
  setRelaod: any;
  token: string;
  api: any;
  additionalData: AdditonalData;
}) => {
  const [addOtherDocModal, setAddOtherDocModal] = useState<boolean>(false);
  const [stagedFile, setStagedFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<GedDocs[]>([]);
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [singleFile, setSingelFile] = useState<GedDocs | null>();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const otherDocsColumns: any = React.useMemo(
    () => [
      {
        name: "Document",
        selector: (row: GedDocs) => row.name,
        sortable: true,
      },
      {
        name: "Date de création",
        selector: (row: GedDocs) => moment(row.createdAt).format("DD/MM/YYYY"),
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        cell: (row: GedDocs) => (
          <div className="table-action">
            <button
              className="btn btn-secondaryColor"
              onClick={() => {
                setUrl(row.file.url);
                setViewModal(true);
              }}
            >
              <ViewIcon />
            </button>
            {creds.user_id && creds.role !== UserTypes.Client && (
              <button
                className="btn btn-red"
                onClick={() => {
                  setSingelFile(row);
                  setDeleteModal(true);
                }}
              >
                <BsTrashFill />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const { pathname } = useLocation();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  useEffect(() => {
    setFiles(
      data.filter((elt) => {
        if (additionalData.year) {
          return (
            JSON.parse(elt.additionalData).type === additionalData.type &&
            JSON.parse(elt.additionalData).year === additionalData.year
          );
        } else {
          return JSON.parse(elt.additionalData).type === additionalData.type;
        }
      })
    );
  }, [data]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<GeneralFormValues>({});

  const { ref: nameRef, ...name } = register("name");

  const handleDocsChange = (event: { target: { files: any } }) => {
    setErrorMessage(null);
    setStagedFile(event.target.files[0]!);
  };

  const saveFile: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      if (!form.name) {
        setLoading(false);
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez insérer le nom du document",
        });
        return;
      }
      if (!stagedFile) {
        setLoading(false);
        setErrorMessage({
          type: "missing_file",
          message: "Veuillez ajouter un document",
        });
        return;
      }

      const formData = new FormData();
      formData.append("name", form.name!);
      formData.append("file", stagedFile as any);
      formData.append("company_id", modelId);
      formData.append("additionalData", JSON.stringify(additionalData) as any);

      await api.post(`/api/GedDoc/Create`, formData, {
        headers: {
          "x-access-token": token,
        },
      });

      setLoading(false);
      setRelaod(true);
      toast.success("Votre ajout a été effectué avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setAddOtherDocModal(false);
      setStagedFile(null);
      reset();
      toast.dismiss()
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setLoading(false);
      setAddOtherDocModal(false);
      setStagedFile(null);
      reset();
    }
  };

  const deleteFile = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/api/GedDoc/Delete`,
        {
          id: singleFile?.id,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      setLoading(false);
      if (data.deleted && data.deleted.length > 0) {
        setRelaod(true);
        toast.success("Votre suppression a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setDeleteModal(false);
      setSingelFile(null);
      toast.dismiss()
    } catch (error: any) {
      ErrorLogger("updating bilan", error);
      setLoading(false);
      setAddOtherDocModal(false);
      setSingelFile(null);
    }
  };

  return (
    <>
      <ToastContainer />

      <h3>Autres documents</h3>
      <Card className="card-Table table-secondary">
        <div className="create-indem__button mb-3">
          {((creds.user_id && creds.role !== UserTypes.Client) ||
            (creds.user_id &&
              creds.role === UserTypes.Client &&
              !pathname.includes("bilan"))) && (
            <>
              <Button
                color="secondary"
                type="submit"
                onClick={() => {
                  setAddOtherDocModal(true);
                }}
              >
                Ajouter un document
              </Button>
            </>
          )}
        </div>

        <DataTable
          columns={otherDocsColumns}
          noDataComponent={
            <p>
              Il n'y a aucun data à afficher
              {additionalData.type === GedDocTypes.Bilan &&
                ` pour l'année ${additionalData.year}`}
            </p>
          }
          data={files}
          pagination
          paginationComponent={(props: any) => {
            const customProps = { ...props, color: "secondary" };
            return <BootyPagination {...customProps} />;
          }}
          progressPending={loading}
          progressComponent={
            <>
              <Spinner color="light" type="grow">
                Loading...
              </Spinner>
              <Spinner color="light" type="grow">
                Loading...
              </Spinner>
              <Spinner color="light" type="grow">
                Loading...
              </Spinner>
            </>
          }
        />
      </Card>
      <div className="openbtn text-center">
        <Modal
          isOpen={deleteModal}
          toggle={() => {
            setSingelFile(null);
            setDeleteModal(false);
          }}
          className="modal-danger modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setSingelFile(null);
              setDeleteModal(false);
            }}
          >
            Supprimer un document
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer le docuemnt{" "}
                {singleFile?.name}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setSingelFile(null);
                setDeleteModal(false);
              }}
              disabled={loading}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => {
                await deleteFile();
              }}
              disabled={loading}
            >
              {loading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={addOtherDocModal}
          toggle={() => {
            setAddOtherDocModal(false);
            setStagedFile(null);
            setErrorMessage(null);
            reset();
          }}
        >
          <ModalHeader
            toggle={() => {
              setAddOtherDocModal(false);
              setStagedFile(null);
              setErrorMessage(null);
              reset();
            }}
          >
            Ajouter un document
          </ModalHeader>
          <form onSubmit={handleSubmit(saveFile)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="name">Nom du document</Label>
                      <Input
                        id="name"
                        innerRef={nameRef}
                        {...name}
                        type="text"
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <RiFileList3Line />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="name">Document</Label>
                    <FilePicker
                      className="form-file file-secondary "
                      onChange={handleDocsChange}
                      state={stagedFile}
                      fileStagedDelete={() => setStagedFile(null)}
                      setUrl={setUrl}
                      setViewModal={setViewModal}
                    />
                  </Col>
                </Row>
                {errorMessage?.type === "invalid_form" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
                {errorMessage?.type === "missing_file" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="secondary"
                outline
                disabled={loading}
                onClick={() => {
                  setAddOtherDocModal(false);
                  setStagedFile(null);
                  setErrorMessage(null);
                  reset();
                }}
                type="button"
              >
                Annuler
              </Button>
              <Button color="secondary" type="submit" disabled={loading}>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Ajouter"
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default OtherDocs;
