import React, { useState } from "react";
import { UserTypes } from "../../util/context";
import "./Switch.scss";
interface SwitchProps {
  id?: string;
  switchValue: string;
  setSwitchValue: (item: string) => void;
}

const CustomSwitch: React.FC<SwitchProps> = ({
  id,
  switchValue,
  setSwitchValue,
}) => {
  return (
    <>
      <input
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
        onChange={() => {
          setSwitchValue(switchValue === "Admin" ? UserTypes.Cabinet : "Admin");
        }}
      />
      <label className="react-switch-label" htmlFor={id}>
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default CustomSwitch;
