import React, { useEffect, useRef, useState } from "react";
import "./MesTransactions.scss";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import { SiPypy } from "react-icons/si";
import { IoIosCash } from "react-icons/io";
import UserInfo from "../../components/user-info/UserInfo";
import { colors, data } from "../../constants";
import {
  RiAddFill,
  RiCalendar2Line,
  RiDownload2Fill,
  RiMoneyEuroCircleLine,
  RiUser3Line,
  RiWalletFill,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Tabs from "../../components/Tabs";
import TransactionsAvalider from "./TransactionsAvalider/TransactionsAvalider";
import TransactionsArchive from "./TransactionsArchive/TransactionsArchive";
import TopCard from "../../components/TopCard/TopCard";
import { useDispatch, useSelector } from "react-redux";
import {
  Client,
  Company,
  ISubCategory,
  ITransaction,
  User,
} from "../../interfaces";
import axios from "axios";
import config from "../../config";
import { ErrorLogger } from "../../util/errorLogger";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/fr";
import AttacheIcon from "../../assets/AttacheIcon";
import IconPlusYellow from "../../assets/images/svg/puls-icon-yellow.svg";
import MoneyIconBlue from "../../assets/images/svg/money-icon-blue.svg";
import MoneyIconGreen from "../../assets/images/svg/euro-icon-green.svg";
import packMoneyIcons from "../../assets/images/svg/pack-money.svg";
import MoneySacIconGreen from "../../assets/images/svg/money-sac-green.svg";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import CalenderIconGreen from "../../assets/images/svg/calender-icon-green.svg";
import TrDec from "../../assets/images/icons/client/tr-dec.png";
import TrEnc from "../../assets/images/icons/client/tr-enc.png";
import { useTVA } from "../../util/hooks/useTVA";
import { fetchAllCompanies } from "../../store/reducers/companies-list/companiesSlice";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import {
  AppPacks,
  BankSynced,
  TransactionMediums,
  UserTypes,
} from "../../util/context";
import CrownIcon from "../../assets/CrownIcon";
import FirstSyncComp from "../../components/FirstSyncComp";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

export interface TransactionsProps {}

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

type GeneralFormValues = {
  amount: number | null;
  label: string | null;
  date: string | null;
  category: { value: string; label: string } | null;
  document?: any;
  modalType?: string | null;
};

// Tabs Array
const tabs: TabsType = [
  {
    label: "À valider",
    index: 1,
    Component: TransactionsAvalider,
  },
  {
    label: "Archivées",
    index: 2,
    Component: TransactionsArchive,
  },
];

// Tabs Array
const entrepTabs: TabsType = [
  {
    label: "À Comptabliliser",
    index: 1,
    Component: TransactionsAvalider,
  },
  {
    label: "Comptablilisées",
    index: 2,
    Component: TransactionsArchive,
  },
];

const { API_URL } = config[process.env.NODE_ENV];

const MesTransactions = ({
  context,
  pack,
}: {
  context?: string;
  pack?: string;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD hh:mm")
  );
  const [addEspeceModal, setAddEspeceModal] = useState<boolean>(false);
  const [isImmobFire, setIsImmobFire] = useState<boolean>(false);
  const [isImmobSubCateg, setIsImmobSubCateg] = useState<ISubCategory | null>();
  const [isImmobTrans, setIsImmobTrans] = useState<ITransaction | null>();
  const [reloadTrs, setReloadTrs] = useState<boolean>(false);
  const [addNoteFraisModal, setAddNoteFraisModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [archiveAllErrorModal, setArchiveAllErrorModal] =
    useState<boolean>(false);

  const [archiveAllInvalidStatus, setArchiveAllInvalidStatus] =
    useState<boolean>(false);
  const [archiveAllInvalidDocs, setArchiveAllInvaliddocs] =
    useState<boolean>(false);
  const [archiveAllSepcialInvalidDocs, setArchiveAllSpecialInvaliddocs] =
    useState<boolean>(false);
  const [inputSubCategs, setInputSubCategs] = useState<ISubCategory[]>([]);
  const [outputSubCategs, setOutputSubCategs] = useState<ISubCategory[]>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const { user, company } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const [spendingData, setSpendingData] = useState<number>(0);
  const [itData, setItData] = useState<number>(0);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const navigate = useNavigate();
  let api = useAxios();

  const getDashboardSependingIT = async (foundComp: Company) => {
    try {
      const { data } = await api.post(
        `/api/stats/dashboardSependingIT`,
        {
          dateFrom: moment(currentDate).startOf("month").format("YYYY-MM-DD"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD"),
          companyId: foundComp?.id,
          pack: foundComp?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { spending, it } = data;

      setSpendingData(spending);
      setItData(it);
    } catch (error: any) {
      ErrorLogger("getting spending and it data in dashboard", error);
    }
  };

  const getDashboardSependingITWrapper = () => {
    if (user.id && user.role === UserTypes.Client && company && company?.pack) {
      getDashboardSependingIT(company!);
    }
  };

  useEffect(() => {
    getDashboardSependingITWrapper();
  }, [context, user]);

  const getSubCategories = async () => {
    try {
      setLoading(true);

      const { data } = await api.post(
        `/api/SubCategory/all`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      for (let sub of data.data) {
        if (sub.type && sub.type.toLowerCase() === "output") {
          setOutputSubCategs((prevState) => {
            const oldState = [...prevState];
            oldState.push(sub);
            return oldState;
          });
        }
        if (sub.type && sub.type.toLowerCase() === "input") {
          setInputSubCategs((prevState) => {
            const oldState = [...prevState];
            oldState.push(sub);
            return oldState;
          });
        }
      }
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting sub categories", error);
    }
  };

  useEffect(() => {
    getSubCategories();
  }, []);

  useEffect(() => {
    getDashboardSependingITWrapper();
  }, [currentDate]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<GeneralFormValues>({});

  const { document: _document } = watch();
  const { setTVA } = useTVA();
  const dispatch = useDispatch();

  const createTransaction: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      if (!form.label || !form.amount || !form.date) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }

      const formData = new FormData();
      let subCateg = [] as Partial<ISubCategory>;

      formData.append("label", form.label as string);
      formData.append("status", 100 as any);
      formData.append("companyId", context as string);
      formData.append(
        "date",
        moment(form.date)
          .format("YYYY-MM-DD") as string
      );
      formData.append("medium", TransactionMediums.Manual);
      if (form.category) {
        formData.append("sub_category_id", form.category?.value as string);
      }

      if (form.modalType === "positive") {
        subCateg = inputSubCategs.find(
          (elt) => elt.id === form.category?.value
        )!;
        formData.append("amount", form.amount as any);
        formData.append("type", "cash");
      } else {
        subCateg = outputSubCategs.find(
          (elt) => elt.id === form.category?.value
        )!;
        formData.append("amount", -form.amount as any);
        formData.append("type", "expense");
      }
      if (
        subCateg?.TVA &&
        !isNaN(parseFloat(subCateg?.TVA)) &&
        parseFloat(subCateg?.TVA) !== 0
      ) {
        formData.append(
          "vat",
          String(setTVA(form?.amount!, parseFloat(subCateg?.TVA) / 100))
        );
        formData.append("category", subCateg.key as string);
      }

      if (form.document && form.document.length) {
        for (let index = 0; index < form.document.length; index++) {
          const element = form.document[index];
          formData.append("document", element);
        }
      }

      const { data } = await api.post(`/api/Transaction/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      setLoading(false);
      setReloadTrs(true);
      resteForm();
      setAddEspeceModal(false);
      setAddNoteFraisModal(false);
      if (
        subCateg.amount &&
        subCateg.rule &&
        Math.abs(parseFloat(String(form.amount))) >=
          Math.abs(parseFloat(subCateg.amount)) &&
        String(subCateg.rule) === "true" &&
        subCateg.redirectCategory_id
      ) {
        setIsImmobTrans(data);
        setIsImmobSubCateg(subCateg as ISubCategory);
        setIsImmobFire(true);
        return;
      }
    } catch (error: any) {
      ErrorLogger("transaction creation form", error);
      setLoading(false);
      setErrorMessage({
        type: "undexpected_issue",
        message:
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
      });
    }
  };

  const archiveAllTransactions = async (company_id: string, force = 0) => {
    try {
      setLoading(true);
      setArchiveAllInvalidStatus(false);
      setArchiveAllInvaliddocs(false);
      setArchiveAllSpecialInvaliddocs(false);
      setErrorMessage(null);
      let payload: { company_id: string; force?: number } = {
        company_id,
      };
      if (force > 0) {
        payload.force = force;
      }

      const { data } = await api.post(`/api/transaction/archiveAll`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      if (!data.acrhived) {
        setArchiveAllErrorModal(true);
        if (!data.isValid) setArchiveAllInvalidStatus(true);
        if (
          !data.hasDocuments.status &&
          data.hasDocuments.check === "normal" &&
          data.isValid
        )
          setArchiveAllInvaliddocs(true);
        if (
          !data.hasDocuments.status &&
          data.hasDocuments.check === "special" &&
          data.isValid
        )
          setArchiveAllSpecialInvaliddocs(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      setReloadTrs(true);
      setArchiveAllErrorModal(false);
      setArchiveAllInvalidStatus(false);
      setArchiveAllInvaliddocs(false);
      setArchiveAllSpecialInvaliddocs(false);
    } catch (error: any) {
      ErrorLogger("archive all transactions", error);
      setLoading(false);
      setErrorMessage({
        type: "undexpected_issue",
        message:
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
      });
    }
  };

  const resteForm = () => {
    reset({
      label: null,
      amount: null,
      date: null,
      category: null,
      document: null,
      modalType: null,
    });
    setErrorMessage(null);
  };

  const { ref: labelRef, ...label } = register("label");
  const { ref: amountRef, ...amount } = register("amount");
  const { ref: dateRef, ...date } = register("date");
  const { ref: documentRef, ...document } = register("document");

  return (
    <>
      <div className="page">
        {user.id && user.role === UserTypes.Client ? (
          <>
            <div
              className={`top-content ${
                creds.role === UserTypes.Client ? "client-resize-header" : ""
              }`}
            >
              <Row>
                <Col lg={8} md={12} className="blockBanner">
                  <BannerTop banner={data.transactions} />
                </Col>
                <Col lg={4} md={12}>
                  <Card className="blockUser">
                    <Button
                      className="btn-icon btn-icon-primary"
                      color="light"
                      onClick={() => navigate("/tickets")}
                    >
                      {newNotif && <Badge color="primary"> </Badge>}
                      <CrownIcon stroke={colors.primaryColor} />
                    </Button>
                    <UserInfo user={user} />
                    <Button
                      className="btn-icon btn-icon-primary"
                      color="light"
                      onClick={() =>
                        navigate({
                          pathname: "/profil/client",
                          // search: "?page=1&tab=1",
                        })
                      }
                    >
                      <RiUser3Line />
                    </Button>
                  </Card>
                </Col>
              </Row>
            </div>
            {creds.role === UserTypes.Client &&
              company.bankSynced !== BankSynced.YES && <FirstSyncComp />}
            <div className="action-top-odt">
              <span className="month-label">
                {moment(currentDate).format("MMMM/YYYY")}
              </span>
              <div className="od-tva-wrapper">
                <button
                  type="button"
                  className="btn btn-outline-primary "
                  onClick={() =>
                    setCurrentDate(
                      moment(currentDate)
                        .subtract(1, "month")
                        .format("YYYY-MM-DD hh:mm")
                    )
                  }
                >
                  Mois précédent
                </button>
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() =>
                    setCurrentDate(
                      moment(currentDate)
                        .add(1, "month")
                        .format("YYYY-MM-DD hh:mm")
                    )
                  }
                >
                  Mois suivant
                </button>
              </div>
            </div>
            <div className="Center-content">
              <Row>
                <Col lg="4" md="12" className="client-encais">
                  <TopCard
                    title="Encaissement"
                    text={`${Math.abs(itData)} €`}
                    icon={TrEnc}
                    className="card-primary"
                  />
                </Col>
                <Col lg="4" md="12" className="client-estims">
                  <TopCard
                    title={
                      company?.pack === AppPacks.Entrepreneur
                        ? "Imposition Estimée"
                        : "Décaissement"
                    }
                    text={`${Math.abs(spendingData)} €`}
                    icon={TrDec}
                    className="card-warning"
                  />
                </Col>
                <Col lg="4" md="12" className="client-add-tr-wrapper">
                  <Card
                    className="client-add-tr plus-add"
                    onClick={() => setAddEspeceModal(true)}
                  >
                    Ajouter des espèces
                  </Card>
                  <Card
                    className="client-add-tr neg-add"
                    onClick={() => setAddNoteFraisModal(true)}
                  >
                    Ajouter une note de frais
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}

        <div>
          <Card className="card-Table table-primary">
            <div className="action-top-tabs">
              {user.role !== UserTypes.Client && (
                <>
                  <Button
                    color="secondary"
                    onClick={() => setAddEspeceModal(true)}
                    type="button"
                  >
                    <img
                      src={IconPlusYellow}
                      alt="icon"
                      className="svg-warning"
                    />
                    Ajouter des espèces
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => setAddNoteFraisModal(true)}
                    type="button"
                  >
                    <img
                      src={IconPlusYellow}
                      alt="icon"
                      className="svg-warning"
                    />
                    Ajouter une note de frais
                  </Button>
                </>
              )}

              {selectedTab === 1 && pack !== AppPacks.Entrepreneur && (
                <Button
                  color="warning"
                  outline
                  type="button"
                  onClick={async () => await archiveAllTransactions(context!)}
                >
                  Archiver tout
                </Button>
              )}
            </div>
            <Tabs
              selectedTab={selectedTab}
              onClick={setSelectedTab}
              tabs={pack === AppPacks.Entrepreneur ? entrepTabs : tabs}
              context={context}
              callback={setReloadTrs}
              reload={reloadTrs}
              company={
                companiesList && companiesList.length > 0
                  ? companiesList.find((elt) => elt.id === context)
                  : company
              }
              immobStateHandler={{
                isImmobFire,
                setIsImmobFire,
                isImmobSubCateg: isImmobSubCateg || null,
                setIsImmobSubCateg,
                isImmobTrans: isImmobTrans || null,
                setIsImmobTrans,
              }}
            />
          </Card>
        </div>
        <div className="openbtn text-center">
          {/*add espece*/}
          <Modal
            className="modal-secondary modal-dialog-centered "
            isOpen={addEspeceModal}
            toggle={() => {
              setAddEspeceModal(false);
              resteForm();
            }}
          >
            <ModalHeader
              toggle={() => {
                setAddEspeceModal(false);
                resteForm();
              }}
            >
              Ajouter des espèces
            </ModalHeader>
            <form onSubmit={handleSubmit(createTransaction)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="label">Libellé</Label>
                        <Input
                          id="label"
                          innerRef={labelRef}
                          {...label}
                          type="text"
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={MoneyIconBlue} alt="icon" /> */}
                          {<ReactSVG src={MoneyIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Date</Label>
                        <Controller
                          control={control}
                          name="date"
                          render={({ field }) => (
                            <DatePicker
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        {/* <Input
                          id="date"
                          innerRef={dateRef}
                          {...date}
                          type="date"
                          className="form-secondary"
                        /> */}
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={CalenderIconBlue} alt="icon" /> */}
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Catégorie</Label>
                        <Controller
                          name="category"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={inputSubCategs
                                .filter(
                                  (elt) =>
                                    ![
                                      "IN-017",
                                      "IN-016",
                                      "IN-019",
                                      "IN-022",
                                    ].includes(elt.key)
                                )
                                .reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    { value: curr.id, label: curr.name },
                                  ],
                                  [] as { value: string; label: string }[]
                                )}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="amount">Montant</Label>
                        <Input
                          id="amount"
                          innerRef={amountRef}
                          {...amount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          step={0.01}
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                          min={0}
                        />
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={packMoneyIcons} alt="icon" /> */}
                          {<ReactSVG src={packMoneyIcons} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <div className="form-icon icon-start form-file file-secondary mt-4">
                          <span className="label-file">
                            Déposez le fichier ici
                          </span>
                          <label className="file-box ">
                            <RiDownload2Fill />
                            <Input
                              id="document"
                              innerRef={documentRef}
                              {...document}
                              className="form-default"
                              type="file"
                            />
                            <span> Télécharger d'ici</span>
                          </label>
                        </div>
                      </FormGroup>
                      <div className="list-Files">
                        {_document && _document[0] && (
                          <span className="file-box-item">
                            <AttacheIcon />
                            <pre>{_document[0].name}</pre>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                  {errorMessage?.type === "undexpected_issue" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setAddEspeceModal(false);
                    resteForm();
                  }}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    setValue("modalType", "positive");
                  }}
                >
                  Ajouter
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*add note frais*/}
          <Modal
            className="modal-primary modal-dialog-centered"
            isOpen={addNoteFraisModal}
            toggle={() => {
              setAddNoteFraisModal(false);
              resteForm();
            }}
          >
            <ModalHeader
              toggle={() => {
                setAddNoteFraisModal(false);
                resteForm();
              }}
            >
              Ajouter une note de frais
            </ModalHeader>
            <form onSubmit={handleSubmit(createTransaction)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="label">Libellé</Label>
                        <Input
                          id="label"
                          innerRef={labelRef}
                          {...label}
                          type="text"
                          className="form-primary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-primary icon-primary-fill">
                          {/* <img src={MoneyIconGreen} alt="icon" /> */}
                          {<ReactSVG src={MoneyIconGreen} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Date</Label>
                        <Controller
                          control={control}
                          name="date"
                          render={({ field }) => (
                            <DatePicker
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-primary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        {/* <Input
                          id="date"
                          innerRef={dateRef}
                          {...date}
                          type="date"
                          className="form-primary"
                        /> */}
                        <span className="icon icon-primary icon-primary-fill">
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                          {<ReactSVG src={CalenderIconGreen} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Catégorie</Label>
                        <Controller
                          name="category"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={outputSubCategs.reduce(
                                (acc, curr) => [
                                  ...acc,
                                  { value: curr.id, label: curr.name },
                                ],
                                [] as { value: string; label: string }[]
                              )}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="amount">Montant</Label>
                        <Input
                          id="amount"
                          innerRef={amountRef}
                          {...amount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          step={0.01}
                          className="form-primary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                          min={0}
                        />
                        <span className="icon icon-primary icon-primary-fill">
                          {/* <img src={MoneySacIconGreen} alt="icon" /> */}
                          {<ReactSVG src={MoneySacIconGreen} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <div className="form-icon icon-start form-file file-primary mt-4">
                          <span className="label-file">
                            Déposez le fichier ici
                          </span>
                          <label className="file-box ">
                            <RiDownload2Fill />
                            <Input
                              id="document"
                              innerRef={documentRef}
                              {...document}
                              className="form-default"
                              type="file"
                            />
                            <span> Télécharger d'ici</span>
                          </label>
                        </div>
                      </FormGroup>
                      <div className="list-Files">
                        {_document && _document[0] && (
                          <span className="file-box-item">
                            <AttacheIcon />
                            <pre>{_document[0].name}</pre>
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                  {errorMessage?.type === "undexpected_issue" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddNoteFraisModal(false);
                    resteForm();
                  }}
                  type="button"
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setValue("modalType", "negative");
                  }}
                >
                  Ajouter
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*archive all transactions*/}
          <Modal
            className="modal-warning modal-dialog-centered "
            isOpen={archiveAllErrorModal}
            toggle={() => {
              setArchiveAllErrorModal(false);
              setArchiveAllInvalidStatus(false);
              setArchiveAllInvaliddocs(false);
              setArchiveAllSpecialInvaliddocs(false);
              setErrorMessage(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setArchiveAllErrorModal(false);
                setArchiveAllInvalidStatus(false);
                setArchiveAllInvaliddocs(false);
                setArchiveAllSpecialInvaliddocs(false);
                setErrorMessage(null);
              }}
            >
              Archiver tout
            </ModalHeader>
            <ModalBody>
              <div className="content-form-block archive_all__errors text-center">
                {archiveAllInvalidStatus && (
                  <p>
                    Certaines des transactions ne sont pas valides pour
                    l'archivage
                  </p>
                )}
                {archiveAllInvalidDocs && (
                  <p>
                    Certaines des transactions n'ont pas de document
                    jsutificatifs, voulez-vous continuer ?
                  </p>
                )}
                {archiveAllSepcialInvalidDocs && (
                  <p>
                    Un justificatif doit être fourni pour ce type d’opérations.
                    En cas de contrôle, vous devrez être en mesure de fournir
                    toutes les pièces nécessaires.
                  </p>
                )}
                {errorMessage?.type === "undexpected_issue" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>
            {!archiveAllInvalidStatus && (
              <ModalFooter>
                <Button
                  color="warning"
                  onClick={async () =>
                    await archiveAllTransactions(context!, 1)
                  }
                >
                  {archiveAllSepcialInvalidDocs ? "J’ai compris" : "Archiver"}
                </Button>{" "}
                <Button
                  color="warning"
                  outline
                  onClick={() => {
                    setArchiveAllErrorModal(false);
                    setArchiveAllInvalidStatus(false);
                    setArchiveAllInvaliddocs(false);
                    setArchiveAllSpecialInvaliddocs(false);
                    setErrorMessage(null);
                  }}
                >
                  Annuler
                </Button>
              </ModalFooter>
            )}
          </Modal>
        </div>
      </div>
    </>
  );
};

export default MesTransactions;
