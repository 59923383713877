import React, { useEffect, useState } from "react";
import "./OdTva.scss";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import TopCard from "../../components/TopCard/TopCard";
import collectIcon from "../../assets/images/svg/collect-percent-icon.svg";
import DeductibleIcon from "../../assets/images/svg/money-percent-icon.svg";
import TVAIcon from "../../assets/images/svg/calculator-icon.svg";
import DataTable from "react-data-table-component";
import { BootyPagination } from "../../components/table/pagination";
import { data } from "../../constants";
import moment, { Moment } from "moment";
import config from "../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { Company, IODTVA } from "../../interfaces";
import { useFormatter } from "../../util/hooks/useFormatter";
import useAxios from "../../util/hooks/useAxios";
import Status from "../../components/badge/Status";
import CheckIcon from "../../assets/CheckIcon";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import {
  RiAlertFill,
  RiCheckLine,
  RiShareForwardFill,
  RiTestTubeLine,
} from "react-icons/ri";
import { ODTVATypes, ODTVA_OPTIONS, OptionType } from "../../util/context";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import Select from "react-select";
import Tabs from "../../components/Tabs";
import { TabsType } from "../Facturation";
import Export from "./Export";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

const { API_URL } = config[process.env.NODE_ENV];

type SearchODTVAFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  status?: OptionType | null;
};

const OdTva = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const columns: any = [
    {
      name: "Mois",
      selector: (row: IODTVA) =>
        moment(row.dateTo).format("MMMM/YYYY").toUpperCase(),
      sortable: true,
    },
    {
      name: "TVA Collectée",
      selector: (row: IODTVA) =>
        setDecimalDigits(Math.abs(row.collected)) + " €",
      sortable: true,
    },
    {
      name: "TVA Déductible",
      selector: (row: IODTVA) =>
        setDecimalDigits(Math.abs(row.deductible)) + " €",
      sortable: true,
    },

    {
      name: "TVA",
      selector: (row: IODTVA) => (
        <>
          <div className="tva-recif-wrapper">
            <p
              className={
                Math.abs(row.collected!) === 0 &&
                Math.abs(row.accumulated!) === 0
                  ? ""
                  : Math.abs(row.collected!) > Math.abs(row.deductible!)
                  ? "text-danger"
                  : "text-success"
              }
            >
              {setDecimalDigits(Math.abs(row.accumulated)) + " €" || 0}
            </p>
            {odtvaRectifs.length > 0 &&
              odtvaRectifs.filter((elt) => elt.related_id === row.id).length >
                0 && (
                <>
                  <p id={`rectif-${row.id}`}>
                    <RiAlertFill />
                  </p>

                  <UncontrolledTooltip
                    target={`rectif-${row.id}`}
                    className="rectifi-popver-body"
                    placement="bottom"
                  >
                    <span>Rectifications</span>
                    <ul>
                      {odtvaRectifs
                        .filter((elt) => elt.related_id === row.id)
                        .map((elt, index) => (
                          <li key={index}>
                            <>
                              {moment(elt.dateFrom)
                                .format("MMMM/YYYY")
                                .toUpperCase()}
                              : {setDecimalDigits(elt.accumulated) + " €"}
                            </>
                          </li>
                        ))}
                    </ul>
                  </UncontrolledTooltip>
                </>
              )}
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row: IODTVA) => (
        <div className="table-action small-btns">
          {String(row.status) === "101" && (
            <>
              <button
                type="button"
                className="btn btn-primary btn-small"
                onClick={() => {
                  setSingleODTVA(row);
                  setDeclareModal(true);
                }}
              >
                Déclarer
              </button>
              <button
                type="button"
                className="btn btn-warning btn-small"
                onClick={() => {
                  setSingleODTVA(row);
                  setReportModal(true);
                }}
              >
                Reporter
              </button>
            </>
          )}
          {(String(row.status) === "102" || String(row.status) === "103") && (
            <>
              {String(row.status) === "102" ? (
                <span className="delcared-odtva">
                  <RiCheckLine /> Déclaré
                </span>
              ) : (
                <span className="reported-odtva">
                  <RiShareForwardFill /> Reporté
                </span>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const testColumns: any = [
    {
      name: "Scénario",
      selector: (row: any) => row.case,
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row: any) => (
        <div className="table-action small-btns">
          <>
            <button
              type="button"
              className="btn btn-primary btn-small"
              onClick={async () => {
                await test(row.api);
              }}
            >
              Exécuter
            </button>
          </>
        </div>
      ),
    },
  ];

  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD hh:mm")
  );
  const [collectedTVA, setCollectedTVA] = useState<number | null>(0);
  const [detuctibleTVA, setDeductibleTVA] = useState<number | null>(0);
  const [odtvas, setODTVAs] = useState<IODTVA[]>([]);
  const [odtvaRectifs, setODTVARectifs] = useState<IODTVA[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [declareModal, setDeclareModal] = useState<boolean>(false);
  const [singleODTVA, setSingleODTVA] = useState<IODTVA | null>();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };
  let api = useAxios();

  const [selectedTab, setSelectedTab] = useState<number>(1);

  const getNonDecutibleTVA = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/collectedTVA`,
        {
          dateFrom: moment(currentDate)
            .startOf("month")
            .format("YYYY-MM-DD hh:mm"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD hh:mm"),
          companyId: company?.id,
          pack: company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setCollectedTVA(data.tva.length > 0 ? data.tva[0].result : 0);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const getDecutibleTVA = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/decutibleTVA`,
        {
          dateFrom: moment(currentDate)
            .startOf("month")
            .format("YYYY-MM-DD hh:mm"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD hh:mm"),
          companyId: company?.id,
          pack: company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setDeductibleTVA(data.tva.length > 0 ? data.tva[0].result : 0);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const getODTVAs = async () => {
    try {
      const { data } = await api.post(
        `/api/ODTVA/all`,
        {
          where: {
            companyId: company?.id,
            status: [101, 102, 103, 100],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const odtvaFull = data.data
        // .reverse()
        .filter(
          (elt: { type: ODTVATypes; status: Number }) =>
            elt.type === ODTVATypes.Full && elt.status !== 100
        )
        .sort((a: { dateFrom: string }, b: { dateFrom: string }) => {
          return moment(a.dateFrom).diff(moment(b.dateFrom));
        });
      const odtvaUpdate = data.data
        // .reverse()
        .filter((elt: { type: ODTVATypes }) => elt.type === ODTVATypes.Update);
      setODTVAs(odtvaFull);
      setODTVARectifs(odtvaUpdate);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const decalreODTVA = async (id: string) => {
    try {
      const { data } = await api.post(
        `/api/ODTVA/update`,
        {
          id,
          status: 102,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await getODTVAs();
      setSingleODTVA(null);
      setDeclareModal(false);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const reportODTVA = async (id: string) => {
    try {
      await api.post(
        `/api/ODTVA/update`,
        {
          id,
          status: 103,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      await getODTVAs();
      setSingleODTVA(null);
      setReportModal(false);
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  const test = async (endpoint: string) => {
    try {
      const { data } = await api.post(
        `/api/tests/${endpoint}`,
        {
          company,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.data === "ok") {
        await getNonDecutibleTVA();
        await getDecutibleTVA();
        await getODTVAs();
        setSelectedTab(1);
      }
    } catch (error: any) {
      ErrorLogger("getting od tva data", error);
    }
  };

  useEffect(() => {
    getNonDecutibleTVA();
    getDecutibleTVA();
    getODTVAs();
  }, []);

  const { setDecimalDigits } = useFormatter();

  useEffect(() => {
    getNonDecutibleTVA();
    getDecutibleTVA();
  }, [currentDate]);

  const resetSearchForm = async () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      status: null,
    });
    await getODTVAs();
  };

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchODTVAFormValues>({});

  const searchOdtvas: SubmitHandler<SearchODTVAFormValues> = async (
    form: SearchODTVAFormValues
  ) => {
    try {
      setLoading(true);
      let payload: any = {
        companyId: company?.id,
        type: ODTVATypes.Full,
      };
      if (form.dateFrom || form.dateTo)
        payload.dateFrom = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format("YYYY-MM-DD hh:mm")
            : moment().add(1, "day").format("YYYY-MM-DD hh:mm"),
        };

      if (form.status) payload.status = String(form.status.value);

      const { data } = await api.post(
        `/api/ODTVA/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setODTVAs(data.data.reverse());
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("searching odtvas", error);
    }
  };

  return (
    <div className="page page-odt-tva">
      <div className="top-odt-tva">
        <div className="action-top-odt">
          <span className="month-label">
            {moment(currentDate).format("MMMM/YYYY")}
          </span>
          <div className="od-tva-wrapper">
            <button
              type="button"
              className="btn btn-outline-secondary "
              onClick={() =>
                setCurrentDate(
                  moment(currentDate)
                    .subtract(1, "month")
                    .format("YYYY-MM-DD hh:mm")
                )
              }
            >
              Mois précédent
            </button>
            <button
              type="button"
              className="btn btn-secondary "
              onClick={() =>
                setCurrentDate(
                  moment(currentDate).add(1, "month").format("YYYY-MM-DD hh:mm")
                )
              }
            >
              Mois suivant
            </button>
          </div>
        </div>
        <Row>
          <Col lg={4} md={12}>
            <TopCard
              title="TVA collectée"
              text={setDecimalDigits(Math.abs(collectedTVA!)) + " €"}
              icon={collectIcon}
              className="card-secondary"
            />
          </Col>
          <Col lg={4} md={12}>
            <TopCard
              title="TVA déductible"
              text={setDecimalDigits(Math.abs(detuctibleTVA!)) + " €"}
              icon={DeductibleIcon}
              className="card-purple"
            />
          </Col>
          <Col lg={4} md={12}>
            <TopCard
              title={
                Math.abs(collectedTVA!) === 0 && Math.abs(detuctibleTVA!) === 0
                  ? "TVA"
                  : Math.abs(collectedTVA!) > Math.abs(detuctibleTVA!)
                  ? "TVA DUE"
                  : "Crédit de TVA "
              }
              text={
                setDecimalDigits(
                  Math.abs(Math.abs(detuctibleTVA!) - Math.abs(collectedTVA!))
                ) + " €"
              }
              icon={TVAIcon}
              className={
                Math.abs(collectedTVA!) === 0 && Math.abs(detuctibleTVA!) === 0
                  ? "card-warning"
                  : Math.abs(collectedTVA!) > Math.abs(detuctibleTVA!)
                  ? "card-danger"
                  : "card-primary"
              }
            />
          </Col>
        </Row>
      </div>
      <div className=" section-facturation">
        <div className="card-Table table-secondary card">
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={[
              {
                label: "ODTva",
                index: 1,
                Component: () => (
                  <>
                    <div className="search-top custom_search-top">
                      <form onSubmit={searchHandleSubmit(searchOdtvas)}>
                        <Row className="align-items-end">
                          <Col md={8}>
                            <div className="filterInner">
                              <Row>
                                <Col md={4}>
                                  <FormGroup className="form-icon icon-end">
                                    <Label for="dated">Date de début</Label>
                                    <Controller
                                      control={searchControl}
                                      name="dateFrom"
                                      render={({ field }) => (
                                        <DatePicker
                                          placeholderText="Date de début"
                                          onChange={(date: any) =>
                                            field.onChange(date)
                                          }
                                          selected={
                                            field.value
                                              ? new Date(field.value)
                                              : null
                                          }
                                          className="form-control form-secondary"
                                          locale="fr"
                                          dateFormat="MM/yyyy"
                                          showMonthYearPicker
                                        />
                                      )}
                                    />

                                    <span className="icon icon-secondary icon-secondary-fill">
                                      {/* <img src={CalenderIconBlue} alt="icon" /> */}
                                      {<ReactSVG src={CalenderIconBlue} />}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup className="form-icon icon-end">
                                    <Label for="datef">Date de fin</Label>
                                    <Controller
                                      control={searchControl}
                                      name="dateTo"
                                      render={({ field }) => (
                                        <DatePicker
                                          placeholderText="Date de fin"
                                          onChange={(date: any) =>
                                            field.onChange(date)
                                          }
                                          selected={
                                            field.value
                                              ? new Date(field.value)
                                              : null
                                          }
                                          className="form-control form-secondary"
                                          locale="fr"
                                          dateFormat="MM/yyyy"
                                          showMonthYearPicker
                                        />
                                      )}
                                    />

                                    <span className="icon icon-secondary icon-secondary-fill">
                                      {/* <img src={CalenderIconBlue} alt="icon" /> */}
                                      {<ReactSVG src={CalenderIconBlue} />}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup className="form-icon icon-start">
                                    <Label for="pwd">Statut</Label>
                                    <Controller
                                      name="status"
                                      control={searchControl}
                                      render={({ field }) => (
                                        <Select
                                          {...field}
                                          options={ODTVA_OPTIONS}
                                          closeMenuOnSelect={true}
                                          classNamePrefix="select"
                                          className="custom-select form-secondary"
                                        />
                                      )}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="actionsFilter mb-3">
                              <Button color="secondary" type="submit">
                                <span>Filtrer</span>
                              </Button>
                              <Button
                                color="secondary"
                                outline
                                type="button"
                                onClick={async () => await resetSearchForm()}
                              >
                                <span>Réinitialiser</span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                    <div className="odt-pages">
                      <Card className="odt-tva-Table table-primary">
                        <DataTable
                          columns={columns}
                          data={odtvas}
                          pagination
                          noDataComponent={
                            <p>Il n'y a aucun data à afficher</p>
                          }
                          progressPending={loading}
                          progressComponent={
                            <>
                              <Spinner color="light" type="grow">
                                Loading...
                              </Spinner>
                              <Spinner color="light" type="grow">
                                Loading...
                              </Spinner>
                              <Spinner color="light" type="grow">
                                Loading...
                              </Spinner>
                            </>
                          }
                          conditionalRowStyles={[
                            {
                              when: (row: IODTVA) =>
                                String(row.status) === "102" ||
                                String(row.status) === "103",
                              style: {
                                // backgroundColor: "#dcdcdc",
                                // opacity: "0.4",
                                marginBottom: "5px",
                              },
                            },
                          ]}
                          paginationComponent={(props) => {
                            const customProps = { ...props, color: "primary" };
                            return <BootyPagination {...customProps} />;
                          }}
                        />
                      </Card>
                    </div>
                  </>
                ),
              },
              {
                label: "Export",
                index: 2,
                Component: () => <Export company={company} />,
              },
              // {
              //   label: "Scénarios de test possibles",
              //   index: 3,
              //   Component: () => (
              //     <>
              //       <div className="odt-pages">
              //         <div>
              //           <ul>
              //             <li>
              //               <RiTestTubeLine /> Si la dernière odtva est toujours
              //               en attente, vous pouvez archiver ou désarchiver une
              //               transaction d'un mois précédent pour voir apparaître
              //               la rectification.
              //             </li>
              //             <li>
              //               <RiTestTubeLine /> Vous pouvez rapporter le dernier
              //               odtva et archiver ou désarchiver une transaction
              //               d'un mois précédent pour vérifier comment tous les
              //               odtvas sont mis à jour en conséquence
              //             </li>
              //           </ul>
              //         </div>
              //         <Card className="odt-tva-Table table-primary">
              //           <DataTable
              //             columns={testColumns}
              //             data={[
              //               {
              //                 case: "Créer odtav pour 06/2022 + 05/2022 a dejà des rectifications + 05/2022 est rapporté",
              //                 api: "juinReportedUpdates",
              //               },
              //               {
              //                 case: "Créer odtav pour 06/2022 + 06/2022 a dejà des rectifications + 05/2022 est en attente et sera mis à jour pour être rapporté automatiquement",
              //                 api: "juinPendingUpdates",
              //               },
              //               {
              //                 case: "Créer odtav pour 01/2022 + la première odtva jamais créée",
              //                 api: "janFirst",
              //               },
              //             ]}
              //             pagination
              //             noDataComponent={
              //               <p>Il n'y a aucun data à afficher</p>
              //             }
              //             progressPending={loading}
              //             progressComponent={
              //               <>
              //                 <Spinner color="light" type="grow">
              //                   Loading...
              //                 </Spinner>
              //                 <Spinner color="light" type="grow">
              //                   Loading...
              //                 </Spinner>
              //                 <Spinner color="light" type="grow">
              //                   Loading...
              //                 </Spinner>
              //               </>
              //             }
              //             paginationComponent={(props) => {
              //               const customProps = { ...props, color: "primary" };
              //               return <BootyPagination {...customProps} />;
              //             }}
              //           />
              //         </Card>
              //       </div>
              //     </>
              //   ),
              // },
            ]}
            context={context}
            company={company}
          />
        </div>
      </div>
      <div className="openbtn text-center">
        {/*
        report
        */}
        <Modal
          className="modal-warning modal-dialog-centered"
          isOpen={reportModal}
          toggle={() => {
            setReportModal(false);
            setSingleODTVA(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setReportModal(false);
              setSingleODTVA(null);
            }}
          >
            Reporter
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir reporter la TVA du mois{" "}
                {moment(singleODTVA?.dateTo).format("MMMM/YYYY").toUpperCase()}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              onClick={() => {
                setReportModal(false);
                setSingleODTVA(null);
              }}
            >
              Non
            </Button>
            <Button
              color="warning"
              onClick={async () => await reportODTVA(singleODTVA?.id!)}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
        {/*
        declare
        */}
        <Modal
          className="modal-primary modal-dialog-centered"
          isOpen={declareModal}
          toggle={() => {
            setDeclareModal(false);
            setSingleODTVA(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDeclareModal(false);
              setSingleODTVA(null);
            }}
          >
            Déclarer
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir déclarer la TVA du mois{" "}
                {moment(singleODTVA?.dateTo).format("MMMM/YYYY").toUpperCase()}?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={() => {
                setDeclareModal(false);
                setSingleODTVA(null);
              }}
            >
              Non
            </Button>
            <Button
              color="primary"
              onClick={async () => await decalreODTVA(singleODTVA?.id!)}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default OdTva;
