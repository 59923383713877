import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import "./style.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import config from "../../config";
import { useSelector } from "react-redux";
import axios from "axios";
import { ErrorLogger } from "../../util/errorLogger";
import { Company, IS3, ITransaction, User } from "../../interfaces";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import UserProsp from "../../assets/images/svg/user-blue.svg";
import UserChat from "../../assets/images/avatar.png";
import { Watch } from "typescript";
import {
  RiBriefcase4Line,
  RiCalendar2Line,
  RiCameraLine,
  RiCheckboxCircleLine,
  RiCommunityLine,
  RiDownload2Fill,
  RiFlagLine,
  RiIndeterminateCircleFill,
  RiMapPinLine,
} from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import moment from "moment";
import "moment/locale/fr";
import Select from "react-select";
import {
  GENDER_OPTIONS,
  OptionType,
  TransactionMediums,
} from "../../util/context";
import EmailIcon from "../../assets/images/svg/mail-blue.svg";
import PhoneIcon from "../../assets/images/svg/phone-blue.svg";
import { BsMailbox, BsTrashFill } from "react-icons/bs";
import { BootyPagination } from "../../components/table/pagination";
import DataTable from "react-data-table-component";
import useAxios from "../../util/hooks/useAxios";

type GeneralFormValues = {
  file: any;
};

const { API_URL } = config[process.env.NODE_ENV];

const TransactionsImport = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [successInsert, setSuccessInsert] = useState<string[]>([]);
  const [failInsert, setFailInsert] = useState<string[]>([]);
  const [fileInputKey, setFileInputKey] = useState<string>("");
  const [files, setFiles] = useState<any>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [duplicationModal, setDuplicationModal] = useState<boolean>(false);
  const [duplicates, setDuplicates] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  const [insertErrorMessage, setInsertErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  const [successMessage, setSuccessMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const columnsArr: any = [
    {
      name: "Date",
      selector: (row: any) => moment(row.date).format("YYYY/MM/DD"),
    },
    {
      name: "Transaction",
      selector: (row: any) => row.label,
    },
    {
      name: "Montant",
      selector: (row: any) => row.amount,
    },
  ];
  const handleRowSelected = (state: {
    selectedRows: React.SetStateAction<never[]>;
  }) => setSelectedRows(state.selectedRows);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: object }) => state.user
  ) as { user: User };

  const { id } = useParams();

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  // const { file: _file } = watch();

  // const { ref: fileRef, ...file } = register("file");

  let api = useAxios();

  const uploadTransactions: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);
      if (files.length === 0) {
        setErrorMessage({
          type: "not_valid",
          message: "Veuillez remplir tous les champs nécessaires du formulaire",
        });
        setLoading(false);
        return;
      }

      const formData = new FormData();
      formData.append("companyId", id as string);

      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        formData.append(`bank_statement-[${index}]`, element);
      }

      const { data } = await api.post(
        `/api/documents/bank-statement`,
        formData,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data) {
        
        if (data.uploaded.length > 0) {
          setSuccessMessage({
            type: "upload_success",
            message: (
              <Row>
                <Col md={12}>
                  Les transactions ont été insérées avec succès pour les
                  fichiers :{" "}
                </Col>
                <Col md={12}>
                  <ul>
                    {data.uploaded.map(
                      (
                        elt:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined,
                        key: React.Key | null | undefined
                      ) => (
                        <li key={key}>
                          <RiCheckboxCircleLine /> {elt}
                        </li>
                      )
                    )}
                  </ul>
                </Col>
              </Row>
            ),
          });
        }

        if (data.notUploaded.length > 0) {
          setErrorMessage({
            type: "upload_pending",
            message: (
              <Row>
                <Col md={12}>On peut pas traité : </Col>
                <Col md={12}>
                  <ul>
                    {data.notUploaded.map(
                      (
                        elt:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined,
                        key: React.Key | null | undefined
                      ) => (
                        <li key={key}>
                          <RiIndeterminateCircleFill /> {elt}
                        </li>
                      )
                    )}
                  </ul>
                </Col>
              </Row>
            ),
          });
        }

        if (data.duplicated.length > 0) {
          setDuplicates(data.duplicated);
          setDuplicationModal(true);
        }
      }

      reset();
      setLoading(false);
      setFiles(null);
    } catch (error: any) {
      ErrorLogger("updating client form", error);
      setLoading(false);
      reset();
      setErrorMessage({
        type: "upload_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  const insertTransaction = async () => {
    try {
      setLoading(true);
      setInsertErrorMessage(null);
      if (selectedRows.length === 0) {
        setInsertErrorMessage({
          type: "not_valid_duplicates",
          message: "Veuillez choisir au moins 1 trannsaction",
        });
        setLoading(false);
        return;
      }
      for (let transaction of selectedRows) {
        const formData = new FormData();

        formData.append("amount", (transaction as any).amount as any);
        formData.append("label", (transaction as any).label as string);
        formData.append("status", 100 as any);
        formData.append("companyId", id as string);
        formData.append(
          "date",
          moment((transaction as any).date)
            .set({ hour: 12, minute: 0 })
            .format("YYYY-MM-DD hh:mm") as string
        );
        formData.append("medium", TransactionMediums.BankStatement);

        await api.post(`/api/Transaction/Create`, formData, {
          headers: {
            "x-access-token": creds.token,
          },
        });
      }

      setLoading(false);
      setDuplicationModal(false);
      reset();
      setInsertErrorMessage(null);
      setSelectedRows([]);
      setDuplicates([]);
    } catch (error: any) {
      ErrorLogger("adding duplicate transactions", error);
      setLoading(false);
    }
  };

  const resetsFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const resetState = () => {
    reset();
    setLoading(false);
    setErrorMessage(null);
    setSuccessMessage(null);
    setFailInsert([]);
    setSuccessInsert([]);
    setFiles(null);
    resetsFileInput();
  };

  return (
    <div className="page">
      <Card className="card-Table table-primary card-body">
        <form onSubmit={handleSubmit(uploadTransactions)}>
          <div className="file-uploader col-12 col-lg-7 mx-auto">
            <h3>Importation de nouvelles transactions</h3>
            <FormGroup>
              <Label for="proofOfIdentity">Relevé Bancaire</Label>
              <div className="form-icon icon-start form-file file-secondary">
                <span className="label-file">Déposez le fichier ici</span>
                <label className="file-box ">
                  <RiDownload2Fill />
                  <Input
                    id="proofOfIdentity"
                    placeholder="..."
                    className="form-secondary"
                    type="file"
                    multiple
                    onClick={(event) => {
                      (event.target as HTMLInputElement).value =
                        null as unknown as string;
                    }}
                    onChange={(e) => {
                      setErrorMessage(null);
                      setSuccessMessage(null);
                      setFiles((prevState: any) => [
                        ...(prevState || []),
                        ...(e.target.files as any),
                      ]);
                      resetsFileInput();
                    }}
                  />
                  <span> Télécharger d'ici</span>
                </label>
              </div>
            </FormGroup>
          </div>
          <Row className="status-messages">
            <Col md={6}>
              <div className="list-Files">
                {files &&
                  files.length > 0 &&
                  files.map(
                    (
                      elt: {
                        name:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      key: React.Key | null | undefined
                    ) => (
                      <span
                        className="import-transactions file-box-item-with-clickers"
                        key={key}
                      >
                        <span className="file-viewer-click">
                          <AttacheIcon />
                          <span className="file-name">{elt.name}</span>
                        </span>
                        <span
                          className="file-delete-click"
                          onClick={() => {
                            setErrorMessage(null);
                            setFiles((prevState: any) => {
                              return prevState.filter(
                                (file: { name: any }) =>
                                  file.name !== (elt as any).name
                              );
                            });
                          }}
                        >
                          <BsTrashFill />
                        </span>
                      </span>
                    )
                  )}
              </div>
            </Col>
            <Col md={6}>
              {errorMessage?.type === "upload_error" && (
                <div className="d-flex align-items-center text-danger">
                  {errorMessage?.message}
                </div>
              )}
              {errorMessage?.type === "upload_pending" && (
                <div className="d-flex align-items-center text-danger">
                  {errorMessage?.message}
                </div>
              )}
              {loading && (
                <div>
                  <Spinner color="info" type="border" size={"sm"}>
                    Loading...
                  </Spinner>{" "}
                  Traitement , veuillez patienter...
                </div>
              )}
              {successMessage?.type === "upload_success" && (
                <div className="d-flex align-items-center text-success">
                  {successMessage?.message}
                </div>
              )}
              {errorMessage?.type === "not_valid" && (
                <div className="d-flex align-items-center text-danger">
                  {errorMessage?.message}
                </div>
              )}
            </Col>
          </Row>
          <div className="action-buttons">
            <Button
              color="secondary"
              outline
              type="button"
              disabled={loading}
              onClick={() => resetState()}
            >
              Réinitialiser
            </Button>
            <Button color="secondary" type="submit" disabled={loading}>
              Importation
            </Button>
          </div>
        </form>
      </Card>
      <div className="openbtn text-center">
        {/* select sync account */}
        <Modal
          className="modal-primary modal-dialog-centered duplicates-modal"
          isOpen={duplicationModal}
          toggle={() => {
            setDuplicationModal(false);
            setSelectedRows([]);
            setDuplicates([]);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDuplicationModal(false);
              setSelectedRows([]);
              setDuplicates([]);
            }}
          >
            Transactions dupliquées
          </ModalHeader>
          <ModalBody>
            {!loading ? (
              <Row className="duplicates-actions">
                <Col md={12} className="duplicates-actions-buttons mb-2">
                  <Button
                    color="primary"
                    onClick={() => {
                      setDuplicationModal(false);
                      reset({
                        file: null,
                      });
                      setErrorMessage(null);
                    }}
                  >
                    Ignorer tout
                  </Button>
                  <Button
                    color="primary"
                    outline
                    className="ms-2"
                    onClick={async () => {
                      await insertTransaction();
                    }}
                  >
                    Insérer les transactions sélectionnées
                  </Button>
                </Col>
                {insertErrorMessage?.type === "not_valid_duplicates" && (
                  <div
                    className="d-flex align-items-center text-danger mb-2"
                    color="danger"
                  >
                    {insertErrorMessage?.message}
                  </div>
                )}
              </Row>
            ) : (
              <></>
            )}

            <DataTable
              columns={columnsArr}
              data={duplicates}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              noDataComponent={<p>Il n'y a aucun data à afficher</p>}
              pagination
              progressPending={loading}
              progressComponent={
                <>
                  <div className="d-flex align-items-center text-success">
                    <h5>Insertion des transactions, veuillez patienter</h5>
                  </div>
                </>
              }
              paginationComponent={(props: any) => {
                const customProps = { ...props, color: "primary" };
                return <BootyPagination {...customProps} />;
              }}
            />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default TransactionsImport;
