import React, { useEffect, useState } from "react";
import "./main-layout.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import { Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ErrorLogger } from "../util/errorLogger";
import {
  resetUtil,
  setCounter,
  setReloadTickets,
} from "../store/reducers/utils/utilSlice";
import { persistor } from "../store";
import { SocketProps } from "../interfaces";
import { resetApplications } from "../store/reducers/applications/applicationsSlice";
import { resetClientsList } from "../store/reducers/clients-list/clientsSlice";
import { resetCategories } from "../store/reducers/categories/categoriesSlice";
import { resetCompaniesList } from "../store/reducers/companies-list/companiesSlice";
import { resetUser } from "../store/reducers/user/userSlice";
import { resetPermissionsList } from "../store/reducers/permissions-list/permissionsListSlice";
import { toast, ToastContainer } from "react-toastify";
import { resetNotifs } from "../store/reducers/messages-notifs/messagesNotifsSlice";
import { UserTypes } from "../util/context";
import { deleteWhere } from "../util/helpers/utilities";
import useAxios from "../util/hooks/useAxios";
import { resetRoot } from "../store/reducers/root/rootSlice";

export interface LayoutProps {}

const MainLayout: React.FC<SocketProps> = ({ socket }) => {
  const { counter, errorMessage } = useSelector(
    (state: { util: object }) => state.util
  ) as { counter: null | number; errorMessage: boolean };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  let api = useAxios();

  const [isQontoUser, setIsQontoUser] = useState<boolean>(false);
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const Msg = () => (
    <div>
      Vous avez synchronisé votre compte avec un compte Qonto. Veuillez insérer
      les données correspondantes pour chaque organisation Qonto <strong onClick={()=>navigate('/profil/entreprise/sync')}>Ici</strong>.
    </div>
  );

  const checkIfQontoUser = async () => {
    try {
      const { data: qontoAccounts } = await api.post(
        `/api/BridgeAccount/All`,
        {
          where: {
            bankId: 421,
            companyId: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: syncOrganizations } = await api.post(
        `/api/SyncOrganization/All`,
        {
          where: {
            bankId: 421,
            companyId: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (
        qontoAccounts.data.length > 0 &&
        syncOrganizations.data.length === 0
      ) {
        toast.info(<Msg />, {
          theme: "colored",
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "qontoNotif"
        });
      }
    } catch (error: any) {
      ErrorLogger("getting indemnities", error);
    }
  };

  const leave = async () => {
    try {
      if (creds.role === UserTypes.Client) {
        await deleteWhere(api, creds.token, "CountNotif", {
          company_id: creds.company_id,
        });
      }
      persistor.purge();
      dispatch(resetRoot());
      dispatch(resetApplications());
      dispatch(resetCategories());
      dispatch(resetClientsList());
      dispatch(resetCompaniesList());
      dispatch(resetPermissionsList());
      dispatch(resetUser());
      dispatch(resetNotifs());
      dispatch(resetUtil());
      dispatch(setReloadTickets(false));
      toast.dismiss();
      navigate("/login");
    } catch (error: any) {
      ErrorLogger("logout", error);
    }
  };

  useEffect(() => {
    if (creds.role === UserTypes.Client && location.pathname === "/") {
      checkIfQontoUser()
   }
  }, []);

  useEffect(() => {
    counter &&
      counter > 0 &&
      setTimeout(() => dispatch(setCounter(counter - 1)), 2000);
    counter === 1 && leave();
  }, [counter]);

  return (
    <>
      <ToastContainer autoClose={4000} />
      <div className="main">
        <Sidebar />
        <div className="main__content">
          <TopNav socket={{ socket }} />
          <Outlet context={{ socket }} />
        </div>
      </div>
    </>
  );
};

export default MainLayout;
