import colors from "./colors";
import images from "./images";
// import imgBanner from '../assets/images/img-side.png'

const data = {
  user: {
    id: 1,
    name: "Tuatta",
    picture: images.avt,
  },
  transactions: {
    title: "Mes transactions",
    description: "",
    image: images.imgBanner,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  dashboard: {
    title: "Tableau de Bord",
    description: "",
    image: images.imgDashboard,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  invoice: {
    title: "Facturation",
    description: "",
    image: images.imgInvoice,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  immoblisation: {
    title: "Immobilisation",
    description: "",
    image: images.imgImmobilisation,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  indemnity: {
    title: "Indemnité kilométrique",
    description: "",
    image: images.imgIndemnity,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  bilan: {
    title: "Bilan",
    description: "",
    image: images.imgBilan,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  mesclients: {
    title: "Mes clients",
    description: "",
    image: images.imgBannerClients,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  mesprospects: {
    title: "Mes prospects",
    description: "",
    image: images.imgBannerProspects,
    // background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  gestionadmin: {
    title: "Gestion Admin / White label",
    description: "",
    image: images.imgBannerAdmin,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  gestioncomptable: {
    title: "Gestion comptable",
    description: "",
    image: images.imgBannerAccounting,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  car: {
    title: "Voitures",
    description: "",
    image: images.car,
    button: {
      status: true,
      type: "car",
    },
    background: colors.white,
    // backgroundImage: colors.gradienGreen,
  },
  motorcycle: {
    title: "Motocyclettes",
    description: "",
    image: images.motorcycle,
    button: {
      status: true,
      type: "car",
    },
    background: colors.white,
    // backgroundImage: colors.gradienGreen,
  },
  moped: {
    title: "Cyclomoteurs",
    description: "",
    image: images.moped,
    button: {
      status: true,
      type: "car",
    },
    background: colors.white,
    // backgroundImage: colors.gradienGreen,
  },
  notifications: [
    {
      icon: "bx bx-bell",
      content: "Curabitur id eros quis nunc suscipit blandit",
    },
    {
      icon: "bx bx-bell",
      content:
        "Duis malesuada justo eu sapien elementum, in semper diam posuere",
    },
    // {
    //   icon: "bx bx-cart",
    //   content: "Donec at nisi sit amet tortor commodo porttitor pretium a erat",
    // },
    // {
    //   icon: "bx bx-error",
    //   content: "In gravida mauris et nisi",
    // },
    // {
    //   icon: "bx bx-cart",
    //   content: "Curabitur id eros quis nunc suscipit blandit",
    // },
  ],
  transactionsAvalider: [
    {
      id: 1,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 2,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 3,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 4,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 5,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 6,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 7,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 8,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 9,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 10,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 11,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 12,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 13,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 14,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file 2",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 15,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 16,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 17,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 18,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 19,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 20,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
    {
      id: 21,
      date: "05/15/2022",
      transation: "PRLV SEPA NAVIGO COMUTITRE ECH/...",
      categorie: ["cat1", "cat2"],
      justificatif: "file",
      montant: "-75€",
      tva: ["0€", "10€", "50€"],
      status: " à valider",
    },
  ],
  customers: [
    {
      id: 1,
      name: "Brittan Rois",
      email: "brois0@unicef.org",
      location: "Bator",
      phone: "+62 745 807 7685",
      total_spend: "$557248.44",
      total_orders: 24011,
    },
    {
      id: 2,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 3,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 4,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 5,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 6,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 7,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 8,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 9,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 10,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 11,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
    {
      id: 12,
      name: "Matthew Junifer",
      email: "mjunifer1@buzzfeed.com",
      location: "Bromma",
      phone: "+46 993 722 3008",
      total_spend: "$599864.94",
      total_orders: 60195,
    },
  ],
  mailing: {
    title: "Mailing",
    description: "",
    image: images.imgBannerMailing,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
  tickets: {
    title: "Tickets",
    description: "",
    image: images.imgBannerTickets,
    background: colors.primaryColor,
    backgroundImage: colors.gradienGreen,
  },
};

export default data;
