import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config";
import { saveAs } from 'file-saver';

const { API_URL } = config[process.env.NODE_ENV];

export const createAxiosInsatance = (
  token: string,
  dispatch: any,
  action: any
) => {
  try {
    const axiosInstance = axios.create({
      baseURL: API_URL,
      headers: { "x-access-token": token },
    });

    axiosInstance.interceptors.response.use(
      async (res: any) => {
        return res;
      },
      (error: any) => {
        if (
          (token &&
            !error.response.data.auth &&
            error.response.data.message
              .toLowerCase()
              .includes("token rejected")) ||
          error.response.data.message
            .toLowerCase()
            .includes("failed to authenticate token.")
        ) {
          dispatch(action(4));
          toast.warn(
            "Votre session a expiré, vous serez redirigé vers la page de connexion",
            {
              closeButton: false,
              position: "top-right",
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              toastId: "toast-2022",
            }
          );
          return Promise.reject(error);
        }
      }
    );
    return axiosInstance;
  } catch (error) {
    console.error("createAxiosInsatance : ", error);
  }
};

export const fileSaver = async (payload: any, fileName: string) => {
  try {
    let blob: any = null
    if (typeof payload === "string") {
      const data = await fetch(payload);
      blob = await data.blob();
    } else {
      blob = payload
    }
    saveAs(blob, fileName)
  } catch (error: any) {
    console.error("file saver : ", error)
  }
}