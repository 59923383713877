import React, { lazy, useEffect, useState } from "react";
import "./style.scss";
import T10 from "./t-10.png";
import { Col, Row } from "reactstrap";
import { useFormatter } from "../../util/hooks/useFormatter";

type ThermometerProps = {
  value: number;
  activity: string;
};

const Thermometer = ({ activity, value }: ThermometerProps) => {
  const { entrepCalculator } = useFormatter();
  const [thermTorender, setThermToRender] = useState<any>(T10);
  const [peak, setPeak] = useState<Number>(72600);
  useEffect(() => {
    const val = entrepCalculator(activity, value);
    import(`./${val.t_render}.png`).then((component) =>
      setThermToRender(component.default)
    );

    setPeak(val.peak);
  }, [activity, value]);

  return (
    <div className="therm-wrapper">
      <Row className="therm-render">
        <span className="price-solde">{String(peak.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))} €</span>
        <Col className="therm-render-image">
          <img src={thermTorender} alt={`${value}-alt`} />
          <span className="therm-value">{String(value)} €</span>
        </Col>
      </Row>
      <Row className="therm-text">
        <Col>
          <p>
            * Ce diagramme vous indique les seuils annuels d’encaissement à ne
            pas franchir afin de pouvoir garder votre statut de
            micro-entreprise. Dans le cas où votre activité propose à la fois de
            la prestation de service et une activité commerciale, chaque pan de
            votre activité devra se réfèrer aux seuils prévus : <br /> -
            Activité commerciale : 176200 € <br /> - Prestation de service :
            72600 €.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Thermometer;
