import React, { useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Badge, Button, Card, Col, Row } from "reactstrap";
import { SiPypy } from "react-icons/si";
import { IoIosCash } from "react-icons/io";
import UserInfo from "../../components/user-info/UserInfo";
import { RiUser3Line } from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Tabs from "../../components/Tabs";

import TopCard from "../../components/TopCard/TopCard";
import Notes from "./Notes/Notes";
import InformationSociete from "./InformationSociete/InformationSociete";
import { data } from "../../constants";
import GestionDocuments from "./GestionDocuments/GestionDocuments";
import IbanTelepaiement from "./IbanTelepaiement/IbanTelepaiement";
import ExercicePrecedent from "./ExercicePrecedent/ExercicePrecedent";
import CompteSynchronise from "./CompteSynchronise/CompteSynchronise";
import { Company, User } from "../../interfaces";
import { UserTypes } from "../../util/context";
import { useSelector } from "react-redux";

export interface InfosSocietesProps {}

type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
  blackList: string[];
}[];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Informations société",
    index: 1,
    Component: InformationSociete,
    blackList: [],
  },
  {
    label: "Gestion des Documents",
    index: 2,
    Component: GestionDocuments,
    blackList: [],
  },
  {
    label: "Iban Télépaiement",
    index: 3,
    Component: IbanTelepaiement,
    blackList: [],
  },
  {
    label: "Exercice Précédent",
    index: 4,
    Component: ExercicePrecedent,
    blackList: [],
  },
  {
    label: "Compte Synchronisé",
    index: 5,
    Component: CompteSynchronise,
    blackList: [],
  },
  {
    label: "Notes",
    index: 6,
    Component: Notes,
    blackList: [UserTypes.Client],
  },
];

const InformationsSocietes = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  return (
    <div className="page-infos-societes">
      <div>
        <Card className="card-Table table-primary">
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={tabs.filter((elt) => !elt.blackList.includes(creds.role))}
            context={context}
            company={company}
            client={client}
            callback={callback}
          />
        </Card>
      </div>
    </div>
  );
};

export default InformationsSocietes;
