import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Alert,
  Col,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import moment, { Moment } from "moment";
import { Company, IBilan, IS3, User } from "../../interfaces";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../util/errorLogger";
import { RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../assets/AttacheIcon";
import axios from "axios";
import config from "../../config";
import { BsTrashFill } from "react-icons/bs";
import FileViewer from "../../components/FileViewer";
import useAxios from "../../util/hooks/useAxios";
import FilePicker from "../../components/FilePicker";
import { UserTypes } from "../../util/context";

moment.updateLocale("fr", {});

type GeneralFormValues = {
  etat: number | null;
  faitMarquant: string | null;
  relevesBancaires: IS3[] | null;
  emprunt: IS3[] | null;
  cotisationsObligatoires: IS3[] | null;
  cotisationsFacultatives: IS3[] | null;
  documentsFiscaux: IS3[] | null;
  documentsSupplementaires: IS3[] | null;
  year: number | null;
  liasseFiscale: IS3[] | null;
  FEC: IS3[] | null;
  recapIR: IS3[] | null;
  fakeLiasseFiscale: IS3[] | null;
  fakeFEC: IS3[] | null;
  fakeRecapIR: IS3[] | null;
  zipURL: IS3[] | null;
  fullRecap: IS3[] | null;
};

const { API_URL } = config[process.env.NODE_ENV];

export default function EmptyBilan({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [relevesBancaires, setrelevesBancaires] = useState<any>([]);
  const [emprunt, setemprunt] = useState<any>([]);
  const [cotisationsObligatoires, setcotisationsObligatoires] = useState<any>(
    []
  );
  const [cotisationsFacultatives, setcotisationsFacultatives] = useState<any>(
    []
  );
  const [documentsFiscaux, setdocumentsFiscaux] = useState<any>([]);
  const [documentsSupplementaires, setdocumentsSupplementaires] = useState<any>(
    []
  );

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: Object }) => state.user
  ) as { user: User };

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { ref: faitMarquantRef, ...faitMarquant } = register("faitMarquant");

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  let api = useAxios();

  const handleBilan: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (
        (relevesBancaires.length === 0 ||
          cotisationsObligatoires.length === 0) &&
        creds.role === UserTypes.Client
      ) {
        setErrorMessage({
          type: "missing_files",
          message: "Veuillez fournir toutes les documents obligatoires",
        });
        setLoading(false);
        return;
      }
      formData.append("year", String(new Date().getFullYear() - 1));

      if (creds.role !== UserTypes.Client) {
        formData.append("etat", "2");
        await api.post(
          `/api/Company/Update`,
          {
            id: company?.id!,
            Bilan: true,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      } else {
        formData.append("etat", "1");
      }

      formData.append("company_id", company?.id!);
      if (form.faitMarquant) formData.append("faitMarquant", form.faitMarquant);
      if (relevesBancaires.length > 0) {
        for (let elt of relevesBancaires) {
          formData.append("relevesBancaires", elt);
        }
      }
      if (emprunt.length > 0) {
        for (let elt of emprunt) {
          formData.append("emprunt", elt);
        }
      }
      if (cotisationsObligatoires.length > 0) {
        for (let elt of cotisationsObligatoires) {
          formData.append("cotisationsObligatoires", elt);
        }
      }
      if (cotisationsFacultatives.length > 0) {
        for (let elt of cotisationsFacultatives) {
          formData.append("cotisationsFacultatives", elt);
        }
      }
      if (documentsFiscaux.length > 0) {
        for (let elt of documentsFiscaux) {
          formData.append("documentsFiscaux", elt);
        }
      }
      if (documentsSupplementaires.length > 0) {
        for (let elt of documentsSupplementaires) {
          formData.append("documentsSupplementaires", elt);
        }
      }

      await api.post(`/api/Bilan/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setLoading(false);
      await callback()!;
    } catch (error: any) {
      ErrorLogger("creating bilan", error);
    }
  };

  const handleRelevChange = (event: { target: { files: any } }) => {
    setrelevesBancaires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleEmpruntChange = (event: { target: { files: any } }) => {
    setemprunt((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleCotisationsObligatoiresChange = (event: {
    target: { files: any };
  }) => {
    setcotisationsObligatoires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleCotisationsFacultativesChange = (event: {
    target: { files: any };
  }) => {
    setcotisationsFacultatives((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleDocumentsFiscauxChange = (event: { target: { files: any } }) => {
    setdocumentsFiscaux((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  const handleDocumentsSupplementairesChange = (event: {
    target: { files: any };
  }) => {
    setdocumentsSupplementaires((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleBilan)}>
        <div className="form-bilan">
          <FormGroup className="form-icon icon-start">
            <Label for="cpwd">Fait(s) marquant(s)</Label>
            <Input
              className="form-secondary text-area-custom"
              type="textarea"
              rows="15"
              innerRef={faitMarquantRef}
              {...faitMarquant}
            />
          </FormGroup>
          <div className="box-bilan-item mt-5">
            <h3>
              Relevés bancaires{" "}
              <span className="required-file">(Obligatoire)</span>
            </h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Veuillez-nous transmettre les relevés bancaires de l’année
                  fiscale précédente (1er janvier au 31 décembre). Si vous
                  détenez plusieurs comptes professionnels (pour l’activité
                  renseignée), veuillez télécharger les relevés bancaires pour
                  chacun d’entre eux.
                </p>
                <p className="txt-orange">* Le meilleur format .pdf</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleRelevChange}
                  state={relevesBancaires}
                  renderType={"array"}
                  fileStagedDelete={(file: { name: any }) =>
                    setrelevesBancaires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Emprunt</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Si vous avez un emprunt en cours (ou qui s’est terminé
                  durant l’exercice), veuillez télécharger le tableau
                  d’amortissement de celui-ci
                </p>
                <p className="txt-orange">* Le meilleur format .pdf</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleEmpruntChange}
                  state={emprunt}
                  renderType={"array"}
                  fileStagedDelete={(file: { name: any }) =>
                    setemprunt((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>
              Cotisations sociales obligatoires{" "}
              <span className="required-file">(Obligatoire)</span>
            </h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Appel de cotisation et régularisation des différents
                  organismes
                </p>
                <p className="txt-orange">* Le meilleur format .pdf</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleCotisationsObligatoiresChange}
                  state={cotisationsObligatoires}
                  renderType={"array"}
                  fileStagedDelete={(file: { name: any }) =>
                    setcotisationsObligatoires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Cotisations sociales facultatives</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Tout document relatif à la souscription d’un contrat de
                  prévoyance ou de retraite facultatif
                </p>
                <p className="txt-orange">* Le meilleur format .pdf</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleCotisationsFacultativesChange}
                  state={cotisationsFacultatives}
                  renderType={"array"}
                  fileStagedDelete={(file: { name: any }) =>
                    setcotisationsFacultatives((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Documents fiscaux</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>* On s’en charge 😉</p>
                <p className="txt-orange">* Le meilleur format .pdf</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleDocumentsFiscauxChange}
                  state={documentsFiscaux}
                  renderType={"array"}
                  fileStagedDelete={(file: { name: any }) =>
                    setdocumentsFiscaux((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
          <div className="box-bilan-item">
            <h3>Documents supplémentaires</h3>
            <Row className="inner-box-bilan">
              <Col md={8} sm={7} className="box-text-bilan">
                <p>
                  * Avez-vous d’autres éléments que vous souhaiteriez que nous
                  prenions en compte ?
                </p>
                <p className="txt-orange">* Le meilleur format .pdf</p>
              </Col>
              <Col md={4} sm={5} className="right-bilan-file">
                <FilePicker
                  className="form-file file-secondary "
                  onChange={handleDocumentsSupplementairesChange}
                  state={documentsSupplementaires}
                  renderType={"array"}
                  fileStagedDelete={(file: { name: any }) =>
                    setdocumentsSupplementaires((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== (file as any).name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                />
              </Col>
            </Row>
          </div>
        </div>
        {errorMessage?.type === "missing_files" && (
          <Alert color="danger" className="mt-2">
            {errorMessage?.message}
          </Alert>
        )}
        <div className="text-center py-3 bottom-actions">
          <button
            type="submit"
            className="btn btn-secondary"
            disabled={loading}
          >
            {loading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Valider"
            )}
          </button>
        </div>
      </form>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
}
