import axios from "axios";
import React, { ReactNode, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Card, Col, FormGroup, Label, Row } from "reactstrap";
import config from "../../config";
import { ErrorLogger } from "../../util/errorLogger";
import useAxios from "../../util/hooks/useAxios";
export interface AccountsChoicedProps {}

const { API_URL } = config[process.env.NODE_ENV];

export type Accounts = {
  label: string;
  value: string;
}[];

type GeneralFormValues = {
  accounts: Accounts | null;
};

const AccountsChoice: React.FC<AccountsChoicedProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [companyId, setCompanyId] = useState<string | null>();
  const [item, setItem] = useState<string | null>();

  const [accounts, setAccounts] = useState<Accounts>([]);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [multiLoading, setMultiLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const [multiSyncSuccessMessage, setMultiSyncSuccessMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  useEffect(() => {
    const queries = [...(searchParams as any)] as string[][];
    let accountsArray = [] as Accounts;
    for (let index = 0; index < queries.length; index++) {
      const element = queries[index];
      if (element.includes("companyId")) {
        setCompanyId(element[1]);
      }
      if (element.includes("item")) {
        setItem(element[1]);
      }
      if (
        element.includes("accountId") &&
        !accountsArray.find((elt) => elt.value === element[1])
      ) {
        accountsArray.push({
          label: queries[index + 1][1],
          value: element[1],
        });
      }
    }

    setAccounts(accountsArray);
  }, []);

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  let api = useAxios();

  const synchronize: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setMultiSyncSuccessMessage(null);
      setErrorMessage(null);

      if (!form.accounts || (form.accounts && form.accounts.length === 0)) {
        setErrorMessage({
          type: "invalid_sync_form",
          message: "Veuillez choisir au moins un compte",
        });
        return;
      }

      setMultiLoading(true);

      const { data } = await api.post(
        `/api/accountSync/syncTransactions`,
        {
          companyId,
          accounts: form.accounts?.map((elt) => {
            return { item_id: parseInt(item!), id: elt.value };
          }),
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.error) {
        setMultiLoading(false);
        setErrorMessage({
          type: "multi_sync_error",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }

      reset({
        accounts: null,
      });
      setMultiLoading(false);
      if (data.insert && data.insert === 1) {
        setMultiSyncSuccessMessage({
          type: "multi_sync_success",
          message: (
            <p>
              Vos comptes sont synchronisés avec succès, vous allez être
              redirigé vers la page d'accueil dans quelques instants.
            </p>
          ),
        });
      }
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error: any) {
      ErrorLogger("sync multiple accounts", error);
      setMultiLoading(false);
      setErrorMessage({
        type: "multi_sync_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  return (
    <div className="page-infos-societes">
      <div>
        <Card className="card-Table table-primary">
          <div className="container">
            <div className="section-information-societe">
              <h3>Comptes</h3>
              <form onSubmit={handleSubmit(synchronize)}>
                <Row>
                  <Col md={4} sm={12} className="px-md-4">
                    <FormGroup>
                      <Label for="accounts">
                        Veuillez choisir les comptes à synchroniser
                      </Label>
                      <Controller
                        name="accounts"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={accounts}
                            closeMenuOnSelect={true}
                            isMulti
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {errorMessage?.type === "invalid_sync_form" && (
                  <div className="d-flex justify-content-center text-danger mt-2">
                    {errorMessage?.message}
                  </div>
                )}
                {multiLoading && (
                  <div className="d-flex justify-content-center text-info mt-2">
                    Synchronisation en cours, veuillez patienter.
                  </div>
                )}
                {errorMessage?.type === "multi_sync_error" && (
                  <div className="d-flex justify-content-center text-danger mt-2">
                    {errorMessage?.message}
                  </div>
                )}
                {multiSyncSuccessMessage?.type === "multi_sync_success" && (
                  <div className="d-flex justify-content-center text-success mt-2">
                    {multiSyncSuccessMessage?.message}
                  </div>
                )}
                <div className="text-center py-3 bottom-actions">
                  <Button color="secondary" type="submit" disabled={loading}>
                    Valider
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AccountsChoice;
