import React, { useEffect, useRef, useState } from "react";
import "./assets/boxicons-2.1.1/css/boxicons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/App.scss";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainLayout from "./layout/MainLayout";

import Dashboard from "./pages/Dashboard/Dashboard";
import MesTransactions from "./pages/MesTransactions/MesTransactions";
import Facturation from "./pages/Facturation";
import IndemniteKilometrique from "./pages/IndemniteKilometrique/IndemniteKilometrique";
import Bilan from "./pages/Bilan/Bilan";
// import Immobilisation from "./pages/Immobilisation/Immobilisation";
import Tickets from "./pages/Tickets/Tickets";
import SignIn from "./pages/Account/signin/signin";
import Auth from "./pages/Account";
import GetPassword from "./pages/Account/getPassword/GetPassword";
import ForgotPassword from "./pages/Account/forgotPassword/forgotPassword";
import { ErrorLogger } from "./util/errorLogger";
import axios from "axios";
import config from "./config";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import {
  rehydrateUser,
  setApplication,
  setCompany,
  setCountNotifs,
  setPermissions,
  setUser,
} from "./store/reducers/user/userSlice";
import { useLocation } from "react-router";
import {
  getCountNotifs,
  setReloadTickets,
} from "./store/reducers/utils/utilSlice";
import { Company, CountNotif, IPermission, User } from "./interfaces";
import MesClients from "./pages/MesClients/MesClients";
import GestionProspect from "./pages/GestionProspect/GestionProspect";
import GestionAdmin from "./pages/GestionAdmin/GestionAdmin";
import GestionComptable from "./pages/GestionComptable/GestionComptable";
import { fetchAllClients } from "./store/reducers/clients-list/clientsSlice";
import { fetchAllApplications } from "./store/reducers/applications/applicationsSlice";
import { fetchAllCompanies } from "./store/reducers/companies-list/companiesSlice";
import Mailing from "./pages/Mailing/Mailing";
import SingleTicket from "./pages/Tickets/SingleTicket";
import { io } from "socket.io-client";
import {
  openNewNotifBadge,
  setTicketNotifs,
  updateTicketNotifs,
} from "./store/reducers/messages-notifs/messagesNotifsSlice";
import { fetchAllPermissions } from "./store/reducers/permissions-list/permissionsListSlice";
import ClientBackOffice from "./pages/ClientBackOffice";
import AccountsChoice from "./pages/AccountsChoice";
import SyncProblem from "./pages/SyncProblem";
import AssetType from "./pages/AssetType";
import DevisHandler from "./pages/DevisHandler";
import { usePermissions } from "./util/hooks/usePermissions";
import { AccessPermissions, UserTypes } from "./util/context";

import SyncSuccess from "./pages/SingleSync";

import ErrorSynchro from "./pages/ErrorSynchro/ErrorSynchro";
import Devis from "./pages/Devis/Devis";
import NotFound from "./pages/NotFound";
import { toast } from "react-toastify";
import useAxios from "./util/hooks/useAxios";
import Immobilisation from "./pages/Immobilisation";
import Indemnity from "./pages/Indemnity";
import ProfileInfo from "./pages/ProfileInfo";
import StepOne from "./pages/Account/register/RegisterStepOne";
import StepTwo from "./pages/Account/register/RegisterStepTwo";
import StepThree from "./pages/Account/register/RegisterStepThree";
import StepFour from "./pages/Account/register/RegisterStepFour";
import StepFive from "./pages/Account/register/RegisterStepFive";
import StepAsk from "./pages/Account/register/RegisterStepAsk";
import StepError from "./pages/Account/register/RegisterStepError";
import PaymentError from "./pages/Account/register/PaymentError";
import PaymentSuccess from "./pages/Account/register/PaymentSuccess";
import UserProfileInfo from "./pages/UserProfileInfo";
import ClientWrapper from "./pages/UserProfileInfo/ClientWrapper";
import ClientInfoWrapper from "./pages/UserProfileInfo/ClientWrapper/ClientInfoWrapper";
import GedClientWrapper from "./pages/UserProfileInfo/ClientWrapper/GedClientWrapper";
import CompanyWrapper from "./pages/UserProfileInfo/CompanyWrapper";
import CompanyInfoWrapper from "./pages/UserProfileInfo/CompanyWrapper/CompanyInfoWrapper";
import SyncCompanyWrapper from "./pages/UserProfileInfo/CompanyWrapper/SyncCompanyWrapper";
import GedCompanyWrapper from "./pages/UserProfileInfo/CompanyWrapper/GedCompanyWrapper";
import ExerciceCompanyWrapper from "./pages/UserProfileInfo/CompanyWrapper/ExerciceCompanyWrapper";
import IbanCompanyWrapper from "./pages/UserProfileInfo/CompanyWrapper/IbanCompanyWrapper";
import AdminWrapper from "./pages/UserProfileInfo/AdminWrapper";
import AskSuccess from "./pages/Account/register/AskSuccess";
import AskError from "./pages/Account/register/AskError";

type StopRehydrationRoute = string;

const stopRehydrationRoutes: StopRehydrationRoute[] = [
  "/login",
  "/forgot-password",
  "/reset-password",
  "/register",
  "/devis-handler",
];

const includedRehyBlackListRoutes = (route: string): boolean => {
  return stopRehydrationRoutes.some((elt) =>
    route.toLowerCase().includes(elt.toLocaleLowerCase())
  );
};

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

function App() {
  const [open, setOpen] = useState(false);
  const [prevRoute, setPrevRoute] = useState<String>("/");
  function toggle() {
    setOpen(true);
  }

  let socket: any = useRef();

  const dispatch = useDispatch();

  const { user, permissions, company } = useSelector(
    (state: { root: object; user: object; count: object; company: object }) =>
      state.user
  ) as {
    user: User;
    permissions: IPermission[];
    count: CountNotif;
    company: Company;
  };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  let api = useAxios();
  const location = useLocation();

  const { reloadTickets } = useSelector(
    (state: { util: object }) => state.util
  ) as { reloadTickets: boolean };

  const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
    if (creds.token && creds.token !== "") {
      return children;
    } else {
      return <Navigate to="/login" replace />;
    }
  };

  const ConnectedRoute = ({ children }: { children: React.ReactElement }) => {
    if (creds.token && creds.token !== "") {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
  };

  const AuthorizedRoute = ({
    children,
    permissionNeeded,
  }: {
    children: React.ReactElement;
    permissionNeeded: string;
  }) => {
    const { hasAccessPermission } = usePermissions();

    if (!hasAccessPermission(permissions, permissionNeeded))
      return <Navigate to="/" replace />;

    return <>{children}</>;
  };

  const connectSocket = () => {
    socket.current = io(API_URL, {
      query: {
        "my-key": creds.token,
      },
    });
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await api.post(
          `/api/User/All`,
          {
            where: {
              id: creds.user_id,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        const { data: userData } = data;
        const [userFound] = userData;
        const { company, ...user } = userFound;
        const { application, permissions } = user;
        dispatch(setUser(user));
        company && dispatch(setCompany(company));
        permissions && dispatch(setPermissions(permissions));
        dispatch(setApplication(application));
      } catch (error: any) {
        ErrorLogger("state rehydration in App", error);
      }
    };
    if (
      (!includedRehyBlackListRoutes(location.pathname) ||
        location.pathname !== "/") &&
      creds.user_id
    ) {
      getUser();
      if (creds.role !== UserTypes.Client) {
        dispatch(fetchAllApplications());
        dispatch(fetchAllPermissions());
      }
    }
  }, []);

  useEffect(() => {
    if (creds.token) {
      connectSocket();
    }
  }, [creds.token]);

  const checkUserHasNotifs = async () => {
    try {
      const { data } = await api.post(
        `/api/CustomNotification/All`,
        {
          where: {
            user_id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.data && data.data.length > 0) {
        dispatch(openNewNotifBadge());
      }
    } catch (error: any) {
      ErrorLogger("state rehydration in App", error);
    }
  };

  useEffect(() => {
    if (socket.current) {
      socket.current
        .off(`message-notification-${creds.user_id}`)
        .off(`ticket-notification-${creds.user_id}`)
        .on(`message-notification-${creds.user_id}`, (data: any) => {
          dispatch(openNewNotifBadge());
          dispatch(updateTicketNotifs(data));
        })
        .on(`ticket-notification-${creds.user_id}`, (data: any) => {
          dispatch(openNewNotifBadge());
          dispatch(setReloadTickets(true));
          if (!reloadTickets) {
            dispatch(updateTicketNotifs(data));
          }
        });
    }
  }, [socket.current]);

  useEffect(() => {
    if (creds.user_id) {
      checkUserHasNotifs();
    }
    if (creds.user_id && creds.role !== UserTypes.Client) {
      dispatch(fetchAllCompanies());
      if (creds.role === UserTypes.Cabinet) {
        dispatch(fetchAllClients(creds.user_id));
      }
    }
  }, [creds.user_id, creds.role]);

  useEffect(() => {
    if (
      (prevRoute.includes("profil") || prevRoute.includes("admin")) &&
      !stopRehydrationRoutes.includes(location.pathname) &&
      !location.pathname.includes("profil")
    ) {
      dispatch(rehydrateUser());
      setPrevRoute("/");
    } else {
      setPrevRoute(location.pathname);
    }
  }, [location]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <MainLayout socket={socket} />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <>
              {creds.user_id && creds.role === UserTypes.Client ? (
                <Dashboard />
              ) : (
                <MesClients />
              )}
            </>
          }
        />

        <Route
          path="admin"
          element={
            <>
              <AdminWrapper />
            </>
          }
        />

        <Route
          path="profil"
          element={
            <>
              <UserProfileInfo />
            </>
          }
        >
          <Route
            path="client"
            element={
              <>
                <ClientWrapper />
              </>
            }
          >
            <Route
              index={true}
              element={
                <>
                  <ClientInfoWrapper />
                </>
              }
            />
            <Route
              path="ged"
              element={
                <>
                  <GedClientWrapper />
                </>
              }
            />
          </Route>

          <Route
            path="entreprise"
            element={
              <>
                <CompanyWrapper />
              </>
            }
          >
            <Route
              index={true}
              element={
                <>
                  <CompanyInfoWrapper />
                </>
              }
            />
            <Route
              path="sync"
              element={
                <>
                  <SyncCompanyWrapper />
                </>
              }
            />
            <Route
              path="ged"
              element={
                <>
                  <GedCompanyWrapper />
                </>
              }
            />
            <Route
              path="exercise"
              element={
                <>
                  <ExerciceCompanyWrapper />
                </>
              }
            />
            <Route
              path="iban"
              element={
                <>
                  <IbanCompanyWrapper />
                </>
              }
            />
          </Route>
        </Route>

        <Route
          path="transactions"
          element={
            <>
              <MesTransactions context={user.companyId} pack={creds.pack} />
            </>
          }
        />
        {/* <Route
          path="profil"
          element={
            <>
              <ProfileInfo />
            </>
          }
        /> */}
        <Route
          path="facturation"
          element={
            <>
              <Facturation />
            </>
          }
        />
        <Route
          path="immobilisation"
          element={
            <>
              <Immobilisation />
            </>
          }
        />
        <Route
          path="indemnite"
          element={
            <>
              <Indemnity />
            </>
          }
        />

        <Route
          path="bilan"
          element={
            <>
              {creds.role === UserTypes.Client &&
              company.id &&
              company.Bilan ? (
                <Bilan />
              ) : (
                <Navigate to="/" replace />
              )}
            </>
          }
        />
        <Route
          path="tickets"
          element={
            <>
              <Tickets />
            </>
          }
        />
        <Route
          path="ticket/:id"
          element={
            <>
              <SingleTicket />
            </>
          }
        />

        <Route
          path="gestion-admin"
          element={
            <>
              <AuthorizedRoute permissionNeeded={AccessPermissions.Admin}>
                <GestionAdmin />
              </AuthorizedRoute>
            </>
          }
        />

        {/* <Route
          path="gestion-prospect"
          element={
            <>
              <AuthorizedRoute permissionNeeded={AccessPermissions.Proscpects}>
                <GestionProspect />
              </AuthorizedRoute>
            </>
          }
        /> */}
        {/* <Route path="error-synchro" element={<ErrorSynchro />} /> */}
        <Route
          path="gestion-comptable"
          element={
            <>
              <AuthorizedRoute permissionNeeded={AccessPermissions.Accounting}>
                <GestionComptable />
              </AuthorizedRoute>
            </>
          }
        />

        <Route
          path="mailing"
          element={
            <>
              <Mailing />
            </>
          }
        />

        <Route
          path="account-choice"
          element={
            <>
              <AccountsChoice />
            </>
          }
        />

        <Route
          path="sync-problem"
          element={
            <>
              <ErrorSynchro />
            </>
          }
        />

        <Route
          path="sync-success"
          element={
            <>
              <SyncSuccess />
            </>
          }
        />
      </Route>
      <Route
        path="client/:id"
        element={
          <>
            <ClientBackOffice />
          </>
        }
      />

      <Route
        path="login"
        element={
          <ConnectedRoute>
            <Auth />
          </ConnectedRoute>
        }
      />
      {/* <Route
        path="register"
        element={
          // <ConnectedRoute>
          <Auth />
          // </ConnectedRoute>
        }
      >
        <Route
          index
          element={
            <>
              <ConnectedRoute>
                <StepOne />
              </ConnectedRoute>
            </>
          }
        />
        <Route
          path="activite"
          element={
            <>
              <ConnectedRoute>
                <StepTwo />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="info-activite"
          element={
            <>
              <ConnectedRoute>
                <StepThree />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="pack"
          element={
            <>
              <ConnectedRoute>
                <StepFour />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="info-client"
          element={
            <>
              <ConnectedRoute>
                <StepFive />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="nous-contacter"
          element={
            <>
              <ConnectedRoute>
                <StepAsk />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="contact-envoye"
          element={
            <>
              <ConnectedRoute>
                <AskSuccess />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="contact-non-envoye"
          element={
            <>
              <ConnectedRoute>
                <AskError />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="erreur"
          element={
            <>
              <ConnectedRoute>
                <StepError />
              </ConnectedRoute>
            </>
          }
        />

        <Route
          path="succes-paiement"
          element={
            <>
              <PaymentSuccess />
            </>
          }
        />

        <Route
          path="erreur-paiement"
          element={
            <>
              <ConnectedRoute>
                <PaymentError />
              </ConnectedRoute>
            </>
          }
        />
      </Route> */}

      <Route
        path="forgot-password"
        element={
          <ConnectedRoute>
            <GetPassword />
          </ConnectedRoute>
        }
      />
      <Route
        path="reset-password/:token"
        element={
          <ConnectedRoute>
            <ForgotPassword />
          </ConnectedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
      <Route path="devis-handler" element={<DevisHandler />} />
      {/* <Route path="devis" element={<Devis />} /> */}
    </Routes>
  );
}

export default App;
