import React, { useEffect, useState } from "react";

import "./GestionDocuments.scss";

import { data } from "../../../constants";
import PdfIcons from "../../../assets/images/svg/pdf-icon.svg";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { Company, IS3, User } from "../../../interfaces";
import FileViewer from "../../../components/FileViewer";
import LetterMission from "../LetterMission";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { ErrorLogger } from "../../../util/errorLogger";
import FilePicker from "../../../components/FilePicker";
import { deleteFile } from "../InformationClient";
import {
  GedDocTypes,
  GENDER_OPTIONS,
  OptionType,
  UserTypes,
} from "../../../util/context";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { BootyPagination } from "../../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import { IoMdCreate } from "react-icons/io";
import DataTable from "react-data-table-component";
import OtherDocs from "../../../components/OtherDocs";
registerLocale("fr", fr);

export interface GestionDocProps {}

export const callbackWrapper = async (client: User, callback: any) => {
  if (client?.role !== UserTypes.Client) {
    return callback!(true);
  } else {
    return await callback!();
  }
};

type CustomCompany = Omit<
  Partial<Company>,
  "listOfBeneficialOwners" | "kbis" | "status" | "listOfBeneficialOwners"
> & {
  listOfBeneficialOwners: IS3[] | null;
  kbis: IS3 | null;
  status: IS3 | null;
  // listOfBeneficialOwners: IS3 | null;
};

type CINData = {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  gender?: string | null;
};

const GestionDocuments = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: any;
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [lettreModal, setLettreModal] = useState<boolean>(false);
  const [userCIN, setUserCIN] = useState<any>();
  const [cinLoading, setCinLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let api = useAxios();

  useEffect(() => {
    if (client?.proofOfIdentity) {
      setUserCIN(client?.proofOfIdentity);
    }
  }, [client]);

  useEffect(() => {
    if (reload) {
      callbackWrapper(client!, callback);
      setReload(false);
    }
  }, [reload]);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const updateClient = async (file: any, payload: CINData = {}) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("id", client?.id as string);

      if (file) {
        formData.append("proofOfIdentity", file);
      }

      await api.post(`/api/User/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await callbackWrapper(client!, callback);
      setLoading(false);
      toast.success("Votre ajout a été effectué avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating client cin data", error);
      setLoading(false);
      await callbackWrapper(client!, callback);
    }
  };

  const getCINData = async (file: any) => {
    try {
      setCinLoading(true);
      const formData = new FormData();
      formData.append("cin", file);

      let payload: CINData = {};

      const { data } = await api.post(`/api/documents/cin`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.data.length === 0) {
        setLoading(false);
        setCinLoading(false);
        return;
      }

      if (data.data.FirstName) payload.firstName = data.data.FirstName;

      if (data.data.LastName) payload.lastName = data.data.LastName;

      if (data.data.Birthday)
        if (
          moment(data.data.Birthday, "DD/MM/YYYY", true).isValid() &&
          moment(data.data.Birthday, "DD/MM/YYYY").isBefore(
            moment().format("DD/MM/YYYY")
          )
        ) {
          payload.dateOfBirth = moment(data.data.Birthday).format(
            "YYYY-MM-DD hh:mm"
          );
        }

      if (data.data.Gender)
        payload.gender = data.data.Gender === "F" ? "mrs" : "mr";

      setCinLoading(false);
      return payload;
    } catch (error: any) {
      ErrorLogger("getting cin data", error);
      setLoading(false);
      setCinLoading(false);
    }
  };

  const handleCinChange = async (event: { target: { files: any } }) => {
    try {
      // const payload = (await getCINData(event.target.files![0])) || {};
      await updateClient(event.target.files![0]);
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="section-information-societe">
          <Row>
            <h3>Cahier permanent</h3>
            <Col md={4} sm={12} className="file-uploader">
              <FormGroup>
                <Label for="proofOfIdentity">Carte d'identité</Label>
                <FilePicker
                  className={"form-icon icon-start form-file file-secondary"}
                  onChange={handleCinChange}
                  state={userCIN}
                  fileReadyDelete={async () => {
                    try {
                      setLoading(true);
                      await deleteFile(
                        "User",
                        client?.id!,
                        "proofOfIdentity",
                        creds.token,
                        api
                      );
                      await callbackWrapper(client!, callback);
                      setUserCIN(null);
                      setLoading(false);
                      toast.success(
                        "Votre supression a été effectuée avec succès!",
                        {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    } catch (error: any) {
                      console.table("deleting file : ", error);
                    }
                  }}
                  fileStagedDelete={() => setUserCIN(null)}
                  loader={cinLoading || loading}
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-lg-5">
            <OtherDocs
              modelId={company?.id!}
              data={
                company?.ged_docs && company.ged_docs.length > 0
                  ? company.ged_docs
                  : []
              }
              setRelaod={setReload}
              token={creds.token}
              api={api}
              additionalData={{ type: GedDocTypes.Client }}
            />
          </Row>
        </div>
      </div>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default GestionDocuments;
