import React, { ReactNode, useEffect, useState } from "react";
import "./CompteSynchronise.scss";
import Select from "react-select";
import juridiqueIcons from "../../../assets/images/svg/juridique-icon.svg";
import KeyIcon from "../../../assets/images/svg/key-blue.svg";
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Alert,
  Spinner,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import config from "../../../config";
import { Company, IBridgeAccount, ISyncOrg, User } from "../../../interfaces";
import axios from "axios";
import { ErrorLogger } from "../../../util/errorLogger";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { GeneralFormValues } from "../../Tickets/SingleTicket";
import EMPTY_CONTENT from "../../../assets/images/empty_content.svg";
import { ReactSVG } from "react-svg";
import { OptionType, UserTypes } from "../../../util/context";
import { object } from "yup";
import { Accounts } from "../../AccountsChoice";
import PlusShadow from "../../../assets/PlusShadow";
import DeletShadow from "../../../assets/DeletShadow";
import useAxios from "../../../util/hooks/useAxios";

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

type CompanyFormValues = Partial<Company>;
type SyncOrgFormValues = Omit<Partial<ISyncOrg>, "name" | "login" | "key"> & {
  name: string | null;
  login: string | null;
  key: string | null;
};

type SyncBankFormValues = {
  [prop: string]: OptionType[];
};

const CompteSynchronise = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [syncOrgs, setSyncorgs] = useState<ISyncOrg[]>([]);
  const [bridegAccounts, setBridgeAccounts] = useState<IBridgeAccount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addSyncOrgLoading, setAddSyncOrgLoading] = useState<boolean>(false);
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [multiLoading, setMultiLoading] = useState<boolean>(false);
  const [choiceModal, setChoiceModal] = useState<boolean>(false);
  const [syncModal, setSyncModal] = useState<boolean>(false);
  const [singleSyncOrg, setSingleSyncOrg] = useState<ISyncOrg | null>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [bankFallback, setBankFallback] = useState<{
    [props: string]: string | null;
  }>({});

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: object }) => state.user
  ) as { user: User };

  const { id } = useParams();

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SyncOrgFormValues>({});

  const {
    control: syncControl,
    setValue: syncSetValue,
    getValues: syncGetValue,
    watch: syncWatch,
    register: syncRegister,
    handleSubmit: syncHandleSubmit,
    reset: syncReset,
    formState: { errors: syncError },
  } = useForm<SyncBankFormValues>({});

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const [multiSyncSuccessMessage, setMultiSyncSuccessMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const { ref: nameRef, ...name } = register("name");
  const { ref: loginRef, ...login } = register("login");
  const { ref: keyRef, ...key } = register("key");

  const [isQontoUser, setIsQontoUser] = useState<boolean>(false);

  let api = useAxios();

  const getSyncorgs = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(
        `/api/SyncOrganization/all`,
        {
          where: {
            companyId: creds.role !== UserTypes.Client ? id : creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSyncorgs(data.data);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting notes", error);
    }
  };

  const getBridgeAcoounts = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/api/accountSync/syncAccountsInfo/${
          creds.role !== UserTypes.Client ? id : creds.company_id
        }`,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setBridgeAccounts(data.data);
      for (let bank of data.data) {
        setBankFallback((prevState) => ({
          ...prevState,
          [bank.bankInfo.id]: null,
        }));
      }
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting notes", error);
    }
  };

  const firstSync = async () => {
    try {
      setFirstLoading(true);
      setErrorMessage(null);
      const { data } = await api.post(
        `/api/accountSync/createRedirectURL`,
        {
          clientId: client?.id,
          syncType: "new",
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.error) {
        setFirstLoading(false);
        setErrorMessage({
          type: "first_sync_error",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }
      window.location = data.url;
      setFirstLoading(false);
      setSyncModal(false);
    } catch (error: any) {
      ErrorLogger("creating redirect url", error);
      setFirstLoading(false);
      setErrorMessage({
        type: "first_sync_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  useEffect(() => {
    checkIfQontoUser();
    getSyncorgs();
    getBridgeAcoounts();
  }, []);

  const addSyncOrg: SubmitHandler<SyncOrgFormValues> = async (
    form: SyncOrgFormValues
  ) => {
    try {
      if (!form.login || !form.name || !form.key) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données ",
        });
        return;
      }
      setAddSyncOrgLoading(true);
      await api.post(
        `/api/SyncOrganization/Create`,
        {
          name: form.name,
          login: form.login,
          key: form.key,
          company_id: creds.role !== UserTypes.Client ? id : creds.company_id,
          bankId: 421,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getSyncorgs();
      reset({
        name: null,
        login: null,
        key: null,
      });
      setAddSyncOrgLoading(false);
    } catch (error: any) {
      ErrorLogger("creating sync org", error);
      setAddSyncOrgLoading(false);
    }
  };

  const deleteSyncOrg = async () => {
    try {
      setLoading(true);
      await api.post(
        `/api/SyncOrganization/Delete`,
        {
          id: singleSyncOrg?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getSyncorgs();
      setDeleteModal(false);
      setSingleSyncOrg(null);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  const multipleSync: SubmitHandler<SyncBankFormValues> = async (
    form: SyncBankFormValues
  ) => {
    try {
      setMultiSyncSuccessMessage(null);
      setErrorMessage(null);

      if (Object.keys(form).length === 1 && !form[Object.keys(form)[0]]) {
        setErrorMessage({
          type: "invalid_sync_form",
          message: "Veuillez choisir au moins un compte",
        });
        return;
      }
      setMultiLoading(true);

      let accounts: {
        item_id: string | number;
        id: string | number | undefined | boolean;
      }[] = [];
      for (let bank of Object.keys(form)) {
        for (let account of form[bank]) {
          const item_id = bridegAccounts
            .find(
              (elt) => elt.bankInfo.id === parseInt(bank.replace("bank-", ""))
            )
            ?.relatedAccounts.find(
              (elt) => String(elt.id) === String(account.value)
            )?.itemId;
          accounts.push({ item_id: item_id!, id: account.value });
        }
      }

      const { data } = await api.post(
        `/api/accountSync/syncTransactions`,
        {
          companyId: creds.role !== UserTypes.Client ? id : creds.company_id,
          accounts,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.error) {
        setMultiLoading(false);
        setErrorMessage({
          type: "multi_sync_error",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }

      syncReset(
        Object.keys(form).reduce((acc, curr) => ({ ...acc, [curr]: null }), {})
      );
      setMultiLoading(false);
      if (data.insert && data.insert === 1) {
        setMultiSyncSuccessMessage({
          type: "multi_sync_success",
          message: <p>Vos comptes sont synchronisés avec succès.</p>,
        });
      }

      // setChoiceModal(false);
      // setSyncModal(false);
    } catch (error: any) {
      ErrorLogger("creating sync org", error);
      setMultiLoading(false);
      setErrorMessage({
        type: "multi_sync_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  const checkIfQontoUser = async () => {
    try {
      const { data: qontoAccounts } = await api.post(
        `/api/BridgeAccount/All`,
        {
          where: {
            bankId: 421,
            companyId: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (qontoAccounts.data.length > 0) {
        setIsQontoUser(true);
      }
    } catch (error: any) {
      ErrorLogger("getting indemnities", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="section-Compte-sync">
          <h3>Resynchronisation du compte</h3>
          <div className="mt-3 mb-5 btn-sync ">
            <Button
              color="secondary"
              onClick={() => {
                setSyncModal(true);
              }}
            >
              Resynchroniser votre compte bancaire
            </Button>
          </div>
          {(isQontoUser || syncOrgs.length > 0) && (
            <>
              <div className="blocCode">
                <Row>
                  {syncOrgs.length > 0 &&
                    syncOrgs.map((elt, index) => (
                      <React.Fragment key={index}>
                        <Col md={3} className="px-md-3">
                          <FormGroup className="form-icon icon-start">
                            <Label for="namecamp">Nom de l'organisation</Label>
                            <Input
                              id="namecamp"
                              name="namecamp"
                              placeholder="Nom de  l'organisation"
                              type="text"
                              className="form-secondary"
                              disabled={true}
                              value={elt.name}
                            />
                            <span className="icon icon-secondary icon-secondary-fill">
                              <img src={juridiqueIcons} alt="icon" />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3} className="px-md-3">
                          <FormGroup className="form-icon icon-start">
                            <Label for="nexc">identifiant</Label>
                            <Input
                              id="nexc"
                              name="nexc"
                              placeholder="identifiant"
                              type="text"
                              className="form-secondary"
                              disabled={true}
                              value={elt.login}
                            />
                            <span className="icon icon-secondary icon-secondary-fill">
                              <img src={juridiqueIcons} alt="icon" />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3} className="px-md-3">
                          <FormGroup className="form-icon icon-start">
                            <Label for="phone">Clé secrete</Label>
                            <Input
                              id="phone"
                              name="phone"
                              placeholder="Clé secrète"
                              type="text"
                              className="form-secondary"
                              disabled={true}
                              value={elt.key}
                            />
                            <span className="icon icon-secondary icon-secondary-fill">
                              <img src={KeyIcon} alt="icon" />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={3} className="px-md-3 delete-sync-org-button">
                          <Button
                            color="danger"
                            outline
                            onClick={() => {
                              setSingleSyncOrg(elt);
                              setDeleteModal(true);
                            }}
                          >
                            <DeletShadow />{" "}
                            <span className="delet-span">Supprimer</span>
                          </Button>
                        </Col>
                      </React.Fragment>
                    ))}
                </Row>
                <form onSubmit={handleSubmit(addSyncOrg)}>
                  <Row>
                    <Col md={4} sm={6} className="pe-md-4">
                      <FormGroup className="form-icon icon-start">
                        <Label for="namecamp">Nom de l'organisation</Label>
                        <Input
                          id="namecamp"
                          innerRef={nameRef}
                          {...name}
                          placeholder="Nom de  l'organisation"
                          type="text"
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                        />
                        <span className="icon icon-secondary icon-secondary-fill">
                          <img src={juridiqueIcons} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="px-md-4">
                      <FormGroup className="form-icon icon-start">
                        <Label for="nexc">identifiant</Label>
                        <Input
                          id="nexc"
                          innerRef={loginRef}
                          {...login}
                          placeholder="identifiant"
                          type="text"
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                        />
                        <span className="icon icon-secondary icon-secondary-fill">
                          <img src={juridiqueIcons} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4} sm={6} className="px-md-4">
                      <FormGroup className="form-icon icon-start">
                        <Label for="phone">Clé secrete</Label>
                        <Input
                          id="phone"
                          innerRef={keyRef}
                          {...key}
                          placeholder="Clé secrète"
                          type="text"
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                        />
                        <span className="icon icon-secondary icon-secondary-fill">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                  <div className="mt-3 bottom-actions">
                    <Button
                      color="secondary"
                      type="submit"
                      className="icon-left"
                    >
                      <span className="icon icon-secondary">
                        <PlusShadow />
                      </span>
                      {!addSyncOrgLoading ? (
                        "Ajouter"
                      ) : (
                        <Spinner color="light" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      )}
                    </Button>
                  </div>
                </form>
              </div>
              <div className="my-4 text-nb">
                <p>
                  <strong>
                    NB : Après avoir été connecté à votre compte Qonto :
                  </strong>
                  <br /> 1 - Choisissez votre organisation
                  <br />2 - Cliquez sur "Paramètres & Équipe"
                  <br />3 - Cliquez sur "Clé API"
                  <br /> 4 - Copiez et collez l'identifiant et la clé secrète
                  dans les entrées correspondantes de votre application Noly.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="openbtn text-center">
        {/* delete sync org */}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteModal}
          toggle={() => {
            setSingleSyncOrg(null);
            setDeleteModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleSyncOrg(null);
              setDeleteModal(false);
            }}
          >
            Supprimer une organisation
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer cette organisation{" "}
                {singleSyncOrg?.name} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              type="button"
              onClick={() => {
                setSingleSyncOrg(null);
                setDeleteModal(false);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              type="submit"
              onClick={async () => await deleteSyncOrg()}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>

        {/* select sync type */}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={syncModal}
          toggle={() => {
            setSyncModal(false);
            setErrorMessage(null);
            setFirstLoading(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSyncModal(false);
              setErrorMessage(null);
              setFirstLoading(false);
            }}
          >
            Confirmer la synchronisation
          </ModalHeader>
          <ModalBody>
            <div className="sync-modal">
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    setFirstLoading(false);
                    setErrorMessage(null);
                    setChoiceModal(true);
                  }}
                >
                  Comptes bancaires déjà synchronisées
                </Button>
              </div>
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="submit"
                  disabled={firstLoading}
                  onClick={async () => {
                    await firstSync();
                  }}
                >
                  Nouveau Compte bancaire
                </Button>
              </div>
            </div>
            {firstLoading && (
              <div className="d-flex justify-content-center text-info mt-2">
                Création d'un lien de synchronisation, veuillez patienter.
              </div>
            )}
            {errorMessage?.type === "first_sync_error" && (
              <div className="d-flex justify-content-center text-danger mt-2">
                {errorMessage?.message}
              </div>
            )}
          </ModalBody>
        </Modal>

        {/* select sync account */}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={choiceModal}
          toggle={() => {
            setChoiceModal(false);
            syncReset();
            setLoading(false);
            setChoiceModal(false);
            setSyncModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setChoiceModal(false);
              syncReset();
              setLoading(false);
              setChoiceModal(false);
              setSyncModal(false);
            }}
          >
            Confirmer la synchronisation
          </ModalHeader>
          <ModalBody>
            <div className="acoounts-choice">
              {bridegAccounts && bridegAccounts.length > 0 ? (
                <>
                  <form
                    onSubmit={syncHandleSubmit(multipleSync)}
                    className="multiple-sync-wrapper"
                  >
                    {bridegAccounts.map((elt, index) => (
                      <Row key={index}>
                        <Col md={12} className="bank-wrapper">
                          {bankFallback[elt.bankInfo.id] ? (
                            <p>{elt.bankInfo.name}</p>
                          ) : (
                            <img
                              src={elt.bankInfo.logo_url}
                              alt={`${elt.bankInfo.logo_url}-logo`}
                              onError={() =>
                                setBankFallback((prevState: any) => ({
                                  ...prevState,
                                  [elt.bankInfo.id]: elt.bankInfo.name,
                                }))
                              }
                            />
                          )}
                          <FormGroup>
                            <Label for={`bank-${elt.bankInfo.id}`}>
                              Sélectionner un ou plusieurs comptes
                            </Label>
                            <Controller
                              name={`bank-${elt.bankInfo.id}`}
                              control={syncControl}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={elt.relatedAccounts.reduce(
                                    (acc, curr) => [
                                      ...acc,
                                      {
                                        label: curr.name as string,
                                        value: String(curr.id),
                                      },
                                    ],
                                    [] as OptionType[]
                                  )}
                                  closeMenuOnSelect={true}
                                  isMulti
                                  classNamePrefix="select"
                                  className="custom-select form-secondary"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                    {errorMessage?.type === "invalid_sync_form" && (
                      <div className="d-flex justify-content-center text-danger mt-2">
                        {errorMessage?.message}
                      </div>
                    )}
                    <div className="mt-3 bottom-actions">
                      <Button
                        color="secondary"
                        type="submit"
                        disabled={multiLoading}
                      >
                        Synchroniser
                      </Button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <img src={EMPTY_CONTENT} alt="empty" />
                  <p className="mt-3">
                    il n'y a pas de comptes bancaires synchronisés
                  </p>
                </>
              )}
            </div>
            {multiLoading && (
              <div className="d-flex justify-content-center text-info mt-2">
                Création d'un lien de synchronisation, veuillez patienter.
              </div>
            )}
            {errorMessage?.type === "multi_sync_error" && (
              <div className="d-flex justify-content-center text-danger mt-2">
                {errorMessage?.message}
              </div>
            )}
            {multiSyncSuccessMessage?.type === "multi_sync_success" && (
              <div className="d-flex justify-content-center text-success mt-2">
                {multiSyncSuccessMessage?.message}
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default CompteSynchronise;
