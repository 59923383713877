import Icon1 from "../assets/images/svg/icon-nav-1.svg";
import Icon2 from "../assets/images/svg/icon-nav-2.svg";
import Icon3 from "../assets/images/svg/icon-nav-3.svg";
import Icon4 from "../assets/images/svg/icon-nav-4.svg";

import BilanPNG from "../assets/images/icons/client/bilan.png";
import DashPNG from "../assets/images/icons/client/dash.png";
import ImmobsPNG from "../assets/images/icons/client/immobs.png";
import IndemPNG from "../assets/images/icons/client/indem.png";
import InvoicePNG from "../assets/images/icons/client/invoice.png";
import MyTrsPNG from "../assets/images/icons/client/my-trs.png";

import { AccessPermissions, AppModules, AppPacks } from "../util/context";

export type SidebarNav = {
  link: string;
  section: string;
  icon: string;
  text: string;
  whiteList?: string;
  blackList?: string[];
};

const backOfficeSidebarNav: SidebarNav[] = [
  {
    link: "/",
    section: "mes-clients",
    icon: Icon1,
    text: "Mes Clients",
  },
  {
    link: "/gestion-admin",
    section: "gestion-admin",
    icon: Icon2,
    text: "Gestion admin",
    whiteList: AccessPermissions.Admin,
  },
  {
    link: "/gestion-comptable",
    section: "gestion-comptable",
    icon: Icon3,
    text: "Gestion Comptable",
    whiteList: AccessPermissions.Accounting,
  },
  // {
  //   link: "/gestion-prospect",
  //   section: "gestion-prospect",
  //   icon: Icon4,
  //   text: "Gestion prospect",
  //   whiteList: AccessPermissions.Proscpects,
  // },
];

const clientSidebarNav: SidebarNav[] = [
  {
    link: "/",
    section: "dashboard",
    icon: DashPNG,
    text: "Tableau de bord",
  },
  {
    link: "/transactions",
    section: "transactions",
    icon: MyTrsPNG,
    text: "Mes transactions",
  },

  {
    link: "/facturation",
    section: "facturation",
    icon: InvoicePNG,
    text: "Facturation",
  },
  {
    link: "/immobilisation",
    section: "immobilisation",
    icon: ImmobsPNG,
    text: "Immobilisation",
    blackList: [AppPacks.Entrepreneur],
  },
  {
    link: "/indemnite",
    section: "indemnite",
    icon: IndemPNG,
    text: "Indemnité Kilométrique",
    blackList: [AppPacks.Entrepreneur, AppPacks.BICPlus, AppPacks.BICPlusTVA],
  },
  {
    link: "/bilan",
    section: "bilan",
    icon: BilanPNG,
    text: "Bilan",
    blackList: [AppPacks.Entrepreneur],
  },
  // {
  //   link: "/tickets",
  //   section: "tickets",
  //   icon: "bx bx-cog",
  //   text: "Tickets",
  // },
];

export { clientSidebarNav, backOfficeSidebarNav };
