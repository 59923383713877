import React, { ReactNode, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Company } from "../../interfaces";
import moment from "moment";
import "moment/locale/fr";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import { Button, Card, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import { ErrorLogger } from "../../util/errorLogger";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

type FormValues = {
  dateFrom: string | null;
  dateTo: string | null;
};

const Export = ({ company }: { company: Company | undefined }) => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState<Array<{ url: string; key: string }>>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: {},
  } = useForm<FormValues>({});

  useEffect(() => {
    reset({
      dateFrom: null,
      dateTo: null,
    });
  }, []);
  let api = useAxios();

  const fetchFile: SubmitHandler<FormValues> = async (form: FormValues) => {
    try {
      if (!form.dateFrom || !form.dateTo) {
        setErrorMessage({
          type: "fill-form",
          message: "Veuillez remplir tous les champs du formulaire",
        });
        return;
      }
      setLoading(true);
      setErrorMessage(null);

      const { data } = await api.post(
        `/api/transaction/exportODTVA`,
        {
          dateFrom: moment(form.dateFrom).format("YYYY-MM-DD hh:mm"),
          dateTo: moment(form.dateTo).format("YYYY-MM-DD hh:mm"),
          company: company,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (!data.response) {
        setLoading(false);
        setErrorMessage({
          type: "export-issue",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }
      if (data.response.notProcessed.length > 0) {
        setErrorMessage({
          type: "export-issue",
          message: (
            <Row>
              <Col md={12}>
                Un problème d'export s'est produit, veuillez réessayer plus
                tard.
              </Col>
            </Row>
          ),
        });
      }
      if (data.response.processed.length > 0) {
        setLinks(data.response.processed);
      }
      reset({
        dateFrom: null,
        dateTo: null,
      });
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("exporting dotva", error);
      setLoading(false);
      setErrorMessage({
        type: "export-issue",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  return (
    <div className="page page-reporting">
      <Card className="card-reporting">
        <form onSubmit={handleSubmit(fetchFile)}>
          <Row className="align-items-end">
            <Col md={8}>
              <div className="filterInner">
                <Row>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="exampleEmail">Date de début</Label>
                      <Controller
                        control={control}
                        name="dateFrom"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => {
                              field.onChange(date);
                              setErrorMessage(null);
                            }}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        )}
                      />

                      <span className="icon icon-secondary icon-secondary-fill">
                        {/* <img src={CalenderIconBlue} alt="icon" /> */}
                         {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="exampleEmail">Date de fin</Label>
                      <Controller
                        control={control}
                        name="dateTo"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => {
                              field.onChange(date);
                              setErrorMessage(null);
                            }}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        )}
                      />

                      <span className="icon icon-secondary icon-secondary-fill">
                        {/* <img src={CalenderIconBlue} alt="icon" /> */}
                         {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit" disabled={loading}>
                  Exporter
                </Button>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    reset({
                      dateFrom: null,
                      dateTo: null,
                    });

                    setLinks([]);
                    setErrorMessage(null);
                  }}
                  disabled={loading}
                >
                  Réinitialiser
                </Button>
              </div>
            </Col>
          </Row>
        </form>
        <Row>
          <Col md={8}>
            {loading && (
              <div className="d-flex align-items-center">
                <Spinner color="info" type="border" size={"sm"}>
                  Loading...
                </Spinner>

                <p className="m-2">Création des fichiers</p>
              </div>
            )}
            {!loading && errorMessage?.type === "export-issue" && (
              <div className="d-flex align-items-center text-danger">
                {errorMessage?.message}
              </div>
            )}

            {!loading && errorMessage?.type === "fill-form" && (
              <div className="d-flex align-items-center text-danger">
                {errorMessage?.message}
              </div>
            )}

            {!loading && links.length > 0 && (
              <>
                {links.map((obj: any, index) => (
                  <div className="d-flex align-items-center" key={index}>
                    Cliquez pour télécharger le reporting :{" "}
                    <a
                      href={obj.urls.url}
                      target={"_blank"}
                      className="file-link"
                    >
                      {obj.urls.key}
                    </a>
                  </div>
                ))}
              </>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Export;
