import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../../config";
import { Company } from "../../../interfaces";
import useAxios from "../../../util/hooks/useAxios";
import { createAxiosInsatance } from "../../../util/helpers/createAxiosInsatance";
import { setCounter } from "../utils/utilSlice";
const { API_URL } = config[process.env.NODE_ENV];

const initialState = {
  clientsList: [] as Array<Company>,
  loading: "idle",
  currentRequestId: undefined as any,
  error: null as any,
};



export const fetchAllClients = createAsyncThunk(
  "applications/fetchAllClients",
  async (id: string, { getState, requestId, dispatch }) => {
    const thunkState = getState() as any;
    let api = createAxiosInsatance(thunkState.root.token, dispatch, setCounter);
    const { data } = await api!.post(
      `/api/Company/All`,
      {
        where: {
          cabinets: {
            id,
          },
        },
      },
      {
        headers: {
          "x-access-token": thunkState.root.token,
        },
      }
    );
    return data.data;
  }
);

export const clientsListSlice = createSlice({
  name: "clientsList",
  initialState,
  reducers: {
    setClientsList: (state: any, action: any) => {
      state.clientsList = action.payload;
    },

    setCompanUserStatus_List: (state: any, action: any) => {
      const stateCompanies = JSON.parse(JSON.stringify(state.clientsList));

      const updatedState = stateCompanies.map((obj: Company) => {
        if (obj.id === action.payload.id) {
          return { ...obj, userStatus: action.payload.userStatus };
        }

        return obj;
      });

      state.clientsList = updatedState;
    },

    deleteCompany_List: (state: any, action: any) => {
      const stateCompanies = JSON.parse(JSON.stringify(state.clientsList));

      const updatedState = stateCompanies.filter(
        (company: Company) => company.id !== action.payload.id
      );

      state.clientsList = updatedState;
    },
    resetClientsList: (state: any) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClients.pending, (state: any, action: any) => {
        if (state.loading === "idle") {
          state.loading = "pending";
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(fetchAllClients.fulfilled, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.clientsList = action.payload;
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchAllClients.rejected, (state: any, action: any) => {
        const { requestId } = action.meta;
        if (
          state.loading === "pending" &&
          state.currentRequestId === requestId
        ) {
          state.loading = "idle";
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });
  },
});

export const {
  setCompanUserStatus_List,
  setClientsList,
  deleteCompany_List,
  resetClientsList,
} = clientsListSlice.actions;
export default clientsListSlice.reducer;
