import React, { useState, FormEvent } from "react";

import "./GetPassword.scss";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import imgLogin from "../../../assets/images/img-auth.png";
import loginImg from "../../../assets/images/logo.svg";
import sendImg from "../../../assets/images/svg/send.svg";
import { Link, useNavigate } from "react-router-dom";
import Tabs from "../../../components/Tabs/Tabs";
import { tabs } from "../index";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import config from "../../../config";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import { images } from "../../../constants";

type FormValues = {
  email: string;
};

const sendSchema = yup.object().shape({
  email: yup.string().email().required("L'email est un champ obligatoire"),
});

const { API_URL } = config[process.env.NODE_ENV];

const GetPassword: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(sendSchema),
  });

  let navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  let api = useAxios();

  const onSubmit: SubmitHandler<FormValues> = async (payload) => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const { data } = await api.post(`/api/auth/forgotPassword`, payload);

      if (data.message.toLowerCase() === "ok") {
        setLoading(false);
        setSuccessMessage("Email envoyé, vérifiez votre boîte de réception ");
      }

      if (!data.message) {
        setLoading(false);
        setErrorMessage(
          "Un problème est survenu, veuillez réessayer plus tard."
        );
      }

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error: any) {
      setLoading(false);
      if (
        error.response.data.err &&
        typeof error.response.data.err === "string" &&
        error.response.data.err
          .toLowerCase()
          .includes("no user found with that email address.")
      ) {
        setErrorMessage("Pas d'utilisateur trouvé");
      } else {
        setErrorMessage(
          "Un problème est survenu, veuillez réessayer plus tard."
        );
      }
      ErrorLogger("password recovery email send", error);
    }
  };

  const { ref: emailRef, ...email } = register("email");

  return (
    <div className="main-auth">
      <div className="container-auth">
        <Row className="row-auth">
          <Col md={4} className="leftLogin">
            <div className="innerLeftLogin">
              <div className="img-login">
                <img src={imgLogin} alt="img" />
              </div>
            </div>
          </Col>

          <Col md={8} className="righttLogin">
            <div className="innerrightLogin">
              <div className="main-signin">
                <div className="top-Login">
                  <div className="login-top">
                    <img src={images.logo} />
                  </div>
                  <h1>Récupérer mon mot de passe</h1>
                  <p>
                    Entrez votre email et votre mot de passe pour accéder à
                    votre compte Noly
                  </p>
                </div>
                <form
                  className="connexion-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <FormGroup className="form-icon icon-start">
                    <Label for="email">E-mail</Label>
                    <Input
                      id="email"
                      placeholder="E-mail"
                      type="text"
                      className="form-secondary"
                      {...email}
                      onChange={() => {
                        setErrorMessage(null);
                      }}
                      innerRef={emailRef}
                    />
                    <span className="icon icon-secondary icon-secondary-fill">
                      <img src={sendImg} alt="icon" />
                    </span>
                  </FormGroup>
                  {errors.email && (
                    <Alert color="danger">{errors.email?.message}</Alert>
                  )}
                  {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                  {successMessage && (
                    <Alert color="info">{successMessage}</Alert>
                  )}
                  <div className="bottom-action">
                    <button className="btn btn-secondary" type="submit">
                      {loading ? (
                        <Spinner color="light" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      ) : (
                        "Envoyer"
                      )}
                    </button>

                    <div className="bottom-link">
                      <Link className="forgot-pwd" to="/login">
                        Retour
                      </Link>
                    </div>
                  </div>
                </form>
              </div>

              {/* <Tabs orientation="vertical" tabs={tabs} /> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GetPassword;
