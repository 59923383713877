export type OptionType = {
  value: string | number | boolean | undefined;
  label: string | undefined;
};

export enum AppModules {
  Accounts = "accounts",
  Transactions = "transactions",
  Admin = "admin",
  Accounting = "accounting",
  Proscpects = "prospects",
}

export enum UserTypes {
  Admin = "Administrateur",
  Cabinet = "Cabinet",
  Client = "Client",
  SuperAdmin = "SuperAdmin",
  Support = "Support",
}

export enum ODTVATypes {
  Full = "full",
  Update = "update",
}

export enum ClientTypes {
  B2B = "b2b",
  B2C = "b2c",
}

export enum PayDiffKeys {
  OUT101 = "OUT-101",
  OUT100 = "OUT-100",
}

export enum CarbKeys {
  OUT028 = "OUT-028",
}

export enum CarbVehTypes {
  Utility = "utility",
  Tourisme = "tourisme",
}

export enum CategoriesTypes {
  OUTPUT = "Décaissement",
  INPUT = "Encaissement",
}

export enum TransactionMediums {
  Manual = "manual",
  BankStatement = "bank-statement",
  API = "api",
}

export enum Registerkeys {
  NOLY_REG_ActivityType = "ActivityType",
  NOLY_REG_STEP_VALUE = "STEP",
  NOLY_REG_commercialQ1 = "commercialQ1",
  NOLY_REG_commercialQ2 = "commercialQ2",
  NOLY_REG_commercialQ3 = "commercialQ3",
  NOLY_REG_commercialQ4 = "commercialQ4",
  NOLY_REG_commercialQ5 = "commercialQ5",
  NOLY_REG_associationQ1 = "associationQ1",
  NOLY_REG_associationQ2 = "associationQ2",
  NOLY_REG_associationQ3 = "associationQ3",
  NOLY_REG_associationQ4 = "associationQ4",
  NOLY_REG_eirlQ1 = "eirlQ1",
  NOLY_REG_civilSocietyQ1 = "civilSocietyQ1",
  NOLY_REG_civilSocietyQ2 = "civilSocietyQ2",
  NOLY_REG_civilSocietyQ3 = "civilSocietyQ3",
  NOLY_REG_civilSocietyQ4 = "civilSocietyQ4",
  NOLY_REG_civilSocietyQ5 = "civilSocietyQ5",
  NOLY_REG_worksCouncilQ1 = "worksCouncilQ1",
  NOLY_REG_marchendiseQ1 = "marchendiseQ1",
  NOLY_REG_marchendiseQ2 = "marchendiseQ2",
  NOLY_REG_prestationQ1 = "prestationQ1",
  NOLY_REG_prestationQ2 = "prestationQ2",
  NOLY_REG_autresPrestationsQ1 = "autresPrestationsQ1",
  NOLY_REG_autresPrestationsQ2 = "autresPrestationsQ2",
  NOLY_REG_professionQ1 = "professionQ1",
  NOLY_REG_professionQ2 = "professionQ2",
  NOLY_REG_locationQ1 = "locationQ1",
  NOLY_REG_locationQ2 = "locationQ2",
  NOLY_REG_declaration = "declaration",
  NOLY_REG_dischargeTax = "dischargeTax",
}

export enum GedDocTypes {
  Client = "client",
  Company = "company",
  Bilan = "bilan",
}

export enum AppPacks {
  BIC = "pack-BIC",
  BICPlus = "pack-BIC+",
  BICPlusTVA = "pack-BIC+TVA",
  Entrepreneur = "pack-Entrepreneur",
  BNCAssoc = "pack-BNC/ASSOC",
  BNCAssocPlus = "pack-BNC/ASSOC+",
}

export enum BankSynced {
  YES = "102",
  NO = "100",
}

export enum AccessPermissions {
  Admin = "Admin",
  Accounting = "Accounting",
  Proscpects = "Proscpects",
}

export enum CrudPermissions {
  DeleteAccounts = "DeleteAccounts",
  DeleteTransactions = "DeleteTransactions",
}

export type CustomOptionType = Omit<OptionType, "value"> & {
  value: string | number | boolean | undefined | number[];
};

export const COMPANY_STATUT_OPTIONS: OptionType[] = [
  { label: "Leads", value: "Leads" },
  { label: "En cours", value: "En cours" },
  { label: "En test", value: "En test" },
  { label: "Client", value: "Client" },
  { label: "NA", value: "NA" },
  { label: "Annulation", value: "Annulation" },
  { label: "Résiliation", value: "Résiliation" },
];

export const ASSETS_OPTIONS: OptionType[] = [
  {
    label: "Immobilisations incorporelles – site internet (1 à 5 ans)",
    value: "site internet (1 à 5 ans)",
  },
  {
    label:
      "Immobilisations incorporelles – brevets, licences, concessions (5 ans)",
    value: "brevets, licences, concessions (5 ans)",
  },
  {
    label: "Immobilisations corporelles – travaux & agencements (3 à 15 ans)",
    value: "travaux & agencements (3 à 15 ans)",
  },
  {
    label:
      "Immobilisations corporelles – matériel de bureau & informatique (1 à 10 ans)",
    value: "matériel de bureau & informatique (1 à 10 ans)",
  },
  {
    label: "Immobilisations corporelles – matériel de transport (4 à 5 ans)",
    value: "matériel de transport (4 à 5 ans)",
  },
  {
    label: "Immobilisations corporelles – mobilier (5 à 10 ans)",
    value: "mobilier (5 à 10 ans)",
  },
];

export const ASSETS_OPTIONS_VALIDATORS: CustomOptionType[] = [
  {
    label: "site internet (1 à 5 ans)",
    value: [1, 5],
  },
  {
    label: "brevets, licences, concessions (5 ans)",
    value: 5,
  },
  {
    label: "travaux & agencements (3 à 15 ans)",
    value: [3, 15],
  },
  {
    label: "matériel de bureau & informatique (1 à 10 ans)",
    value: [1, 10],
  },
  {
    label: "matériel de transport (4 à 5 ans)",
    value: [4, 5],
  },
  {
    label: "mobilier (5 à 10 ans)",
    value: [5, 10],
  },
];

export const BILL_OPTIONS: OptionType[] = [
  { label: "Payée", value: 100 },
  { label: "En attente", value: 102 },
  { label: "Non payée", value: 101 },
];

export const EXERCICE_OPTIONS: OptionType[] = [
  { label: "Oui", value: true },
  { label: "Non", value: false },
];

export const QUOTE_OPTIONS: OptionType[] = [
  { label: "Accepté", value: 103 },
  { label: "Non accepté", value: 104 },
  { label: "En attente", value: 102 },
];

export const CREDIT_NOTE_OPTIONS: OptionType[] = [
  { label: "Remboursé", value: 105 },
  { label: "En attente", value: 102 },
  { label: "Non remboursé", value: 106 },
];

export const ODTVA_OPTIONS: OptionType[] = [
  // { label: "Hidden", value: 100 },
  { label: "En attente", value: 101 },
  { label: "Déclaré", value: 102 },
  { label: "Reporté", value: 103 },
];

export const VAT_REGIME_OPTIONS: OptionType[] = [
  { label: "Mensuel", value: "Monthly" },
  { label: "Trimestriel", value: "Quarterly" },
  { label: "Semestriel", value: "SemiAnnually" },
  { label: "Annuel", value: "Annually" },
  { label: "Non  Assujetti", value: "Non" },
];

export const VAT_REGIME_OPTIONS_OBJECT = {
  Monthly: "Mensuel",
  Quarterly: "Trimestriel",
  SemiAnnually: "Semestriel",
  Annually: "Annuel",
};

export const GENDER_OPTIONS: OptionType[] = [
  { label: "Madame", value: "mrs" },
  { label: "Monsieur", value: "mr" },
];

export const VEHICULE_TYPES: OptionType[] = [
  { label: "Voiture", value: "car" },
  { label: "Motocyclette", value: "motorcycle" },
  { label: "Cyclomoteurs", value: "moped" },
];

export const HORSE_POWERS: OptionType[] = [
  { label: "3 CV et moins", value: 3 },
  { label: "4 CV", value: 4 },
  { label: "5 CV", value: 5 },
  { label: "6 CV", value: 6 },
  { label: "7 CV et plus", value: 7 },
];

export const ACQUISITION_CHANNEL_CODES: OptionType[] = [
  { label: "Réseaux sociaux et communauté", value: "social" },
  { label: "Publicité", value: "pub" },
  { label: "Emailing", value: "emails" },
  { label: "Referral", value: "referral" },
  { label: "Direct", value: "direct" },
];

export const FACTURE_STATUS_CODES = {
  100: "Payée",
  101: "Non payée",
  102: "En attente",
  103: "Accepté",
  104: "Non accepté",
  105: "Remboursé",
  106: "Non remboursé",
  999: "Deleted",
};

export const TICKET_STATUS_CODES = {
  100: "Résolue",
  107: "Non résolue",
  102: "En attente",
  106: "Nouveau",
  999: "Deleted",
};

export const TICKET_OPTIONS: OptionType[] = [
  { label: "Résolue", value: 100 },
  // { label: "Non résolue", value: 107 },
  // { label: "En attente", value: 102 },
  { label: "Nouvelle", value: 106 },
];

export const PLAN_OPTIONS: OptionType[] = [
  { label: "BIC", value: "pack-BIC" },
  { label: "BIC +", value: "pack-BIC+" },
  { label: "BIC + ( TVA incluse )", value: "pack-BIC+TVA" },
  { label: "Entrepreneur", value: "pack-Entrepreneur" },
  { label: "BNC/ASSOC +", value: "pack-BNC/ASSOC+" },
  { label: "BNC/ASSOC", value: "pack-BNC/ASSOC" },
];

export const JURID_FORMES: OptionType[] = [
  // { label: "Entrepreneur Individuel(EI)", value: "EI" },
  // {
  //   label: "Entreprise Unipersonnelle à Responsabilité Limitée (EURL)",
  //   value: "EURL",
  // },
  // { label: "Entreprise à Responsabilité Limitée (SARL)", value: "SARL" },
  // { label: "Entreprise en Nom Collectif (SNC)", value: "SNC" },
  // { label: "Entreprise par Actions Simplifiées (SAS)", value: "SAS" },
  // {
  //   label: "Entreprise par Actions Simplifiées Unipersonnelle (SAS)",
  //   value: "SASU",
  // },
  // { label: "Entreprise civile immobilière", value: "SCI" },
  // { label: "Auto Entrepreneur", value: "AE" },
  { label: "EI", value: "EI" },
  {
    label: "EURL",
    value: "EURL",
  },
  { label: "SARL", value: "SARL" },
  { label: "SELARL", value: "SELARL" },
  { label: "SELAS", value: "SELAS" },
  { label: "SELASU", value: "SELASU" },
  { label: "SELEURL", value: "SELEURL" },
  { label: "SAS", value: "SAS" },
  {
    label: "SASU",
    value: "SASU",
  },
  { label: "SCI", value: "SCI" },
  { label: "AE", value: "AE" },
];

export const LEADS_STATUS_CODES: OptionType[] = [
  { label: "Lead", value: 100 },
  { label: "Aucune réponse", value: 106 },
  { label: "Non intéressé", value: 101 },
  { label: "Client", value: 102 },
  { label: "Hors cible", value: 103 },
  { label: "Rappel", value: 104 },
  { label: "Intéressé", value: 105 },
];
