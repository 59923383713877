import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import { OutletComp } from "..";
import RoutingTabs from "../../../components/RoutingTabs";
import { Company, User } from "../../../interfaces";
import { UserTypes } from "../../../util/context";
import { ErrorLogger } from "../../../util/errorLogger";
import useAxios from "../../../util/hooks/useAxios";
import CompanyInfo from "./CompanyInfoWrapper";
import ExerciceCompanyWrapper from "./ExerciceCompanyWrapper";
import Ged from "./GedCompanyWrapper";
import Iban from "./IbanCompanyWrapper";
import SyncCompanyWrapper from "./SyncCompanyWrapper";

const tabs = [
  {
    label: "Informations société",
    index: 1,
    Component: CompanyInfo,
    link: "/profil/entreprise",
  },
  {
    label: "Gestion des Documents",
    index: 2,
    Component: Ged,
    link: "/profil/entreprise/ged",
  },
  {
    label: "Iban Télépaiement",
    index: 3,
    Component: Iban,
    link: "/profil/entreprise/iban",
  },
  {
    label: "Exercice Précédent",
    index: 4,
    Component: ExerciceCompanyWrapper,
    link: "/profil/entreprise/exercise",
  },
  {
    label: "Compte Synchronisé",
    index: 5,
    Component: SyncCompanyWrapper,
    link: "/profil/entreprise/sync",
  },
];

function CompanyWrapper() {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  let api = useAxios();

  const [reload, setReload] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [currentCompany, setCurrentCompany] = useState<Company | null>();

  const getUser = async () => {
    try {
      const { data } = await api.post(
        `/api/User/All`,
        {
          where: {
            id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      const { data: userData } = data;
      const [userFound] = userData;
      const { company, ...user } = userFound;
      setCurrentCompany(company);
      setCurrentUser(user);
    } catch (error: any) {
      ErrorLogger("state rehydration in App", error);
    }
  };

  useEffect(() => {
    getUser();
    // if (user.role === UserTypes.Client) {
    //   setCurrentCompany(userCompany);
    // }
  }, [creds.token]);

  useEffect(() => {
    if (reload) {
      getUser();
      setReload(false);
    }
  }, [reload]);

  return (
    <div className="page">
      <div>
        <Card className="card-Table table-primary">
          <RoutingTabs tabs={tabs} type="child" />
          <OutletComp
            context={{
              context: creds.company_id,
              company: currentCompany!,
              client: currentUser!,
              user: currentUser!,
              callback: getUser,
            }}
          />
        </Card>
      </div>
    </div>
  );
}

export default CompanyWrapper;
