import React from "react";
import "./FactureHtml.scss";
import LogoNoly from "../../../assets/images/logo.svg";
import { FormGroup } from "reactstrap";
import { IAvoir, IDevis, IFacture, IModel, User } from "../../../interfaces";
import moment, { Moment } from "moment";
import { ClientTypes, OptionType } from "../../../util/context";
import { colors } from "../../../constants";
import CerleUserBg from "../../../assets/CerleUserBg";
moment.updateLocale("fr", {});

export interface FactHtmlProps {}

const FactureHtml = ({
  invoice,
  type,
  client,
}: {
  invoice: IFacture | IDevis | IAvoir | IModel;
  type: string;
  client?: User;
}) => {
  return (
    <>
      <div className="facture-Wrrap">
        <div className="Header-fact-view">
          <div className="left-head-fact">
            <div className="titleNUm-fact">
              <span className="titlenum-fact">
                {type === "facture"
                  ? "Facture"
                  : type === "devis"
                  ? "Devis"
                  : type === "avoir"
                  ? "Avoir"
                  : "Model"}{" "}
                n° :
              </span>
              <span className="num-fact">{invoice?.id.toUpperCase()}</span>
              <span className="date-fact">
                Date: {moment(invoice?.date).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="right-head-fact">
            <div className="invoice-logo user-info mb-4">
              {type === "facture" ? (
                <>
                  {(invoice as IFacture)?.billInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IFacture)?.billInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              ) : type === "devis" ? (
                <>
                  {(invoice as IDevis)?.devisInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IDevis)?.devisInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              ) : type === "avoir" ? (
                <>
                  {(invoice as IAvoir)?.avoirInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IAvoir)?.avoirInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {(invoice as IModel)?.modelInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IModel)?.modelInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="Header-fact-infos">
          <div className="right-infos-fact">
            <div className="box-fact-infos box-primary">
              <h3>{invoice?.company.name}</h3>
              <div className="ligne-infos">
                <span className="label-title">Adresse :</span>
                <span className="label-content">
                  {invoice?.company.headOffice}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Teléphone :</span>
                <span className="label-content">
                  {" "}
                  {client?.phone || invoice?.company.phone}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Siren :</span>
                <span className="label-content"> {invoice?.company.siren}</span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Numéro TVA :</span>
                <span className="label-content">
                  {" "}
                  {invoice?.company.intraCommunityVATNumber}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Capital social :</span>
                <span className="label-content">
                  {" "}
                  {invoice?.company.shareCapital}
                </span>
              </div>
            </div>
          </div>
          <div className="left-infos-fact">
            <div className="box-fact-infos ">
              <h3>{invoice?.clientInfo.clientName || invoice?.clientInfo.clientCompany}</h3>
              <div className="ligne-infos">
                <span className="label-title">Adresse :</span>
                <span className="label-content">
                  {invoice?.clientInfo.clientAdress}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Teléphone :</span>
                <span className="label-content">
                  {invoice?.clientInfo.clientTel}
                </span>
              </div>
              {invoice?.clientType &&
              invoice?.clientType === ClientTypes.B2B ? (
                <>
                  <div className="ligne-infos">
                    <span className="label-title">Siren :</span>
                    <span className="label-content">
                      {invoice?.clientInfo.clientSiren}
                    </span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Numéro TVA :</span>
                    <span className="label-content">
                      {invoice?.clientInfo.clientTVA}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="infos-additionnels">
          <div className="text-added">
            <span className="label-text-added">
              Informations additionnelles :
            </span>
            <br />
            <span>
              {type === "facture" ? (
                <>{(invoice as IFacture)?.billInfo.additionalInfo}</>
              ) : type === "devis" ? (
                <>{(invoice as IDevis)?.devisInfo.additionalInfo}</>
              ) : type === "avoir" ? (
                <>{(invoice as IAvoir)?.avoirInfo.additionalInfo}</>
              ) : (
                <>{(invoice as IModel)?.modelInfo.additionalInfo}</>
              )}
            </span>
          </div>
        </div>

        <div className="table-fact table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Catégorie</th>
                <th>Description</th>
                <th>Prix unit.HT</th>
                <th>Quantité</th>
                <th>%TVA</th>
                <th>Tot.TVA</th>
                <th>Tot.HT</th>
                <th>Tot.TTC</th>
              </tr>
            </thead>
            <tbody>
              {type === "facture" ? (
                <>
                  {(invoice as IFacture)?.billItems &&
                    (invoice as IFacture)?.billItems.length > 0 &&
                    (invoice as IFacture)?.billItems.map((elt, key) => (
                      <tr key={key}>
                        <td>
                          {(elt.category as unknown as OptionType).label
                            ? (elt.category as unknown as OptionType).label
                            : elt.category}{" "}
                        </td>
                        <td>{elt.description}</td>
                        <td>
                          {elt.priceUnitHT
                            ? elt.priceUnitHT
                            : (elt as any).unitPrice}{" "}
                          €
                        </td>
                        <td>{elt.qty ? elt.qty : (elt as any).quantity}</td>
                        <td>{elt.TVA ? elt.TVA : (elt as any).tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              ) : type === "devis" ? (
                <>
                  {(invoice as IDevis)?.devisItems &&
                    (invoice as IDevis)?.devisItems.length > 0 &&
                    (invoice as IDevis)?.devisItems.map((elt, key) => (
                      <tr key={key}>
                        <td>
                          {(elt.category as unknown as OptionType).label
                            ? (elt.category as unknown as OptionType).label
                            : elt.category}{" "}
                        </td>
                        <td>{elt.description}</td>
                        <td>
                          {elt.priceUnitHT
                            ? elt.priceUnitHT
                            : (elt as any).unitPrice}{" "}
                          €
                        </td>
                        <td>{elt.qty ? elt.qty : (elt as any).quantity}</td>
                        <td>{elt.TVA ? elt.TVA : (elt as any).tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              ) : type === "avoir" ? (
                <>
                  {(invoice as IAvoir)?.avoirItems &&
                    (invoice as IAvoir)?.avoirItems.length > 0 &&
                    (invoice as IAvoir)?.avoirItems.map((elt, key) => (
                      <tr key={key}>
                        <td>
                          {(elt.category as unknown as OptionType).label
                            ? (elt.category as unknown as OptionType).label
                            : elt.category}{" "}
                        </td>
                        <td>{elt.description}</td>
                        <td>
                          {elt.priceUnitHT
                            ? elt.priceUnitHT
                            : (elt as any).unitPrice}{" "}
                          €
                        </td>
                        <td>{elt.qty ? elt.qty : (elt as any).quantity}</td>
                        <td>{elt.TVA ? elt.TVA : (elt as any).tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {(invoice as IModel)?.modelItems &&
                    (invoice as IModel)?.modelItems.length > 0 &&
                    (invoice as IModel)?.modelItems.map((elt, key) => (
                      <tr key={key}>
                        <td>
                          {(elt.category as unknown as OptionType).label
                            ? (elt.category as unknown as OptionType).label
                            : elt.category}{" "}
                        </td>
                        <td>{elt.description}</td>
                        <td>
                          {elt.priceUnitHT
                            ? elt.priceUnitHT
                            : (elt as any).unitPrice}{" "}
                          €
                        </td>
                        <td>{elt.qty ? elt.qty : (elt as any).quantity}</td>
                        <td>{elt.TVA ? elt.TVA : (elt as any).tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="resultat-table">
          <div className="inner-total">
            <div className="item-totat">
              <span className="label-total">Total HT : </span>
              <span className="num-total">{invoice?.totalHT} €</span>
            </div>
            <div className="item-totat">
              <span className="label-total">Total TVA : </span>
              <span className="num-total">{invoice?.totalTVA} €</span>
            </div>
            <div className="item-totat total-ttc">
              <span className="label-total">Total TTC :</span>
              <span className="num-total">{invoice?.totalTTC} €</span>
            </div>
          </div>
        </div>
        <div className="footer-fact">
          <span>Noly Compta</span>
        </div>
      </div>
    </>
  );
};

export default FactureHtml;
