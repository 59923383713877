import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
// import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {
  LoadError,
  RenderPage,
  RenderPageProps,
  Viewer,
} from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import DocViewer, {
  BMPRenderer,
  HTMLRenderer,
  IHeaderOverride,
  ImageProxyRenderer,
  JPGRenderer,
  MSDocRenderer,
  MSGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
} from "@cyntler/react-doc-viewer";
import { RiDownload2Fill } from "react-icons/ri";
import DownloadLink from "react-download-link";
import useAxios from "../../util/hooks/useAxios";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const renderPage: RenderPage = (props: RenderPageProps) => (
  <>
    {props.canvasLayer.children}
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    >
      <div
        style={{
          color: "rgba(90, 199, 176, 0.5)",
          fontSize: `${5 * props.scale}rem`,
          fontWeight: "bold",
          textTransform: "uppercase",
          transform: "rotate(-45deg)",
          userSelect: "none",
        }}
      >
        NOLY COMPTA
      </div>
    </div>
    {props.annotationLayer.children}
    {props.textLayer.children}
  </>
);

const renderError = (error: LoadError) => {
  let message = "";
  switch (error.name) {
    case "InvalidPDFException":
      message = "Le document n'est pas valide ou est corrompu";
      break;
    case "MissingPDFException":
      message = "Le document est manquant";
      break;
    case "UnexpectedResponseException":
      message = "Impossible de charger le document";
      break;
    default:
      message = "Impossible de charger le document";
      break;
  }

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "#e53e3e",
          borderRadius: "0.25rem",
          color: "#fff",
          padding: "0.5rem",
        }}
      >
        {message}
      </div>
    </div>
  );
};

const CustomHeader: IHeaderOverride = (state) => {
  let api = useAxios();
  const getDataFromURL = async (url: string) => {
    const { data } = await api.get(url, {
      responseType: "blob",
    });
    return data;
  };

  return (
    <>
      {state.documents && state.documents.length > 0 && (
        <div className="image-downloader">
          <RiDownload2Fill />
          <DownloadLink
            label="Télécharger"
            filename={(state.documents[0].uri || "").substring(
              (state.documents[0].uri || "").lastIndexOf("/") + 1
            ).replace("?origin=" + window.location.host,"")}
            exportFile={async () =>
              await getDataFromURL(state.documents[0].uri)
            }
          />
        </div>
      )}
    </>
  );
};

const Doc = ({
  url,
  hasWaterMark,
  fileType,
}: {
  url: string | null;
  hasWaterMark?: boolean;
  fileType?: string;
}) => {
  const url2blob = async (url: string) => {
    try {
      const data = await fetch(url);
      const blob = await data.blob();

      setDispalyedURlL({
        url,
        type: blob.type,
        location: "local",
      });
    } catch (err: any) {
      console.error(err);
    }
  };

  const [displayedURL, setDispalyedURlL] = useState<{
    type: string;
    url: string | null;
    location: string;
  } | null>();

  useEffect(() => {
    if (String(url) === "null") {
      setDispalyedURlL(null)
    }
    if (url && url?.includes("blob:")) {
      url2blob(url);
    } 
    if (url && !url?.includes("blob:")) {
      setDispalyedURlL({
        url: url + "?origin=" + window.location.host,
        type: url?.split(".").pop()!,
        location: "remote",
      });
    }
  }, [url]);

  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file) => {
        return `${String(url).toUpperCase()}`;
      },
    },
    searchPlugin: {
      keyword: "PDF",
    },
  });
  const { Toolbar } = toolbarPluginInstance;

  console.log(displayedURL)
  return (
    <>
      {displayedURL && (
        <>
          {displayedURL?.location === "local" ? (
            <>
              {displayedURL.type && (
                <>
                  {displayedURL?.type.toLowerCase().includes("pdf") ? (
                    <>
                      <Toolbar />
                      <Viewer
                        fileUrl={displayedURL.url!}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                      />
                    </>
                  ) : (
                    <>
                      <DocViewer
                        documents={[
                          {
                            uri: displayedURL.url!,
                            fileType: displayedURL.type,
                          },
                        ]}
                        pluginRenderers={[
                          BMPRenderer,
                          HTMLRenderer,
                          ImageProxyRenderer,
                          JPGRenderer,
                          MSDocRenderer,
                          MSGRenderer,
                          PDFRenderer,
                          PNGRenderer,
                          TIFFRenderer,
                          TXTRenderer,
                        ]}
                        config={{
                          noRenderer: {
                            overrideComponent: renderError as any,
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {displayedURL.type && (
                <>
                  {displayedURL?.type.toLowerCase().includes("pdf") ? (
                    <>
                      <Toolbar />
                      <Viewer
                        fileUrl={displayedURL.url!}
                        plugins={[toolbarPluginInstance]}
                        renderError={renderError}
                      />
                    </>
                  ) : (
                    <>
                      <DocViewer
                        documents={[
                          {
                            uri: displayedURL?.url!,
                            fileType: displayedURL.type,
                          },
                        ]}
                        pluginRenderers={[
                          BMPRenderer,
                          HTMLRenderer,
                          ImageProxyRenderer,
                          JPGRenderer,
                          MSDocRenderer,
                          MSGRenderer,
                          PDFRenderer,
                          PNGRenderer,
                          TIFFRenderer,
                          TXTRenderer,
                        ]}
                        config={{
                          noRenderer: {
                            overrideComponent: renderError as any,
                          },
                          header: {
                            disableFileName: true,
                            overrideComponent: CustomHeader,
                          },
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const FileViewer = ({
  url,
  viewModal,
  setUrl,
  setViewModal,
  hasWaterMark,
  fileType,
}: {
  url: string | null;
  viewModal: boolean;
  setViewModal: (value: React.SetStateAction<boolean>) => void;
  setUrl: (value: any) => void;
  hasWaterMark?: boolean;
  fileType?: string;
}) => {

  return (
    <Modal
      isOpen={viewModal}
      toggle={() => {
        setViewModal(false);
        setUrl(null);
      }}
      className={`${url?.includes(".doc") ? "with_html" : ""}`}
    >
      <ModalHeader
        toggle={() => {
          setViewModal(false);
          setUrl(null);
        }}
      >
        Prévisualisation du fichier
      </ModalHeader>
      <ModalBody>
        <Doc url={url} hasWaterMark={hasWaterMark} fileType={fileType} />
      </ModalBody>
    </Modal>
  );
};

export default FileViewer;
