import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { Company, CountNotif, User } from "../../interfaces";
import { setCountNotifs } from "../../store/reducers/user/userSlice";
import { UserTypes } from "../../util/context";
import { ErrorLogger } from "../../util/errorLogger";
import useAxios from "../../util/hooks/useAxios";
import "./DropdownMenu.scss";









type DropDownProps = {
  title?: string;
  badge?: string;
  className?: string;
  icon?: any;
  customToggle?: any;
  contentData?: any;
  renderItems?: any;
  item?: any;
  index?: any;
  renderFooter?: any;
};

const renderNotificationItem = (item: any) => (
  <div className="notification-item">
    <i className={item.icon}></i>
    <span>{item.content}</span>
  </div>
);

const Dropdown: React.FC<DropDownProps> = ({
  icon,
}) => {
  const [showItems, setShowItems] = useState(false);

  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  let api = useAxios();

  const [countNotif, setCountNotif] = useState<CountNotif | null>();
  const getCountNotif = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/getCountedTransactions`,
        {
          company_id: creds.company_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setCountNotif(data.data ? data.data : null);
    } catch (error: any) {
      ErrorLogger("sgetting count notif", error);
    }
  };

  const memoizedGetCountNotif = useCallback(getCountNotif, [creds.token]);

  useEffect(() => {
    const clickOutsideRefHandler = (e: { target: any; }) => {
      // user click toggle
      if ((dropdown_toggle_el as any).current && (dropdown_toggle_el as any).current.contains(e.target)) {
        (dropdown_content_el as any).current.classList.toggle("active");
      } else {
        // user click outside toggle and content
        if ((dropdown_content_el as any).current && !(dropdown_content_el as any).current.contains(e.target)) {
          (dropdown_content_el as any).current.classList.remove("active");
        }
      }
    }
    document.addEventListener("click", clickOutsideRefHandler);

    return () => document.removeEventListener("click", clickOutsideRefHandler);
  }, []);

  useEffect(() => {
    if (creds.role === UserTypes.Client) {
      memoizedGetCountNotif();
    }
  }, [creds.token]);

  const updateCountNotif = async () => {
    try {
      if (!countNotif || (countNotif && !countNotif.seen)) {
        const { data } = await api.post(
          `/api/CountNotif/UpdateWhere`,
          {
            where: {
              company_id: creds.company_id,
            },
            data: {
              seen: true,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        setCountNotif((prevState) => ({
          ...(prevState as CountNotif),
          seen: true,
        }));
      }
    } catch (error: any) {
      ErrorLogger("state rehydration in App", error);
    }
  };

  return (
    <div className="dropdown">
      <button
        ref={dropdown_toggle_el}
        className="btn-icon btn btn-outline-primary"
        onClick={async () => {
          if (
            !(dropdown_content_el.current as any)?.classList.contains("active")
          ) {
            await updateCountNotif();
          }
        }}
      >
        {countNotif && !countNotif.seen ? (
          <Badge color="primary"> </Badge>
        ) : (
          <></>
        )}

        {icon ? icon : ""}
      </button>
      <div ref={dropdown_content_el} className="dropdown__content">
        {countNotif && Object.keys(countNotif).length > 0 ? (
          <>
            {countNotif.nonArchived && countNotif.nonArchived > 0
              ? renderNotificationItem({
                  icon: "bx bx-bell",
                  content: `${countNotif.nonArchived} opération(s) pas encore archivée(s)`,
                })
              : ""}
            {countNotif.nonCategorized && countNotif.nonCategorized > 0
              ? renderNotificationItem({
                  icon: "bx bx-bell",
                  content: `${countNotif.nonCategorized} transaction(s) pas encore catégorisée(s)`,
                })
              : ""}
          </>
        ) : (
          ""
        )}
        {/* {contentData && renderItems
          ? contentData.map((item: any, index: any) => 
          : ""} */}
        {/* {renderFooter ? (
          <div className="dropdown__footer">{renderFooter()}</div>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};

export default Dropdown;
