import React, { useEffect, useState } from "react";
import "./ExercicePrecedent.scss";
import Select from "react-select";
import { RiCalendar2Line, RiDownload2Fill } from "react-icons/ri";
import juridiqueIcons from "../../../assets/images/svg/juridique-icon.svg";
import PhoneIcon from "../../../assets/images/svg/phone-blue.svg";
import ExpertIcon from "../../../assets/images/svg/expert-icon.svg";
import EmailIcon from "../../../assets/images/svg/mail-blue.svg";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import config from "../../../config";
import { Company, User } from "../../../interfaces";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ErrorLogger } from "../../../util/errorLogger";
import { fetchAllCompanies } from "../../../store/reducers/companies-list/companiesSlice";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/fr";
import { EXERCICE_OPTIONS, OptionType, UserTypes } from "../../../util/context";
import { toast, ToastContainer } from "react-toastify";
import AttacheIcon from "../../../assets/AttacheIcon";
import { BsTrashFill } from "react-icons/bs";
import { deleteFile } from "../../ClientInfo/InformationClient";
import FileViewer from "../../../components/FileViewer";
import "react-toastify/dist/ReactToastify.css";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import FilePicker from "../../../components/FilePicker";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

type GeneralFormValues = Omit<
  Partial<Company>,
  "beginningOfFiscalYear" | "dateOfExercise"
> & {
  beginningOfFiscalYear: OptionType | null;
  dateOfExercise: string | null;
};

const { API_URL } = config[process.env.NODE_ENV];

export interface AvaliderProps {}

const ExercicePrecedent = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState<any>();
  const [livre, setLivre] = useState<any>();

  const [balanceLoading, setBalanceLoading] = useState<any>();
  const [livreLoading, setLivreLoading] = useState<any>();

  const [viewModal, setViewModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { beginningOfFiscalYear: _beginningOfFiscalYear } = watch();

  const getCompany = () => {
    try {
      setCurrentCompany(company);
      reset({
        expertName: company?.expertName,
        firmName: company?.firmName,
        expertEmail: company?.expertEmail,
        expertPhone: company?.expertPhone,
        beginningOfFiscalYear:
          EXERCICE_OPTIONS.find(
            (elt) => elt.value === company?.beginningOfFiscalYear
          ) || null,
        dateOfExercise: company?.dateOfExercise
          ? moment(company?.dateOfExercise).format()
          : null,
      });
      setBalance(company?.balanceComptable);
      setLivre(company?.grandLivre);
    } catch (error: any) {
      ErrorLogger("getting company info", error);
    }
  };

  useEffect(() => {
    getCompany();
  }, [company]);

  const { ref: expertNameRef, ...expertName } = register("expertName");
  const { ref: firmNameRef, ...firmName } = register("firmName");
  const { ref: expertEmailRef, ...expertEmail } = register("expertEmail");
  const { ref: expertPhoneRef, ...expertPhone } = register("expertPhone");
  const { ref: beginningOfFiscalYearRef, ...beginningOfFiscalYear } = register(
    "beginningOfFiscalYear"
  );

  let api = useAxios();

  const callbackWrapper = async () => {
    if (creds.role !== UserTypes.Client) {
      return dispatch(fetchAllCompanies());
    } else {
      return await callback!();
    }
  };

  const updateCompany: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);

      const formData = new FormData();
      if (creds.role !== UserTypes.Client) {
        formData.append("id", id as string);
      } else {
        formData.append("id", creds.company_id as string);
      }

      if (livre && !livre.key) {
        formData.append("grandLivre", livre);
      }

      if (balance && !balance.key) {
        formData.append("balanceComptable", balance);
      }

      if (String(form.expertName) !== String(currentCompany?.expertName)) {
        formData.append("expertName", String(form.expertName));
      }

      if (String(form.firmName) !== String(currentCompany?.firmName)) {
        formData.append("firmName", String(form.firmName));
      }

      if (String(form.expertEmail) !== String(currentCompany?.expertEmail)) {
        formData.append("expertEmail", String(form.expertEmail));
      }

      if (String(form.expertPhone) !== String(currentCompany?.expertPhone)) {
        formData.append("expertPhone", String(form.expertPhone));
      }

      if (
        String(form.beginningOfFiscalYear?.value) !==
          String(currentCompany?.beginningOfFiscalYear) &&
        form.beginningOfFiscalYear
      ) {
        formData.append(
          "beginningOfFiscalYear",
          String(form.beginningOfFiscalYear?.value)
        );
      }

      if (
        moment(form.dateOfExercise).format("dd/MM/yyyy") !==
        moment(currentCompany?.dateOfExercise).format("dd/MM/yyyy")
      ) {
        formData.append(
          "dateOfExercise",
          moment(form.dateOfExercise).format("YYYY-MM-DD hh:mm")
        );
      }

      await api.post(`/api/Company/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await callbackWrapper();
      getCompany();
      setLoading(false);
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  const addCompanyFile = async (file: any, type: string) => {
    try {
      if (type === "grandLivre") {
        setLivreLoading(true);
      }
      if (type === "balanceComptable") {
        setBalanceLoading(true);
      }

      const formData = new FormData();
      if (creds.role !== UserTypes.Client) {
        formData.append("id", id as string);
      } else {
        formData.append("id", creds.company_id as string);
      }

      formData.append(type, file);

      await api.post(`/api/Company/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await callbackWrapper();
      getCompany();
      if (type === "grandLivre") {
        setLivreLoading(false);
      }
      if (type === "balanceComptable") {
        setBalanceLoading(false);
      }
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company form", error);
      if (type === "grandLivre") {
        setLivreLoading(false);
      }
      if (type === "balanceComptable") {
        setBalanceLoading(false);
      }
    }
  };

  const handleBalanceChange = async (event: { target: { files: any } }) => {
    // setBalance(event.target.files![0]);
   await addCompanyFile(event.target.files![0],"balanceComptable")
  };

  const handleGrandLivreChange = async (event: { target: { files: any } }) => {
    // setLivre(event.target.files![0]);
    await addCompanyFile(event.target.files![0],"grandLivre")
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <form onSubmit={handleSubmit(updateCompany)}>
          <div className="section-exercise-prev">
            <Row>
              <Col md={6} sm={12} className=" pe-lg-4">
                <FormGroup className="form-icon icon-end">
                  <Label for="dateOfExercise">
                    Date de fin de l'exercice en cours
                  </Label>
                  <Controller
                    control={control}
                    name="dateOfExercise"
                    render={({ field }) => (
                      <DatePicker
                        onChange={(date: any) => field.onChange(date)}
                        selected={field.value ? new Date(field.value) : null}
                        className="form-control form-secondary"
                        locale="fr"
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  {/*  <Input
                     id="dti"
                     innerRef={dateOfExerciseRef}
                    {...dateOfExercise}
                     className="form-secondary"
                   /> */}
                  <span className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={CalenderIconBlue} alt="icon" /> */}
                    {<ReactSVG src={CalenderIconBlue} />}
                  </span>
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className="ps-lg-4">
                <FormGroup>
                  <Label for="beginningOfFiscalYear">
                    Sagit-il du premier exercice ?
                  </Label>
                  <Controller
                    name="beginningOfFiscalYear"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={EXERCICE_OPTIONS}
                        closeMenuOnSelect={true}
                        classNamePrefix="select"
                        className="custom-select form-secondary"
                      />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            {loading ? (
              <Spinner color="light" type="border" size={"md"}>
                Loading...
              </Spinner>
            ) : (
              <>
                {_beginningOfFiscalYear?.label === "Non" && !loading && (
                  <>
                    <h3 className="mt-lg-5">Contact du précédent expert:</h3>
                    <Row>
                      <Col md={3} sm={6} className="pe-md-4">
                        <FormGroup className="form-icon icon-start">
                          <Label for="namecamp">Nom du cabinet</Label>
                          <Input
                            id="namecamp"
                            innerRef={firmNameRef}
                            {...firmName}
                            placeholder="Nom de l'entreprise"
                            type="text"
                            className="form-secondary"
                          />
                          <span className="icon icon-secondary icon-secondary-fill">
                              {/* <img src={juridiqueIcons} alt="icon" /> */}
                              {<ReactSVG src={juridiqueIcons} />}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3} sm={6} className="px-md-4">
                        <FormGroup className="form-icon icon-start">
                          <Label for="nexc">Nom de l'expert</Label>
                          <Input
                            id="nexc"
                            innerRef={expertNameRef}
                            {...expertName}
                            placeholder="Nom de l'expert"
                            type="text"
                            className="form-secondary"
                          />
                          <span className="icon icon-secondary icon-secondary-fill">
                              {/* <img src={ExpertIcon} alt="icon" /> */}
                              {<ReactSVG src={ExpertIcon} />}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3} sm={6} className="px-md-4">
                        <FormGroup className="form-icon icon-start">
                          <Label for="phone">Numéro de Téléphone</Label>
                          <Input
                            id="phone"
                            innerRef={expertPhoneRef}
                            {...expertPhone}
                            placeholder="Nº de Téléphone"
                            type="text"
                            className="form-secondary"
                          />
                          <span className="icon icon-secondary icon-secondary-fill">
                              {/* <img src={PhoneIcon} alt="icon" /> */}
                              {<ReactSVG src={PhoneIcon} />}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3} sm={6} className="ps-md-4">
                        <FormGroup className="form-icon icon-start">
                          <Label for="email">Email</Label>
                          <Input
                            id="email"
                            innerRef={expertEmailRef}
                            {...expertEmail}
                            placeholder="Email"
                            type="text"
                            className="form-secondary"
                          />
                          <span className="icon icon-secondary icon-secondary-fill">
                              {/* <img src={EmailIcon} alt="icon" /> */}
                              {<ReactSVG src={EmailIcon} />}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                    <h3 className="mt-lg-5">
                      Balance comptable du dernier exercice et le grand livre :{" "}
                    </h3>
                    <Row>
                      <p>
                        Il conviendra de nous faire parvenir la balance
                        comptable du dernier exercice ainsi que le grand livre.
                      </p>
                      <Col md={4} sm={6} className="pe-md-4">
                        <FormGroup className="form-icon icon-start">
                          <Label for="namecamp">Balance comptable</Label>
                          <FilePicker
                            className="form-icon icon-start form-file file-secondary"
                            onChange={handleBalanceChange}
                            state={balance}
                            fileReadyDelete={async () => {
                              try {
                                setBalanceLoading(true);
                                await deleteFile(
                                  "Company",
                                  company?.id!,
                                  "balanceComptable",
                                  creds.token,
                                  api
                                );
                                await callbackWrapper();
                                getCompany();
                                setBalanceLoading(false);
                                toast.success(
                                  "Votre supression a été effectuée avec succès!",
                                  {
                                    position: "bottom-right",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                              } catch (error: any) {
                                console.table("deleting file : ", error);
                              }
                            }}
                            fileStagedDelete={() => setBalance(null)}
                            setUrl={setUrl}
                            setViewModal={setViewModal}
                            loader={balanceLoading}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4} sm={6} className="px-md-4">
                        <FormGroup className="form-icon icon-start">
                          <Label for="nexc">Grand livre</Label>
                          <FilePicker
                            className="form-icon icon-start form-file file-secondary"
                            onChange={handleGrandLivreChange}
                            state={livre}
                            fileReadyDelete={async () => {
                              try {
                                setLivreLoading(true);
                                await deleteFile(
                                  "Company",
                                  company?.id!,
                                  "grandLivre",
                                  creds.token,
                                  api
                                );
                                await callbackWrapper();
                                getCompany();
                                setLivreLoading(false);
                                toast.success(
                                  "Votre supression a été effectué avec succès!",
                                  {
                                    position: "bottom-right",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                              } catch (error: any) {
                                console.table("deleting file : ", error);
                              }
                            }}
                            fileStagedDelete={() => setBalance(null)}
                            setUrl={setUrl}
                            setViewModal={setViewModal}
                            loader={livreLoading}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

            <div className="text-center mt-5 bottom-actions">
              {/* <Button
                color="secondary"
                outline
                onClick={function noRefCheck() {}}
              >
                Reinitialiser
              </Button> */}
              <Button color="secondary" type="submit" disabled={loading}>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Enregistrer"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default ExercicePrecedent;
