import React, { useState, FormEvent } from "react";
import { useForm, SubmitHandler, set } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./signin.scss";
import { Alert, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import axios from "axios";
import loginImg from "../../../assets/images/logo.svg";
import keyImg from "../../../assets/images/svg/key.svg";
import sendImg from "../../../assets/images/svg/send.svg";
import { Link, useNavigate } from "react-router-dom";
import { ErrorLogger } from "../../../util/errorLogger";
import config from "../../../config";
import { useDispatch } from "react-redux";
import {
  setCompanyId,
  setUserId,
  setToken,
  setApplicationId,
  setUserRole,
  setCompanyPack,
} from "../../../store/reducers/root/rootSlice";
import {
  setApplication,
  setCompany,
  setPermissions,
  setUser,
} from "../../../store/reducers/user/userSlice";
import { fetchAllCompanies } from "../../../store/reducers/companies-list/companiesSlice";
import { fetchAllClients } from "../../../store/reducers/clients-list/clientsSlice";
import { fetchAllApplications } from "../../../store/reducers/applications/applicationsSlice";
import { fetchAllPermissions } from "../../../store/reducers/permissions-list/permissionsListSlice";
import useAxios from "../../../util/hooks/useAxios";
import { UserTypes } from "../../../util/context";
import { getCountNotifs } from "../../../store/reducers/utils/utilSlice";
import { images } from "../../../constants";
import { ReactSVG } from "react-svg";

type FormValues = {
  email: string;
  password: string;
};

const emailSchema = yup.string().email();

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

const SignIn: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  let api = useAxios();

  const onSubmit: SubmitHandler<FormValues> = async (payload) => {
    try {
      setErrorMessage(null);
      if (!payload.email) {
        return setErrorMessage({
          type: "invalid_email",
          message: "L'email est un champ obligatoire",
        });
      }
      if (!emailSchema.isValidSync(payload.email)) {
        return setErrorMessage({
          type: "invalid_email",
          message: "Vous devez fournir une adresse email valide",
        });
      }
      if (!payload.password) {
        return setErrorMessage({
          type: "invalid_password",
          message: "Le mot de passe est un champ obligatoire",
        });
      }
      setLoading(true);
      const response = await api.post(`/api/auth/login`, payload);
      if (response) {
        const { data } = response;
        const { token } = data;
        const { company, ...user } = data.user;
        const { application, permissions } = user;
        if (application.id !== APPLICATION_ID) {
          setLoading(false);
          return setErrorMessage({
            type: "email",
            message: "Pas d'utilisateur trouvé",
          });
        }
        dispatch(setUserId(user.id));
        dispatch(setUser(user));
        dispatch(setApplicationId(application.id));
        dispatch(setApplication(application));
        company && dispatch(setCompanyId(company.id));
        company && dispatch(setCompanyPack(company.pack));
        company && dispatch(setCompany(company));
        permissions && dispatch(setPermissions(permissions));
        dispatch(setToken(token));
        dispatch(setUserRole(user.role));
        if (user.role !== UserTypes.Client) {
          dispatch(fetchAllApplications());
          dispatch(fetchAllPermissions());
        }

        navigate("/");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        !error.response.data.auth &&
        error.response.data.message.toLowerCase().includes("no user found")
      ) {
        setErrorMessage({ type: "email", message: "Pas d'utilisateur trouvé" });
      }
      if (
        !error.response.data.auth &&
        error.response.data.message
          .toLowerCase()
          .includes("user is deactivated")
      ) {
        setErrorMessage({
          type: "email",
          message: "Votre compte n'est pas actif, veuillez l'activer",
        });
      }
      if (
        !error.response.data.auth &&
        error.response.data.message.toLowerCase().includes("password unvalid")
      ) {
        setErrorMessage({ type: "password", message: "Mot de passe invalide" });
      }
      ErrorLogger("login form", error);
    }
  };

  const { ref: emailRef, ...email } = register("email");
  const { ref: passwordRef, ...password } = register("password");

  return (
    <div className="main-signin">
      <div className="top-Login">
        <div className="login-top">
          <img src={images.logo} />
        </div>
        <h1>Connexion</h1>
        <p>
          Entrez votre email et votre mot de passe pour accéder à votre compte
          Noly
        </p>
      </div>
      <form className="connexion-form" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="form-icon icon-start">
          <Label for="email">E-mail</Label>
          <Input
            // id="email"
            placeholder="E-mail"
            type="text"
            className="form-secondary"
            // defaultValue={intialValues.email}
            {...email}
            innerRef={emailRef}
            // onChange={() => {
            //   setErrorMessage(null);
            // }}
          />
          <span className="icon icon-secondary icon-secondary-fill">
            {/* <img src={sendImg} alt="icon" /> */}
            {<ReactSVG src={sendImg} />}
          </span>
        </FormGroup>
        {/* {errors.email && <Alert color="danger">{errors.email?.message}</Alert>} */}
        {errorMessage?.type === "invalid_email" && (
          <Alert color="danger">{errorMessage?.message}</Alert>
        )}
        {errorMessage?.type === "email" && (
          <Alert color="danger">{errorMessage?.message}</Alert>
        )}
        <FormGroup className="form-icon icon-start">
          <Label for="pwd">Mot de passe</Label>
          <Input
            // id="pwd"
            placeholder="Mot de passe"
            type="password"
            className="form-secondary"
            // defaultValue={intialValues.password}
            {...password}
            // onChange={() => {
            //   setErrorMessage(null);
            // }}
            innerRef={passwordRef}
          />
          <span className="icon icon-secondary icon-secondary-fill">
            {/* <img src={keyImg} alt="icon" /> */}
            {<ReactSVG src={keyImg} />}
          </span>
        </FormGroup>
        {/* {errors.password && (
          <Alert color="danger">{errors.password?.message}</Alert>
        )} */}
        {errorMessage?.type === "invalid_password" && (
          <Alert color="danger">{errorMessage?.message}</Alert>
        )}
        {errorMessage?.type === "password" && (
          <Alert color="danger">{errorMessage?.message}</Alert>
        )}
        <div className="bottom-action">
          <button className="btn btn-secondary" type="submit">
            {loading ? (
              <Spinner color="light" type="border" size={"sm"}>
                Loading...
              </Spinner>
            ) : (
              "Se connecter"
            )}
          </button>

          <div className="bottom-link">
            <Link className="forgot-pwd" to="/forgot-password">
              Mot de passe oublié ?
            </Link>
            <Link className="register-mobile" to="/register">
              Créez un compte
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignIn;
