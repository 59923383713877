import React, { useEffect, useState } from "react";
import "./TransactionsAvalider.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { BootyPagination } from "../../../components/table/pagination";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import {
  Company,
  ICategory,
  IPermission,
  ISubCategory,
  ITransaction,
  User,
} from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import axios from "axios";
import moment from "moment";
import {
  setCounter,
  setErrorMessage,
} from "../../../store/reducers/utils/utilSlice";
import {
  RiArchiveFill,
  RiArrowDropDownLine,
  RiCloseLine,
  RiDeleteBin5Line,
  RiDownload2Fill,
  RiEdit2Fill,
  RiErrorWarningLine,
  RiSearchLine,
  RiTimeFill,
} from "react-icons/ri";
import tvaEditIcon from "../../../assets/images/svg/edit-small-blue.svg";
import TourVeh from "../../../assets/images/vahTypeTour.png";
import UtilVeh from "../../../assets/images/vehTypeUtil.png";
import { useFormatter } from "../../../util/hooks/useFormatter";
import Encaissements from "../Encaissements";
import Decaissements from "../Decaissements";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import FileViewer from "../../../components/FileViewer";
import { number } from "yup/lib/locale";
import ArchiveIcon from "../../../assets/ArchiveIcon";
import {
  AppPacks,
  ASSETS_OPTIONS,
  ASSETS_OPTIONS_VALIDATORS,
  CarbVehTypes,
  CrudPermissions,
  OptionType,
  PayDiffKeys,
  TransactionMediums,
  UserTypes,
} from "../../../util/context";
import MoneySacIconBlue from "../../../assets/images/svg/money-sac-blue.svg";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import MoneySacIconGreen from "../../../assets/images/svg/money-sac-green.svg";
import CalenderIconGreen from "../../../assets/images/svg/calender-icon-green.svg";
import { useNavigate } from "react-router-dom";
import SaveIconWhite from "../../../assets/SaveIconWhite";
import { ReactSVG } from "react-svg";
import EditIcon from "../../../assets/EditIcon";
import { useTVA } from "../../../util/hooks/useTVA";
import { FaEye, FaTrash } from "react-icons/fa";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { usePermissions } from "../../../util/hooks/usePermissions";
import { ImmobStateHandler } from "../../../components/Tabs/Tabs";
import useAxios from "../../../util/hooks/useAxios";
import { deleteFile } from "../../ClientInfo/InformationClient";
import AttacheIcon from "../../../assets/AttacheIcon";
import { FiInfo } from "react-icons/fi";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

type SearchFormValues = {
  name: string | null;
};

type DynamicObj = {
  [prop: string]: [value: boolean];
};

type ImmobFormValues = {
  type: OptionType | null;
  numberOfYears: number | null;
  transactionName: string | null;
  transactionDate: string | null;
  transactionAmount: number | null;
};

type EmpruntFormValues = {
  capitalAmount: number | null;
  chargeAmount: number | null;
  insuranceAmount: number | null;
};

type GeneralFormValues = {
  amount: number | null;
  label: string | null;
  date: string | null;
  category: { value: string; label: string } | null;
  document?: any;
  modalType?: string | null;
};

const TransactionsAvalider = ({
  context,
  callback,
  reload,
  company,
  immobStateHandler,
}: {
  context?: string;
  callback?: any;
  reload?: boolean;
  company?: Company;
  immobStateHandler?: ImmobStateHandler;
}) => {
  const [loading, setLoading] = useState(false);
  const [carbLoading, setCarbLoading] = useState(false);
  const [addNoteFraisModal, setAddNoteFraisModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [immbModal, setImmobModal] = useState<boolean>(false);
  const [immobMoreInfo, setImmobMoreInfo] = useState<boolean>(false);
  const [payDiffValidation, setPayDiffValidation] = useState<boolean>(false);
  const [payDiffArchive, setPayDiffArchive] = useState<boolean>(false);
  const [openCategModal, setOpenCategModal] = useState<boolean>(false);
  const [stopArchiveModal, setStopArchiveModal] = useState<boolean>(false);
  const [empruntModal, setEmpruntModal] = useState<boolean>(false);
  const [empruntMoreInfo, setempruntMoreInfo] = useState<boolean>(false);
  const [singleEmprunt, setSingleEmprunt] = useState<ITransaction | null>();
  const [categStatus, setCategStatus] = useState<string | null>();
  const [categRemb, setCategremb] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [editTVAClick, setEditTVAClick] = useState<DynamicObj>({});
  const [payDiffEditTVAClick, setPayDiffEditTVAClick] = useState<DynamicObj>(
    {}
  );
  const [payDiffNeddValidation, setPayDiffNeddValidation] =
    useState<DynamicObj>({});
  const [url, setUrl] = useState<string | null>(null);
  const [decCategs, setDecCategs] = useState<ICategory[]>([]);
  const [encCategs, setEncCategs] = useState<ICategory[]>([]);
  const [isImmobCateg, setIsImmobCateg] = useState<ISubCategory | null>();
  const [isCarbCateg, setIsCarbCateg] = useState<ISubCategory | null>();
  const [noFilesModal, setNoFilesModal] = useState<boolean>(false);
  const [noFilesLoading, setNoFilesLoading] = useState<boolean>(false);
  const [noFilesTrans, setNoFilesTrans] = useState<ITransaction | null>();
  const [singleTrans, setSingleTrans] = useState<ITransaction | null>();
  const [payDiffTrans, setPayDiffTrans] = useState<ITransaction | null>();
  const [singleImmob, setImmob] = useState<ITransaction | null>();
  const [singleCarb, setSingleCarb] = useState<ITransaction | null>();
  const [singleTVA, setSingleTVA] = useState<{
    id: string;
    value: string | null;
  } | null>();
  const [singlePayDiffTVA, setSinglePayDiffTVA] = useState<{
    id: string;
    value: string | null;
  } | null>();
  const [file, setFile] = useState("");
  const [isImmobModal, setIsImmobModal] = useState<boolean>(false);
  const [isCarbModal, setIsCarbModal] = useState<boolean>(false);
  const [diffPayModal, setDiffPayModal] = useState<boolean>(false);
  const [diffPayValidateModal, setDiffPayValidateModal] =
    useState<boolean>(false);
  const [diffPayArchiveModal, setDiffPayArchiveModal] =
    useState<boolean>(false);
  const [closeCategSearch, setCloseCategSearch] = useState<boolean>(false);
  const [searchCategName, setSearchCategName] = useState<string | null>();
  const [refreshCategs, setRefreshCategs] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string | null>();
  const { setDecimalDigits } = useFormatter();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const ontoggle = (id: string) => {
    setTransactionId(id);
    setOpen(true);
  };

  const [totalRows, setTotalRows] = useState(0);

  const [transactionsToValid, setTransactionsToValid] = useState<
    ITransaction[]
  >([]);

  const [payDiffTransArray, setPAyDiffTransArray] = useState<ITransaction[]>(
    []
  );

  const navigate = useNavigate();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser, permissions } = useSelector(
    (state: { user: object; permissions: object }) => state.user
  ) as { user: User; permissions: IPermission[] };

  const { hasCrudPermission } = usePermissions();
  let api = useAxios();

  const columns = React.useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: { date: Date }) => moment(row.date).format("DD/MM/YYYY"),
      },
      {
        name: "Transaction",
        selector: (row: any) => row.label,
        sortable: true,
        cell: (row: any) => (
          <div className="tr-categ-cell-wrapper">
            <p>{row.label}</p>
            <div className="tr-categ-cell">
              {!row.sub_category && (
                <p
                  onClick={() => {
                    if (parseFloat(row.amount) > 0) {
                      setCategStatus("enc");
                    } else {
                      setCategStatus("dec");
                    }
                    setSingleTrans(row);
                    setOpenCategModal(true);
                  }}
                  className="tr-categ-cell-toCateg"
                >
                  À catégoriser <RiArrowDropDownLine size={30} />
                </p>
              )}

              {row.sub_category && (
                <p>
                  {row.sub_category.name}{" "}
                  <RiEdit2Fill
                    onClick={() => {
                      if (parseFloat(row.amount) > 0) {
                        setCategStatus("enc");
                      } else {
                        setCategStatus("dec");
                      }
                      setSingleTrans(row);
                      setOpenCategModal(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
        ),
      },
      // {
      //   name: "Catégorie",
      //   sortable: true,
      //   cell: (row: any) => (
      //     <div className="file-table">
      //       {!row.sub_category && (
      //         <Button
      //           onClick={() => {
      //             if (parseFloat(row.amount) > 0) {
      //               setCategStatus("enc");
      //             } else {
      //               setCategStatus("dec");
      //             }
      //             setSingleTrans(row);
      //             setOpenCategModal(true);
      //           }}
      //           color="primary"
      //           outline
      //           type="button"
      //           className="category-selection-button btn-small"
      //         >
      //           À catégoriser
      //         </Button>
      //       )}

      //       {row.sub_category && (
      //         <div className="list-Files categ-box i-primary">
      //           <span className="categ-name">{row.sub_category.name}</span>
      //           <button
      //             className="icon-tva"
      //             type="submit"
      //             onClick={() => {
      //               if (parseFloat(row.amount) > 0) {
      //                 setCategStatus("enc");
      //               } else {
      //                 setCategStatus("dec");
      //               }
      //               setSingleTrans(row);
      //               setOpenCategModal(true);
      //             }}
      //           >
      //             {/* <img src={tvaEditIcon} alt="icon" /> */}
      //             <EditIcon fill={tvaEditIcon} />
      //           </button>
      //         </div>
      //       )}
      //     </div>
      //   ),
      // },
      {
        name: "Justificatif",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => (
          <>
            <div className="file-table">
              <FormGroup className="form-file file-primary">
                <label className="file-box ">
                  <RiDownload2Fill />
                  <Input
                    type="file"
                    className="form-default"
                    onChange={async (e) => {
                      try {
                        const formData = new FormData();
                        formData.append("id", row?.id);
                        formData.append("document", e.target.files![0]);

                        await api.post(`/api/Transaction/Update`, formData, {
                          headers: {
                            "x-access-token": creds.token,
                          },
                        });
                        await getTransactions();
                      } catch (error: any) {
                        ErrorLogger("updating a transaction's document", error);
                      }
                    }}
                  />
                  <span> Télécharger d'ici</span>
                </label>
              </FormGroup>
              <div className="list-Files">
                {row.document && (
                  <>
                    <span className="file-box-item transaction-file">
                      <FaEye
                        onClick={() => {
                          setUrl(row.document.url);
                          setViewModal(true);
                        }}
                      />
                      <span>{row.document.key}</span>
                      <FaTrash
                        onClick={async () => {
                          try {
                            await deleteFile(
                              "Transaction",
                              row.id,
                              "document",
                              creds.token,
                              api
                            );
                            await getTransactions();
                          } catch (error: any) {
                            ErrorLogger(
                              "deleting a transaction's document",
                              error
                            );
                          }
                        }}
                      />
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        name: "Montant",
        sortable: true,
        cell: (row: any) => (
          <span
            className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      {
        name: "Tva",
        sortable: true,
        omit:
          company?.pack === AppPacks.Entrepreneur ||
          company?.pack === AppPacks.BNCAssoc ,
        cell: (row: ITransaction) => {
          return (
            <>
              <div className="tva-box i-secondary">
                <input
                  key={row.id}
                  name="tva"
                  placeholder="0"
                  className="num-tva"
                  value={
                    singleTVA?.id === row.id
                      ? String(singleTVA.value)
                      : row.vat
                      ? parseFloat(row.vat).toFixed(2)
                      : ""
                  }
                  type="number"
                  onWheel={(e) => (e.target as any).blur()}
                  disabled={!editTVAClick[row.id]!}
                  onChange={(e) =>
                    setSingleTVA({
                      id: row.id,
                      value: e.target.value,
                    })
                  }
                  min={0}
                />
                <span className="device-tva">€ TVA </span>
                {!editTVAClick[row.id] ? (
                  <button
                    className="icon-tva i-secondary"
                    type="submit"
                    onClick={() => {
                      setEditTVAClick((prevState: any) => {
                        return {
                          ...prevState,
                          [row.id]: !prevState[row.id],
                        };
                      });
                    }}
                  >
                    {<ReactSVG src={tvaEditIcon} />}
                    {/* <img src={tvaEditIcon} alt="icon" /> */}
                  </button>
                ) : (
                  <button
                    className="icon-tva i-secondary"
                    type="submit"
                    onClick={async () => await editTransactionVat(row)}
                  >
                    <SaveIconWhite />
                  </button>
                )}
              </div>
            </>
          );
        },
      },
      {
        name: "Statut",
        selector: (row: any) => row.status,
        cell: (row: ITransaction) => (
          <>
            <Button
              color={row.status === 100 ? "warning" : "success"}
              className="btn-status btn-small"
              onClick={async () => {
                try {
                  if (row.sub_category_id) {
                    if (
                      row.sub_category.name === "Emprunt" &&
                      row.amount <= 0 &&
                      row.status === 100
                    ) {
                      setSingleEmprunt(row);
                      setEmpruntModal(true);
                      return;
                    }

                    if (
                      (row.sub_category.key === PayDiffKeys.OUT101 ||
                        row.sub_category.key === PayDiffKeys.OUT100) &&
                      row.status === 100
                    ) {
                      await diffPayCheckValidate(row);
                      return;
                    }

                    const parentCateg = decCategs.find((elt) => {
                      return elt.sub_categories?.find(
                        (elt) => elt.id === row.sub_category_id
                      );
                    });
                    console.log(parentCateg);
                    console.log(row.numberOfYears);
                    if (
                      parentCateg?.name.includes("Immobilisations") &&
                      !row.numberOfYears
                    ) {
                      setImmob(row);
                      immobReset({
                        transactionAmount: Math.abs(row.amount),
                        transactionDate: moment(row.date).format("YYYY-MM-DD"),
                        type: {
                          value: row.sub_category.id,
                          label: `${row.sub_category.name} ${
                            row.sub_category.yearsRange.length > 1
                              ? `(${row.sub_category.yearsRange[0]} à ${row.sub_category.yearsRange[1]} ans)`
                              : `(${row.sub_category.yearsRange[0]} ans)`
                          }`,
                        },
                      });
                      setImmobModal(true);
                      return;
                    }
                  }
                  await validateTransaction(row);
                } catch (error: any) {
                  ErrorLogger("updating a transaction's document", error);
                }
              }}
              // disabled={row.status === 101}
            >
              {row.status === 100 ? "À valider" : "Validé"}
            </Button>
          </>
        ),
      },
      {
        name:
          creds.role === UserTypes.Client
            ? company?.pack !== AppPacks.Entrepreneur
              ? "Archiver"
              : "Comptabiliser"
            : "Action",
        button: true,
        cell: (row: ITransaction) => (
          <>
            {row.status === 100 ? (
              <>
                {permissions.length > 0 &&
                hasCrudPermission(
                  permissions,
                  CrudPermissions.DeleteTransactions
                ) ? (
                  <button
                    className="btn btn-red"
                    onClick={() => ontoggle(row.id)}
                  >
                    <BsTrashFill />
                  </button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <button
                className="btn btn-orange"
                onClick={async () => {
                  if (!row.subCategoryId) {
                    setStopArchiveModal(true);
                    return;
                  }
                  if (
                    row.sub_category.key === PayDiffKeys.OUT101 ||
                    row.sub_category.key === PayDiffKeys.OUT100
                  ) {
                    await diffPayCheckArchive(row);
                    return;
                  }
                  if (row.document) {
                    await archiveTransaction(row.id);
                  } else {
                    setNoFilesTrans(row);
                    setNoFilesModal(true);
                    return;
                  }
                }}
              >
                <ArchiveIcon />
              </button>
            )}
          </>
        ),
      },
    ],
    [company, permissions, editTVAClick, singleTVA]
  );

  const diffPayColumns = React.useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: { date: Date }) => moment(row.date).format("DD/MM/YYYY"),
      },
      {
        name: "Transaction",
        selector: (row: any) => row.label,
        sortable: true,
        cell: (row: any) => (
          <div className="tr-categ-cell-wrapper">
            <p>{row.label}</p>
            <div className="tr-categ-cell">
              {!row.sub_category && (
                <p
                  onClick={() => {
                    if (parseFloat(row.amount) > 0) {
                      setCategStatus("enc");
                    } else {
                      setCategStatus("dec");
                    }
                    setSingleTrans(row);
                    setOpenCategModal(true);
                  }}
                  className="tr-categ-cell-toCateg"
                >
                  À catégoriser <RiArrowDropDownLine size={30} />
                </p>
              )}

              {row.sub_category && (
                <p>
                  {row.sub_category.name}{" "}
                  <RiEdit2Fill
                    onClick={() => {
                      if (parseFloat(row.amount) > 0) {
                        setCategStatus("enc");
                      } else {
                        setCategStatus("dec");
                      }
                      setSingleTrans(row);
                      setOpenCategModal(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
        ),
      },
      // {
      //   name: "Catégorie",
      //   sortable: true,
      //   cell: (row: any) => (
      //     <div className="file-table">
      //       {!row.sub_category && (
      //         <Button
      //           onClick={() => {
      //             if (parseFloat(row.amount) > 0) {
      //               setCategStatus("enc");
      //             } else {
      //               setCategStatus("dec");
      //             }
      //             setSingleTrans(row);
      //             setOpenCategModal(true);
      //           }}
      //           color="primary"
      //           outline
      //           type="button"
      //           className="category-selection-button btn-small"
      //         >
      //           À catégoriser
      //         </Button>
      //       )}

      //       {row.sub_category && (
      //         <div className="list-Files categ-box i-primary">
      //           <span className="categ-name">{row.sub_category.name}</span>
      //           <button
      //             className="icon-tva"
      //             type="submit"
      //             onClick={() => {
      //               if (parseFloat(row.amount) > 0) {
      //                 setCategStatus("enc");
      //               } else {
      //                 setCategStatus("dec");
      //               }
      //               setSingleTrans(row);
      //               setOpenCategModal(true);
      //             }}
      //           >
      //             {/* <img src={tvaEditIcon} alt="icon" /> */}
      //             <EditIcon fill={tvaEditIcon} />
      //           </button>
      //         </div>
      //       )}
      //     </div>
      //   ),
      // },
      {
        name: "Justificatif",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => (
          <>
            <div className="file-table">
              <FormGroup className="form-file file-primary">
                <label className="file-box ">
                  <RiDownload2Fill />
                  <Input
                    type="file"
                    className="form-default"
                    onChange={async (e) => {
                      try {
                        const formData = new FormData();
                        formData.append("id", row?.id);
                        formData.append("document", e.target.files![0]);

                        await api.post(`/api/Transaction/Update`, formData, {
                          headers: {
                            "x-access-token": creds.token,
                          },
                        });
                        await getPayDiffTransactions(payDiffTrans?.id!);
                      } catch (error: any) {
                        ErrorLogger("updating a transaction's document", error);
                      }
                    }}
                  />
                  <span> Télécharger d'ici</span>
                </label>
              </FormGroup>
              <div className="list-Files">
                {row.document && (
                  <>
                    <span className="file-box-item transaction-file">
                      <FaEye
                        onClick={() => {
                          setUrl(row.document.url);
                          setViewModal(true);
                        }}
                      />
                      <span>{row.document.key}</span>
                      <FaTrash
                        onClick={async () => {
                          try {
                            await deleteFile(
                              "Transaction",
                              row.id,
                              "document",
                              creds.token,
                              api
                            );
                            await getTransactions();
                          } catch (error: any) {
                            ErrorLogger(
                              "deleting a transaction's document",
                              error
                            );
                          }
                        }}
                      />
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        name: "Montant",
        sortable: true,
        cell: (row: any) => (
          <span
            className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      {
        name: "Tva",
        sortable: true,
        omit:
          company?.pack === AppPacks.Entrepreneur ||
          company?.pack === AppPacks.BNCAssoc ||
          diffPayArchiveModal,
        cell: (row: ITransaction) => {
          return (
            <>
              <div className="tva-box i-secondary">
                <input
                  key={row.id}
                  name="tva"
                  placeholder="0"
                  className="num-tva"
                  value={
                    singlePayDiffTVA?.id === row.id
                      ? String(singlePayDiffTVA.value)
                      : row.vat
                      ? parseFloat(row.vat).toFixed(2)
                      : ""
                  }
                  type="number"
                  onWheel={(e) => (e.target as any).blur()}
                  disabled={!payDiffEditTVAClick[row.id]!}
                  onChange={(e) =>
                    setSinglePayDiffTVA({
                      id: row.id,
                      value: e.target.value,
                    })
                  }
                  min={0}
                />
                <span className="device-tva">€ TVA </span>
                {!payDiffEditTVAClick[row.id] ? (
                  <button
                    className="icon-tva"
                    type="submit"
                    onClick={() =>
                      setPayDiffEditTVAClick((prevState: any) => {
                        return {
                          ...prevState,
                          [row.id]: !prevState[row.id],
                        };
                      })
                    }
                  >
                    <img src={tvaEditIcon} alt="icon" />
                  </button>
                ) : (
                  <button
                    className="icon-tva i-blue"
                    type="submit"
                    onClick={async () => await editTransactionVat(row)}
                  >
                    <SaveIconWhite />
                  </button>
                )}
              </div>
            </>
          );
        },
      },
      {
        name: "Statut",
        selector: (row: any) => row.status,
        omit:
          Object.keys(payDiffNeddValidation).length === 0 ||
          diffPayArchiveModal,
        cell: (row: ITransaction) => (
          <>
            {payDiffNeddValidation[row.id] ? (
              <Button
                color={row.status === 100 ? "warning" : "success"}
                className="btn-status btn-small"
                onClick={async () => {
                  try {
                    if (row.sub_category_id) {
                      if (
                        row.sub_category.name === "Emprunt" &&
                        row.amount <= 0 &&
                        row.status === 100
                      ) {
                        setSingleEmprunt(row);
                        setEmpruntModal(true);
                        return;
                      }

                      const parentCateg = decCategs.find((elt) => {
                        return elt.sub_categories?.find(
                          (elt) => elt.id === row.sub_category_id
                        );
                      });
                      if (
                        parentCateg?.name.includes("Immobilisations") &&
                        !row.numberOfYears
                      ) {
                        setImmob(row);
                        immobReset({
                          transactionAmount: Math.abs(row.amount),
                          transactionDate: moment(row.date).format(
                            "YYYY-MM-DD"
                          ),
                          type: {
                            value: row.sub_category.id,
                            label: `${row.sub_category.name} ${
                              row.sub_category.yearsRange.length > 1
                                ? `(${row.sub_category.yearsRange[0]} à ${row.sub_category.yearsRange[1]} ans)`
                                : `(${row.sub_category.yearsRange[0]} ans)`
                            }`,
                          },
                        });
                        setImmobModal(true);
                        return;
                      }
                    }
                  } catch (error: any) {
                    ErrorLogger("updating a transaction's document", error);
                  }
                }}
                // disabled={row.status === 101}
              >
                {row.status === 100 ? "À valider" : "Validé"}
              </Button>
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        name: "Action",
        button: true,
        omit: diffPayArchiveModal,
        cell: (row: ITransaction) => (
          <>
            <button
              className="btn btn-red"
              onClick={async () => await deleteTransaction(row.id)}
            >
              <BsTrashFill />
            </button>
          </>
        ),
      },
    ],
    [
      payDiffNeddValidation,
      diffPayArchiveModal,
      payDiffEditTVAClick,
      singlePayDiffTVA,
    ]
  );

  const deleteTransaction = async (id: string = "") => {
    try {
      const { data } = await api.post(
        `/api/Transaction/deleteWhere`,
        {
          where: {
            id: [id !== "" ? id : transactionId],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== transactionId)
      );
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans?.id!);
      }
      setTransactionId(null);
      setOpen(false);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
    }
  };

  const deletePayDiffTransaction = async (id: string) => {
    try {
      const { data } = await api.post(
        `/api/Transaction/deleteWhere`,
        {
          where: {
            diffPay_id: [id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
    }
  };

  const validatePayDiffTransactions = async () => {
    try {
      setErrorMessage(null);
      setPayDiffValidation(true);

      if (payDiffTransArray.length > 0) {
        let issued = [] as string[];
        payDiffTransArray.forEach((tr) => {
          const parentCateg = decCategs.find((elt) => {
            return elt.sub_categories?.find(
              (elt) => elt.id === tr.sub_category_id
            );
          });
          const check =
            (parentCateg?.name.includes("Immobilisations") &&
              !tr.numberOfYears) ||
            (tr.sub_category.name === "Emprunt" &&
              tr.amount <= 0 &&
              tr.status === 100);

          if (check) {
            issued.push(tr.id);
          }
        });

        if (issued.length > 0) {
          for (let id of issued) {
            setPayDiffNeddValidation((prevState: any) => {
              return {
                ...prevState,
                [id]: !prevState[id],
              };
            });
          }
          setErrorMessage({
            type: "payDiff_need_validation",
            message:
              "Une ou plusieurs transactions doivent être validées manuellement",
          });
        } else {
          const { data } = await api.post(
            `/api/Transaction/Update`,
            {
              id: payDiffTrans?.id,
              status: 101,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
          for (let tr of payDiffTransArray) {
            if (tr.status === 100) {
              await api.post(
                `/api/Transaction/Update`,
                {
                  id: tr.id,
                  status: 101,
                },
                {
                  headers: {
                    "x-access-token": creds.token,
                  },
                }
              );
            }
          }
          setPayDiffNeddValidation({});
          await getTransactions();
          await getPayDiffTransactions(payDiffTrans?.id!);
          setDiffPayValidateModal(false);
          setPayDiffTrans(null);
        }
      }

      setPayDiffValidation(false);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
      await getTransactions();
      setPayDiffValidation(false);
      setPayDiffNeddValidation({});
    }
  };

  const archiveTransaction = async (id: string) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id,
          status: company && company.pack === AppPacks.Entrepreneur ? 104 : 102,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== id)
      );

      if (payDiffTrans && payDiffTrans.id) {
        await api.post(
          `/api/Transaction/UpdateWhere`,
          {
            where: {
              diffPay_id: id,
            },
            data: {
              status:
                company && company.pack === AppPacks.Entrepreneur ? 104 : 102,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
    }
  };

  const validateTransaction = async (row: ITransaction) => {
    try {
      let payload: {
        id: string;
        status: number;
        numberOfYears?: null;
      } = {
        id: row.id,
        status: row.status === 100 ? 101 : 100,
      };
      if (row.numberOfYears && row.status === 101) {
        payload = {
          ...payload,
          numberOfYears: null,
        };
      }

      await api.post(`/api/Transaction/Update`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (
        row.status === 101 &&
        (row.sub_category.key === PayDiffKeys.OUT101 ||
          row.sub_category.key === PayDiffKeys.OUT100)
      ) {
        await api.post(
          `/api/Transaction/UpdateWhere`,
          {
            where: {
              diffPay_id: row.id,
            },
            data: {
              status: 100,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      await getTransactions();
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
    // await getTransactions(page);
  };

  const getTransactions = async () => {
    try {
      callback(false);
      const { data } = await api.post(
        `/api/Transaction/All`,
        {
          where: {
            status: [100, 101],
            companyId: context,
            diffPay_id: {
              isNull: true,
            },
          },
          perPage: 20,
          pageIndex: currentPage,
          order: [
            ["date", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: transactionsData } = data;
      setTransactionsToValid(
        transactionsData.sort((a: { date: number }, b: { date: number }) => {
          return moment(b.date).diff(moment(a.date));
        })
      );
      for (let elt of transactionsData) {
        setEditTVAClick((prevState: any) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }
      setTotalRows(data.count);
    } catch (error: any) {
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const getPayDiffTransactions = async (payDiffId: string) => {
    try {
      const { data } = await api.post(
        `/api/Transaction/All`,
        {
          where: {
            diffPay_id: payDiffId,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: transactionsData } = data;
      setPAyDiffTransArray(
        transactionsData.sort((a: { date: number }, b: { date: number }) => {
          return moment(b.date).diff(moment(a.date));
        })
      );
      for (let elt of transactionsData) {
        setPayDiffEditTVAClick((prevState: any) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }
      return transactionsData;
    } catch (error: any) {
      ErrorLogger("getting pay diff non valid transactions", error);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await api.post(
        `/api/Category/All`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const enc = data.data.filter(
        (elt: ICategory) => elt.type === "Encaissement"
      );
      const dec = data.data.filter(
        (elt: ICategory) => elt.type === "Décaissement"
      );

      setDecCategs(dec);
      setEncCategs(enc);
    } catch (error: any) {
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchFormValues>({});

  const { ref: nameRef, ...name } = register("name");

  const {
    control: immobControl,
    setValue: immobSetValue,
    watch: immobWatch,
    register: immobRegister,
    handleSubmit: immobHandleSubmit,
    reset: immobReset,
    formState: { errors: immobErrors },
  } = useForm<ImmobFormValues>({});

  const {
    control: empruntControl,
    setValue: empruntSetValue,
    watch: empruntWatch,
    register: empruntRegister,
    handleSubmit: empruntHandleSubmit,
    reset: empruntReset,
    formState: { errors: empruntErrors },
  } = useForm<EmpruntFormValues>({});

  const { ref: transactionAmountRef, ...transactionAmount } =
    immobRegister("transactionAmount");
  const { ref: transactionDateRef, ...transactionDate } =
    immobRegister("transactionDate");
  const { ref: numberOfYearsRef, ...numberOfYears } =
    immobRegister("numberOfYears");

  const { ref: capitalAmountRef, ...capitalAmount } =
    empruntRegister("capitalAmount");
  const { ref: chargeAmountRef, ...chargeAmount } =
    empruntRegister("chargeAmount");
  const { ref: insuranceAmountRef, ...insuranceAmount } =
    empruntRegister("insuranceAmount");

  const searhcCategs = async () => {
    try {
      if (!searchCategName) {
        return;
      }
      if (!closeCategSearch) {
        setCloseCategSearch(true);
      }

      if (categStatus === "dec") {
        const allSubs = decCategs.reduce(
          (acc, curr) => [...acc, ...(curr.sub_categories || [])],
          [] as ISubCategory[]
        );
        let found: ISubCategory[] = [];
        for (let elt of allSubs) {
          const exists = elt.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchCategName
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")!
            );

          if (exists) {
            found.push(elt);
          }
        }
        setDecCategs(
          found.length > 0
            ? [{ ...decCategs[0], sub_categories: found }]
            : decCategs
        );
      }

      if (categStatus === "enc") {
        for (let elt of encCategs) {
          if (elt.sub_categories) {
            const exists: ISubCategory[] = elt.sub_categories?.filter((elt) =>
              elt.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  searchCategName
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")!
                )
            );
            if (exists.length > 0) {
              setEncCategs((prevState) => [
                {
                  ...prevState[0],
                  sub_categories:
                    exists.length > 0 ? exists : prevState[0].sub_categories,
                },
              ]);
            }
          }
        }
      }
    } catch (error: any) {
      ErrorLogger("searching categs", error);
    }
  };

  const editTransactionVat = async (transaction: ITransaction) => {
    try {
      if (payDiffTrans && payDiffTrans.id) {
        await api.post(
          `/api/Transaction/Update`,
          {
            id: transaction.id,
            vat:
              transaction.amount > 0
                ? Math.abs(Number(singlePayDiffTVA?.value))
                : -Math.abs(Number(singlePayDiffTVA?.value)),
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        await getPayDiffTransactions(payDiffTrans?.id!);
        setSinglePayDiffTVA(null);
        setPayDiffEditTVAClick((prevState: any) => {
          return {
            ...prevState,
            [transaction.id]: false,
          };
        });
      } else {
        await api.post(
          `/api/Transaction/Update`,
          {
            id: transaction.id,
            vat:
              transaction.amount > 0
                ? Math.abs(Number(singleTVA?.value))
                : -Math.abs(Number(singleTVA?.value)),
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        await getTransactions();
        setSingleTVA(null);
        setEditTVAClick((prevState: any) => {
          return {
            ...prevState,
            [transaction.id]: false,
          };
        });
      }
    } catch (error: any) {
      ErrorLogger("updating transaction tva", error);
    }
  };

  const editImmobTransaction: SubmitHandler<ImmobFormValues> = async (
    form: ImmobFormValues
  ) => {
    try {
      setLoading(true);
      if (
        !form.numberOfYears ||
        !form.transactionAmount ||
        !form.transactionDate ||
        !form.type
      ) {
        setErrorMessage({
          type: "invalid_immob_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }
      const chosenType = form.type.value;

      const immobilisations = decCategs.find(
        (elt) => elt.name === "Immobilisations"
      )?.sub_categories;

      if (immobilisations) {
        const chosenSubCateg = immobilisations.find(
          (elt) => elt.id === chosenType
        );

        if (
          (Array.isArray(chosenSubCateg?.yearsRange) &&
            chosenSubCateg?.yearsRange.length! > 1 &&
            (Number(form.numberOfYears) < chosenSubCateg?.yearsRange[0]! ||
              Number(form.numberOfYears) > chosenSubCateg?.yearsRange[1]!)) ||
          (Array.isArray(chosenSubCateg?.yearsRange) &&
            chosenSubCateg?.yearsRange.length == 1 &&
            String(chosenSubCateg?.yearsRange[0]) !==
              String(form.numberOfYears))
        ) {
          setErrorMessage({
            type: "invalid_immob_form",
            message:
              "Veuillez choisir un nombre d'années correspondant au type choisi",
          });
          setLoading(false);
          return;
        }
        await api.post(
          `/api/Transaction/Update`,
          {
            id: singleImmob?.id,
            assetType: chosenType,
            createAsset: true,
            numberOfYears: Number(form.numberOfYears),
            status: 101,
            amount: -Math.abs(form.transactionAmount),
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      setImmob(null);
      immobReset();
      await getTransactions();
      setLoading(false);
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans.id);
      }
      setImmobModal(false);
      setErrorMessage(null);
    } catch (error: any) {
      ErrorLogger("updating transaction immob", error);
    }
  };

  const editEmpruntTransaction: SubmitHandler<EmpruntFormValues> = async (
    form: EmpruntFormValues
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);
      if (!form.capitalAmount && !form.insuranceAmount && !form.chargeAmount) {
        setErrorMessage({
          type: "invalid_emprunt_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }
      if (
        Math.abs(
          (parseFloat(String(form.capitalAmount)) || 0) +
            (parseFloat(String(form.insuranceAmount)) || 0) +
            (parseFloat(String(form.chargeAmount)) || 0)
        ) !== Math.abs(singleEmprunt?.amount!)
      ) {
        setErrorMessage({
          type: "invalid_emprunt_form",
          message:
            "Les montants proposés ne correspondent pas au montant de la transaction sélectionnée.",
        });
        return;
      }
      await api.post(
        `/api/Transaction/Update`,
        {
          id: singleEmprunt?.id,
          capitalAmount: form.capitalAmount || 0,
          chargeAmount: form.chargeAmount || 0,
          insuranceAmount: form.insuranceAmount || 0,
          status: 101,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSingleEmprunt(null);
      empruntReset();
      await getTransactions();
      setLoading(false);
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans.id);
      }
      setEmpruntModal(false);
      setErrorMessage(null);
    } catch (error: any) {
      ErrorLogger("updating transaction immob", error);
    }
  };

  const resetStates = () => {
    setCloseCategSearch(false);
    reset();
    setSearchCategName(null);
    setOpenCategModal(false);
    setCategremb(false);
    setSingleTrans(null);
    setIsImmobModal(false);
  };

  const immobCheckCallback = async (categ: ISubCategory) => {
    try {
      setIsImmobCateg(categ);
      await getCategories();
      setOpenCategModal(false);
      setIsImmobModal(true);
    } catch (error: any) {
      ErrorLogger("switching to immob", error);
    }
  };

  const carbCheckCallback = (transaction: ITransaction) => {
    try {
      setOpenCategModal(false);
      setIsCarbModal(true);
      setSingleCarb(transaction);
    } catch (error: any) {
      ErrorLogger("switching to immob", error);
    }
  };

  const editCarbTransaction = async (vehType: String) => {
    try {
      setCarbLoading(true);

      await api.post(
        `/api/Transaction/Update`,
        {
          id: singleCarb?.id,
          carbVehType: vehType,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getTransactions();
      setCarbLoading(false);
      setSingleCarb(null);
      setIsCarbModal(false);
    } catch (error: any) {
      ErrorLogger("updating transaction carb", error);
      setCarbLoading(false);
    }
  };

  const diffPayCheckCallback = async (transaction: ITransaction) => {
    try {
      setOpenCategModal(false);
      setPayDiffTrans(transaction);
      await getPayDiffTransactions(transaction.id);
      setDiffPayModal(true);
    } catch (error: any) {
      ErrorLogger("switching to diff pay", error);
    }
  };

  const diffPayCheckValidate = async (transaction: ITransaction) => {
    try {
      setPayDiffTrans(transaction);
      await getPayDiffTransactions(transaction.id);
      setDiffPayValidateModal(true);
    } catch (error: any) {
      ErrorLogger("switching to diff pay", error);
    }
  };

  const diffPayCheckArchive = async (transaction: ITransaction) => {
    try {
      setPayDiffTrans(transaction);
      (await getPayDiffTransactions(transaction.id)) as ITransaction[];
      setDiffPayArchiveModal(true);
    } catch (error: any) {
      ErrorLogger("switching to diff pay", error);
    }
  };

  const categCallback = async () => {
    await getCategories();
    await getTransactions();

    if (payDiffTrans && payDiffTrans.id) {
      await getPayDiffTransactions(payDiffTrans.id);
    }

    resetStates();
  };

  const { setTVA } = useTVA();

  const immobChange = async () => {
    try {
      if (singleTrans && isImmobCateg?.id) {
        let categArray = singleTrans.amount > 0 ? encCategs : decCategs;
        for (let elt of categArray) {
          const subCategs = elt.sub_categories;
          const found = subCategs?.find(
            (elt) => elt.id === isImmobCateg.redirectCategory_id
          );
          if (found) {
            await api.post(
              `/api/Transaction/Update`,
              {
                id: singleTrans?.id,
                subCategoryId: found?.id,
                category: found?.key,
                vat:
                  found?.TVA &&
                  !isNaN(parseFloat(found?.TVA)) &&
                  parseFloat(found?.TVA) !== 0
                    ? setTVA(singleTrans?.amount!, parseFloat(found?.TVA) / 100)
                    : null,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );
          }
        }
      }
      setRefreshCategs(false);
      setIsImmobCateg(null);
      await categCallback();
    } catch (error: any) {
      ErrorLogger("switching to immob", error);
    }
  };

  useEffect(() => {
    getTransactions();
    getCategories();
  }, []);

  useEffect(() => {
    if (reload) {
      getTransactions();
      getCategories();
    }
  }, [reload]);

  useEffect(() => {
    if (
      immobStateHandler?.isImmobFire &&
      immobStateHandler?.isImmobSubCateg &&
      immobStateHandler?.isImmobTrans
    ) {
      setSingleTrans(immobStateHandler?.isImmobTrans);
      immobCheckCallback(immobStateHandler?.isImmobSubCateg!);
      immobStateHandler?.setIsImmobFire(false);
    }
  }, [immobStateHandler]);

  useEffect(() => {
    searhcCategs();
  }, [searchCategName]);

  useEffect(() => {
    getTransactions();
  }, [currentPage]);

  const {
    watch: payDiff_watch,
    control: payDiff_control,
    register: payDiff_register,
    handleSubmit: payDiff_handleSubmit,
    reset: payDiff_reset,
    setValue: payDiff_setValue,
    formState: { errors: payDiff_errors },
    getValues,
  } = useForm<GeneralFormValues>({});

  const { document: _document } = payDiff_watch();

  const { ref: labelRef, ...label } = payDiff_register("label");
  const { ref: amountRef, ...amount } = payDiff_register("amount");
  const { ref: dateRef, ...date } = payDiff_register("date");
  const { ref: documentRef, ...document } = payDiff_register("document");

  const createTransaction: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      setErrorMessage(null);
      if (!form.label || !form.amount || !form.date) {
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }

      const formData = new FormData();

      formData.append("label", form.label as string);
      formData.append("status", 100 as any);
      formData.append("companyId", context as string);
      formData.append("date", moment(form.date).format("YYYY-MM-DD") as string);
      formData.append("medium", TransactionMediums.Manual);

      if (form.category) {
        formData.append("sub_category_id", form.category?.value as string);

        let subCateg = decCategs.find(
          (elt) => elt.id === form.category?.value
        )! as Partial<ISubCategory>;

        if (
          subCateg?.TVA &&
          !isNaN(parseFloat(subCateg?.TVA)) &&
          parseFloat(subCateg?.TVA) !== 0
        ) {
          formData.append(
            "vat",
            String(setTVA(form?.amount!, parseFloat(subCateg?.TVA) / 100))
          );
          formData.append("category", subCateg.key as string);
        }
      }

      formData.append("amount", -form.amount as any);
      formData.append("type", "expense");
      formData.append("diffPay_id", payDiffTrans?.id!);

      if (form.document && form.document.length) {
        for (let index = 0; index < form.document.length; index++) {
          const element = form.document[index];
          formData.append("document", element);
        }
      }

      const { data } = await api.post(`/api/Transaction/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setLoading(false);
      await getTransactions();
      await getPayDiffTransactions(payDiffTrans?.id!);
      restePayDiffForm();
      setAddNoteFraisModal(false);
    } catch (error: any) {
      ErrorLogger("transaction creation form", error);
      setLoading(false);
      setErrorMessage({
        type: "undexpected_issue",
        message:
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
      });
    }
  };

  const restePayDiffForm = () => {
    payDiff_reset({
      label: null,
      amount: null,
      date: null,
      category: null,
      document: null,
      modalType: null,
    });
    setErrorMessage(null);
  };

  return (
    <div className="toValide-transactions-table">
      <DataTable
        columns={columns}
        data={transactionsToValid}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        paginationComponent={(props) => {
          const customProps = { ...props, color: "primary" };
          return <BootyPagination {...customProps} />;
        }}
        paginationServer
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        paginationPerPage={20}
      />
      <div className="openbtn text-center">
        {/*
        transaction deletion modal 
        */}
        <Modal
          isOpen={open}
          toggle={() => {
            setTransactionId(null);
            setOpen(false);
          }}
          className="modal-danger modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setTransactionId(null);
              setOpen(false);
            }}
          >
            Supprimer une transaction
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer la transaction{" "}
                {
                  transactionsToValid.find((elt) => elt.id === transactionId)
                    ?.label
                }
                ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setTransactionId(null);
                setOpen(false);
              }}
            >
              Non
            </Button>
            <Button color="danger" onClick={() => deleteTransaction()}>
              Oui
            </Button>
          </ModalFooter>
        </Modal>

        {/*
         stop archive modal 
        */}
        <Modal
          isOpen={stopArchiveModal}
          toggle={() => {
            setStopArchiveModal(false);
          }}
          className="modal-warning modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setStopArchiveModal(false);
            }}
          >
            Transaction non catégorisée
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                On ne peut pas archiver une transaction non catégorisée
              </p>
            </div>
          </ModalBody>
        </Modal>

        {/*
        transaction rule modal 
        */}
        <Modal
          isOpen={isImmobModal}
          toggle={async () => {
            await categCallback();
            setTransactionId(null);
            setIsImmobModal(false);
          }}
          className="modal-warning modal-dialog-centered"
        >
          <ModalHeader
            toggle={async () => {
              await categCallback();
              setTransactionId(null);
              setIsImmobModal(false);
            }}
          >
            Vérification de la catégorisation
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Cette transaction est-elle une immobilisation ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              onClick={async () => {
                await categCallback();
                setTransactionId(null);
                setIsImmobModal(false);
              }}
            >
              Non
            </Button>
            <Button color="warning" onClick={async () => await immobChange()}>
              Oui
            </Button>
          </ModalFooter>
        </Modal>

        {/*
        transaction carburant modal 
        */}
        <Modal
          isOpen={isCarbModal}
          toggle={() => {
            setSingleCarb(null);
            setIsCarbModal(false);
          }}
          className="modal-warning modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setSingleCarb(null);
              setIsCarbModal(false);
            }}
          >
            Quel est le type de véhicule ?
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5 carb-modal">
              <div className="content-text carb-modal-wrapper">
                <div className="card util-veh-card">
                  <img src={UtilVeh} alt="Véhicule utilitaire" />
                  <Button
                    onClick={async () =>
                      await editCarbTransaction(CarbVehTypes.Utility)
                    }
                    color="secondary"
                  >
                    Véhicule utilitaire
                  </Button>
                </div>
                <div className="card tour-veh-card">
                  <img src={TourVeh} alt="Véhicule de tourisme" />
                  <Button
                    onClick={async () =>
                      await editCarbTransaction(CarbVehTypes.Tourisme)
                    }
                    color="primary"
                  >
                    Véhicule de tourisme
                  </Button>
                </div>
              </div>

              <div className="carb-modal-nb">
                <div className="icon-col">
                  <FiInfo />
                </div>
                <div className="text-col">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  maximus euismod cursus. Vivamus commodo eros vitae ipsum
                  luctus, vitae condimentum nisi bibendum.
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/*
        transaction archive no file 
        */}
        <Modal
          isOpen={noFilesModal}
          toggle={() => {
            setNoFilesModal(false);
            setNoFilesTrans(null);
            setNoFilesLoading(false);
          }}
          className="modal-warning modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setNoFilesModal(false);
              setNoFilesTrans(null);
              setNoFilesLoading(false);
            }}
          >
            Vérification
          </ModalHeader>
          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                {(noFilesTrans?.sub_category.subType === "Charges" ||
                  noFilesTrans?.sub_category.subType ===
                    "Chiffre d'affaires") &&
                noFilesTrans?.sub_category.key !== "OUT-110"
                  ? "Un justificatif doit être fourni pour ce type d’opérations. En cas de contrôle, vous devrez être en mesure de fournir toutes les pièces nécessaires."
                  : "Cette transaction n'a pas de justificatif, voulez-vous continuer ?"}
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              disabled={noFilesLoading}
              onClick={() => {
                setNoFilesModal(false);
                setNoFilesTrans(null);
                setNoFilesLoading(false);
              }}
            >
              Annuler
            </Button>
            <Button
              color="warning"
              disabled={noFilesLoading}
              onClick={async () => {
                try {
                  setNoFilesLoading(true);
                  await archiveTransaction(noFilesTrans?.id!);
                  setNoFilesModal(false);
                  setNoFilesTrans(null);
                  setNoFilesLoading(false);
                } catch (error) {
                  console.error(error);
                  setNoFilesModal(false);
                  setNoFilesTrans(null);
                  setNoFilesLoading(false);
                }
              }}
            >
              {(noFilesTrans?.sub_category.subType === "Charges" ||
                noFilesTrans?.sub_category.subType === "Chiffre d'affaires") &&
              noFilesTrans?.sub_category.key !== "OUT-110"
                ? "J’ai compris"
                : "Oui"}
            </Button>
          </ModalFooter>
        </Modal>

        {/*
        décaissements / encaissements modal 
        */}
        <Modal
          className="modal-primary modal-dialog-centered modal-lg"
          isOpen={openCategModal}
          toggle={async () => {
            await categCallback();
          }}
        >
          <ModalHeader
            toggle={async () => {
              await categCallback();
            }}
          >
            {categStatus && categStatus === "dec"
              ? "Décaissements"
              : "Encaissements"}
          </ModalHeader>
          <ModalBody>
            <div
              className={`content-form-block ${
                categStatus && categStatus === "dec"
                  ? "decaissements-block"
                  : ""
              }`}
            >
              <div className="top-chois-form">
                <Row>
                  <Col md={6} sm={12} className="left-label">
                    <Label className="label-primary">
                      Est-ce un remboursement ?
                    </Label>
                  </Col>
                  <Col md={6} sm={12}>
                    <div className="form-line">
                      <div className="check-box check-primary">
                        <input
                          type="radio"
                          id="test2"
                          name="radio-group"
                          onClick={() => setCategremb(true)}
                        />
                        <label htmlFor="test2">Oui</label>
                      </div>
                      <div className="check-box check-primary">
                        <input
                          type="radio"
                          id="test3"
                          name="radio-group"
                          onClick={() => setCategremb(false)}
                          defaultChecked={true}
                        />
                        <label htmlFor="test3">Non</label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <form onSubmit={handleSubmit(searhcCategs)}> */}
              <FormGroup className="form-icon icon-end">
                <Input
                  // id="mc"
                  // innerRef={nameRef}
                  // {...name}
                  value={searchCategName || ""}
                  type="text"
                  className="form-default"
                  onChange={(event) => {
                    setSearchCategName(event.target.value);
                  }}
                />
                <button
                  className="icon icon-primary"
                  onClick={async () => {
                    if (closeCategSearch) {
                      await getCategories();
                      setCloseCategSearch(false);
                      setSearchCategName("");
                    }
                  }}
                >
                  {closeCategSearch ? <RiCloseLine /> : <RiSearchLine />}
                </button>
              </FormGroup>
              {/* </form> */}
              {!categRemb ? (
                <>
                  {decCategs.length > 0 && encCategs.length > 0 ? (
                    <>
                      {categStatus === "dec" ? (
                        <Decaissements
                          categs={decCategs}
                          transaction={singleTrans}
                          callback={async () => await categCallback()}
                          immobCheckCallback={immobCheckCallback}
                          carbCheckCallback={carbCheckCallback}
                          diffPayCheckCallback={diffPayCheckCallback}
                          setRefreshCategs={setRefreshCategs}
                        />
                      ) : (
                        <Encaissements
                          categs={encCategs}
                          transaction={singleTrans}
                          callback={async () => await categCallback()}
                          setRefreshCategs={setRefreshCategs}
                        />
                      )}
                    </>
                  ) : (
                    <Spinner color="success" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  )}
                </>
              ) : (
                <>
                  {decCategs.length > 0 && encCategs.length > 0 ? (
                    <>
                      {categStatus === "enc" ? (
                        <Decaissements
                          categs={decCategs}
                          transaction={singleTrans}
                          callback={async () => await categCallback()}
                          immobCheckCallback={immobCheckCallback}
                          carbCheckCallback={carbCheckCallback}
                          diffPayCheckCallback={diffPayCheckCallback}
                          setRefreshCategs={setRefreshCategs}
                        />
                      ) : (
                        <Encaissements
                          categs={encCategs}
                          transaction={singleTrans}
                          callback={async () => await categCallback()}
                          setRefreshCategs={setRefreshCategs}
                        />
                      )}
                    </>
                  ) : (
                    <Spinner color="success" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  )}
                </>
              )}
            </div>
          </ModalBody>
        </Modal>

        {/*
        add immob 
        */}
        <Modal
          className="modal-secondary modal-dialog-centered "
          isOpen={immbModal}
          toggle={() => {
            setImmob(null);
            immobReset();
            setErrorMessage(null);
            setImmobModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setImmob(null);
              immobReset();
              setErrorMessage(null);
              setImmobModal(false);
            }}
          >
            Immobilisation
          </ModalHeader>
          <form onSubmit={immobHandleSubmit(editImmobTransaction)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Type d’immobilisation</Label>
                      <Controller
                        name="type"
                        control={immobControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={
                              decCategs.find(
                                (elt) => elt.name === "Immobilisations"
                              )
                                ? decCategs
                                    .find(
                                      (elt) => elt.name === "Immobilisations"
                                    )
                                    ?.sub_categories?.reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          value: curr.id,
                                          label: `${curr.name} ${
                                            curr.yearsRange.length > 1
                                              ? `(${curr.yearsRange[0]} à ${curr.yearsRange[1]} ans)`
                                              : `(${curr.yearsRange[0]} ans)`
                                          }`,
                                        },
                                      ],
                                      [] as OptionType[]
                                    )
                                : ASSETS_OPTIONS
                            }
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <div
                      className="asset-type-info"
                      onClick={() => {
                        setImmobMoreInfo(true);
                      }}
                    >
                      <RiErrorWarningLine />
                      <span>
                        Plus d'informations sur les types d'immobilisation
                      </span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="numberOfYears">
                        Nombre d'années (durée d'utilisation)
                      </Label>
                      <Input
                        id="label"
                        innerRef={numberOfYearsRef}
                        {...numberOfYears}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                        min={0}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <RiTimeFill />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="transactionAmount">
                        Le montant de l’immobilisation
                      </Label>
                      <Input
                        id="transactionAmount"
                        innerRef={transactionAmountRef}
                        {...transactionAmount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary"
                        onChange={() => setErrorMessage(null)}
                        step={0.01}
                        min={0}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <img src={MoneySacIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="transactionDate">Date d'aquisition</Label>
                      <Controller
                        control={immobControl}
                        name="transactionDate"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => {
                              field.onChange(date);
                              setErrorMessage(null);
                            }}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="transactionDate"
                        innerRef={transactionDateRef}
                        {...transactionDate}
                        type="date"
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      /> */}
                      <span className="icon icon-secondary icon-secondary-fill">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  {errorMessage?.type === "invalid_immob_form" && (
                    <Col md={12}>
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    </Col>
                  )}
                </Row>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="secondary"
                outline
                onClick={() => {
                  setImmob(null);
                  immobReset();
                  setErrorMessage(null);
                  setImmobModal(false);
                }}
                type="button"
              >
                Annuler
              </Button>
              <Button color="secondary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/*
        add emprunt data
        */}
        <Modal
          className="modal-secondary modal-dialog-centered "
          isOpen={empruntModal}
          toggle={() => {
            setSingleEmprunt(null);
            empruntReset();
            setErrorMessage(null);
            setEmpruntModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleEmprunt(null);
              empruntReset();
              setErrorMessage(null);
              setEmpruntModal(false);
            }}
          >
            Emprunt
          </ModalHeader>
          <form onSubmit={empruntHandleSubmit(editEmpruntTransaction)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <div
                      className="asset-type-info"
                      onClick={() => {
                        setempruntMoreInfo(true);
                      }}
                    >
                      <RiErrorWarningLine />
                      <span>Plus d'informations sur votre Emprunt</span>
                    </div>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="capitalAmount">Capital remboursé</Label>
                      <Input
                        id="label"
                        innerRef={capitalAmountRef}
                        {...capitalAmount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        step={0.01}
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                        min={0}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <img src={MoneySacIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="chargeAmount">Intérêts</Label>
                      <Input
                        id="chargeAmount"
                        innerRef={chargeAmountRef}
                        {...chargeAmount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary"
                        onChange={() => setErrorMessage(null)}
                        step={0.01}
                        min={0}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <img src={MoneySacIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="insuranceAmount">Assurances</Label>
                      <Input
                        id="insuranceAmount"
                        innerRef={insuranceAmountRef}
                        {...insuranceAmount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        className="form-secondary"
                        onChange={() => setErrorMessage(null)}
                        step={0.01}
                        min={0}
                      />
                      <span className="icon icon-secondary icon-secondary-fill">
                        <img src={MoneySacIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>

                  {errorMessage?.type === "invalid_emprunt_form" && (
                    <Col md={12}>
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    </Col>
                  )}
                </Row>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="secondary"
                outline
                onClick={() => {
                  setSingleEmprunt(null);
                  empruntReset();
                  setErrorMessage(null);
                  setEmpruntModal(false);
                }}
                type="button"
              >
                Annuler
              </Button>
              <Button color="secondary" type="submit">
                Valider
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/*
        add diff pay data
        */}
        <Modal
          className="modal-primary modal-dialog-centered pay-diff-modal"
          isOpen={diffPayModal}
          toggle={async () => {
            setPayDiffTrans(null);
            setErrorMessage(null);
            setDiffPayModal(false);
            if (
              payDiffTransArray.length > 0 &&
              payDiffTransArray.reduce(
                (acc, curr) =>
                  parseFloat(String(acc)) + parseFloat(String(curr.amount)),
                0
              ) !== parseFloat(String(payDiffTrans?.amount))
            ) {
              await deletePayDiffTransaction(payDiffTrans?.id!);
            }
          }}
        >
          <ModalHeader
            toggle={async () => {
              setPayDiffTrans(null);
              setErrorMessage(null);
              setDiffPayModal(false);
              setPAyDiffTransArray([]);
              if (
                payDiffTransArray.length > 0 &&
                payDiffTransArray.reduce(
                  (acc, curr) =>
                    parseFloat(String(acc)) + parseFloat(String(curr.amount)),
                  0
                ) !== parseFloat(String(payDiffTrans?.amount))
              ) {
                await deletePayDiffTransaction(payDiffTrans?.id!);
              }
            }}
          >
            Paiements différés
          </ModalHeader>
          <ModalBody>
            <div className="content-form-block">
              <Row className="text-center">
                <div>
                  <h5>
                    Le montant de votre transaction {payDiffTrans?.label} :{" "}
                    <strong>{payDiffTrans?.amount}</strong> €
                  </h5>
                </div>
              </Row>
              <Row className="mt-3">
                <DataTable
                  columns={diffPayColumns}
                  data={payDiffTransArray}
                  noDataComponent={<p>Il n'y a pas encore de transactions</p>}
                  pagination
                  paginationComponent={(props) => {
                    const customProps = { ...props, color: "primary" };
                    return <BootyPagination {...customProps} />;
                  }}
                  persistTableHead={true}
                />
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              outline
              onClick={async () => {
                setPayDiffTrans(null);
                setErrorMessage(null);
                setDiffPayModal(false);
                setPAyDiffTransArray([]);
                if (
                  payDiffTransArray.length > 0 &&
                  payDiffTransArray.reduce(
                    (acc, curr) =>
                      parseFloat(String(acc)) + parseFloat(String(curr.amount)),
                    0
                  ) !== parseFloat(String(payDiffTrans?.amount))
                ) {
                  await deletePayDiffTransaction(payDiffTrans?.id!);
                }
              }}
              type="button"
            >
              Annuler
            </Button>
            {payDiffTransArray.length > 0 &&
            payDiffTransArray.reduce(
              (acc, curr) =>
                parseFloat(String(acc)) + parseFloat(String(curr.amount)),
              0
            ) === parseFloat(String(payDiffTrans?.amount)) ? (
              <Button
                color="primary"
                type="button"
                onClick={async () => {
                  setPayDiffTrans(null);
                  setErrorMessage(null);
                  setDiffPayModal(false);
                  setPAyDiffTransArray([]);
                  await getTransactions();
                }}
              >
                Ajouter
              </Button>
            ) : (
              <Button
                color="primary"
                type="button"
                onClick={() => setAddNoteFraisModal(true)}
              >
                Ajouter une transaction
              </Button>
            )}
          </ModalFooter>
        </Modal>

        {/*
        validate diff pay data
        */}
        <Modal
          className="modal-primary modal-dialog-centered pay-diff-modal"
          isOpen={diffPayValidateModal}
          toggle={async () => {
            setPayDiffTrans(null);
            setErrorMessage(null);
            setDiffPayValidateModal(false);
            setPayDiffValidation(false);
            setPayDiffNeddValidation({});
          }}
        >
          <ModalHeader
            toggle={async () => {
              setPayDiffTrans(null);
              setErrorMessage(null);
              setDiffPayValidateModal(false);
              setPayDiffValidation(false);
              setPayDiffNeddValidation({});
            }}
          >
            Validation des paiements différés
          </ModalHeader>

          <ModalBody>
            <div className="content-form-block">
              <Row className="text-center">
                <div>
                  {!payDiffTransArray.every((elt) => elt.subCategoryId) ? (
                    <h5>
                      Vous devez catégoriser toutes les transactions afin de les
                      valider
                    </h5>
                  ) : (
                    <h5>
                      Vous allez valider la transaction {payDiffTrans?.label} :{" "}
                      <strong>{payDiffTrans?.amount}</strong> € et chaque
                      transaction qui lui est associée
                    </h5>
                  )}
                </div>
              </Row>
              <Row className="mt-3">
                <DataTable
                  columns={diffPayColumns}
                  data={payDiffTransArray}
                  noDataComponent={<p>Il n'y a pas encore de transactions</p>}
                  pagination
                  paginationComponent={(props) => {
                    const customProps = { ...props, color: "primary" };
                    return <BootyPagination {...customProps} />;
                  }}
                />
              </Row>
              <Row className="text-center">
                <div>
                  {errorMessage?.type === "payDiff_need_validation" && (
                    <span className="text-center text-danger">
                      {errorMessage?.message}
                    </span>
                  )}
                </div>
              </Row>
            </div>
          </ModalBody>
          {payDiffTransArray.every((elt) => elt.subCategoryId) && (
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={async () => {
                  setPayDiffTrans(null);
                  setErrorMessage(null);
                  setDiffPayValidateModal(false);
                  setPayDiffValidation(false);
                  setPayDiffNeddValidation({});
                }}
                type="button"
                disabled={payDiffValidation}
              >
                Annuler
              </Button>
              {payDiffTransArray.length > 0 &&
              payDiffTransArray.reduce(
                (acc, curr) =>
                  parseFloat(String(acc)) + parseFloat(String(curr.amount)),
                0
              ) === parseFloat(String(payDiffTrans?.amount)) ? (
                <Button
                  color="primary"
                  type="button"
                  disabled={payDiffValidation}
                  onClick={async () => {
                    await validatePayDiffTransactions();
                  }}
                >
                  {payDiffValidation ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Valider"
                  )}
                </Button>
              ) : (
                <Button
                  color="primary"
                  type="button"
                  onClick={() => setAddNoteFraisModal(true)}
                >
                  Ajouter une transaction
                </Button>
              )}
            </ModalFooter>
          )}
        </Modal>

        {/*
        archive diff pay data
        */}
        <Modal
          className="modal-primary modal-dialog-centered pay-diff-modal"
          isOpen={diffPayArchiveModal}
          toggle={async () => {
            setPayDiffTrans(null);
            setErrorMessage(null);
            setDiffPayArchiveModal(false);
            setPayDiffArchive(false);
            setPayDiffNeddValidation({});
          }}
        >
          <ModalHeader
            toggle={async () => {
              setPayDiffTrans(null);
              setErrorMessage(null);
              setDiffPayArchiveModal(false);
              setPayDiffArchive(false);
              setPayDiffNeddValidation({});
            }}
          >
            Archiver des paiements différés
          </ModalHeader>
          <ModalBody>
            <div className="content-form-block">
              <Row className="text-center">
                <div>
                  {!payDiffTransArray.every((elt) => elt.subCategoryId) ? (
                    <h5>
                      Vous devez catégoriser toutes les transactions afin de les
                      archiver
                    </h5>
                  ) : (
                    <>
                      {!payDiffTransArray.every((elt) => elt.document) ? (
                        <h5>
                          Certaines transactions n'ont pas de justificatifs,
                          voulez-vous continuer ?
                        </h5>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </Row>
              <Row className="mt-3">
                <DataTable
                  columns={diffPayColumns}
                  data={payDiffTransArray}
                  noDataComponent={<p>Il n'y a pas encore de transactions</p>}
                  pagination
                  paginationComponent={(props) => {
                    const customProps = { ...props, color: "primary" };
                    return <BootyPagination {...customProps} />;
                  }}
                />
              </Row>
            </div>
          </ModalBody>
          {payDiffTransArray.every((elt) => elt.subCategoryId) && (
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={async () => {
                  setPayDiffTrans(null);
                  setErrorMessage(null);
                  setDiffPayArchiveModal(false);
                  setPayDiffArchive(false);
                  setPayDiffNeddValidation({});
                }}
                type="button"
                disabled={payDiffArchive}
              >
                Annuler
              </Button>
              <Button
                color="primary"
                type="button"
                disabled={payDiffArchive}
                onClick={async () => {
                  await archiveTransaction(payDiffTrans?.id!);
                  setPayDiffTrans(null);
                  setErrorMessage(null);
                  setDiffPayArchiveModal(false);
                  setPayDiffArchive(false);
                  setPayDiffNeddValidation({});
                }}
              >
                {payDiffArchive ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Archiver"
                )}
              </Button>
            </ModalFooter>
          )}
        </Modal>

        {/*
        more info immob
        */}
        <Modal
          className="modal-secondary modal-dialog-centered "
          isOpen={immobMoreInfo}
          toggle={() => {
            setImmobMoreInfo(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setImmobMoreInfo(false);
            }}
          >
            Type d’immobilisation et durée d’amortissement
          </ModalHeader>

          <ModalBody>
            <div className="content-form-block">
              <div className="container">
                <div className="section-information-societe">
                  <h3>Durée préconisée par le plan comptable général</h3>
                  <ul>
                    <li>Bâtiments commerciaux (20 à 50 ans)</li>
                    <li>Bâtiments industriels (20 ans)</li>
                    <li>Bureaux 25 ans</li>
                    <li>Immeubles d'habitation (40 à 100 ans)</li>
                    <li>Entrepôts (20 ans)</li>
                    <li>Maçonnerie (15 ans)</li>
                    <li>Agencements de bureaux (10 ans)</li>
                    <li>Agencement léger (5 à 6,5 ans)</li>
                    <li>Peintures, papiers-peints (3 à 5 ans)</li>
                    <li>Revêtements de sols (5 ans)</li>
                    <li>Mobilier de magasin (10 ans)</li>
                    <li>Gros travaux dans locaux (5 à 10 ans)</li>
                    <li>Matériel et outillage</li>
                    <ul>
                      <li>Matériel (6,5 à 10 ans)</li>
                      <li>Outillage (5 à 10 ans)</li>
                    </ul>
                    <li>Matériel de bureau</li>
                    <ul>
                      <li>Mobilier (10 ans)</li>
                      <li>Photocopieur (5 ans)</li>
                      <li>Matériel électrique (6,5 à 10 ans)</li>
                      <li>Téléphone, répondeur (3 ans 3 ans)</li>
                      <li>Magnétoscope 5 ans</li>
                    </ul>
                    <li>Informatique</li>
                    <ul>
                      <li>Ordinateur (3 à 5 ans 3 à 5 ans)</li>
                      <li>Logiciels (1 à 3 ans)</li>
                      <li>Automobiles (4 à 5 ans)</li>
                    </ul>
                    <li>Biens incorporels</li>
                    <ul>
                      <li>Brevets (5 ans)</li>
                      <li>Dessins durée d'exploitation</li>
                      <li>Autres durées des avantages procurés</li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/*
        more info emprunt
        */}
        <Modal
          className="modal-secondary modal-dialog-centered "
          isOpen={empruntMoreInfo}
          toggle={() => {
            setempruntMoreInfo(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setempruntMoreInfo(false);
            }}
          >
            Emprunt
          </ModalHeader>

          <ModalBody>
            <div className="content-form-block">
              <div className="container">
                <div className="section-information-societe">
                  <p>
                    Les informations requises se trouvent dans votre tableau de
                    remboursement fourni par l’établissement bancaire auprès
                    duquel vous avez contracté votre emprunt
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/*add pay diff transaction*/}
        <Modal
          className="modal-primary modal-dialog-centered"
          isOpen={addNoteFraisModal}
          toggle={() => {
            setAddNoteFraisModal(false);
            restePayDiffForm();
          }}
        >
          <ModalHeader
            toggle={() => {
              setAddNoteFraisModal(false);
              restePayDiffForm();
            }}
          >
            Ajouter une transaction
          </ModalHeader>
          <form onSubmit={payDiff_handleSubmit(createTransaction)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="label">Libellé</Label>
                      <Input
                        id="label"
                        innerRef={labelRef}
                        {...label}
                        type="text"
                        className="form-primary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-primary icon-primary-fill">
                        <img src={CalenderIconGreen} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Date</Label>
                      <Controller
                        control={payDiff_control}
                        name="date"
                        render={({ field }) => (
                          <DatePicker
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-primary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                          id="date"
                          innerRef={dateRef}
                          {...date}
                          type="date"
                          className="form-primary"
                        /> */}
                      <span className="icon icon-primary icon-primary-fill">
                        <img src={CalenderIconGreen} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Catégorie</Label>
                      <Controller
                        name="category"
                        control={payDiff_control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={decCategs
                              .reduce(
                                (acc, curr) => [
                                  ...acc,
                                  ...(curr.sub_categories || []),
                                ],
                                [] as ISubCategory[]
                              )
                              .filter(
                                (elt) =>
                                  elt.key !== PayDiffKeys.OUT100 &&
                                  elt.key !== PayDiffKeys.OUT101
                              )
                              .reduce(
                                (acc, curr) => [
                                  ...acc,
                                  { value: curr.id, label: curr.name },
                                ],
                                [] as { value: string; label: string }[]
                              )}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-primary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="amount">Montant</Label>
                      <Input
                        id="amount"
                        innerRef={amountRef}
                        {...amount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        step={0.01}
                        className="form-primary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                        min={0}
                      />
                      <span className="icon icon-primary icon-primary-fill">
                        <img src={CalenderIconGreen} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <div className="form-icon icon-start form-file file-primary mt-4">
                        <span className="label-file">
                          Déposez le fichier ici
                        </span>
                        <label className="file-box ">
                          <RiDownload2Fill />
                          <Input
                            id="document"
                            innerRef={documentRef}
                            {...document}
                            className="form-default"
                            type="file"
                          />
                          <span> Télécharger d'ici</span>
                        </label>
                      </div>
                    </FormGroup>
                    <div className="list-Files">
                      {_document && _document[0] && (
                        <span className="file-box-item">
                          <AttacheIcon />
                          <pre>{_document[0].name}</pre>
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                {errorMessage?.type === "invalid_form" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
                {errorMessage?.type === "undexpected_issue" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setAddNoteFraisModal(false);
                  restePayDiffForm();
                }}
                type="button"
              >
                Annuler
              </Button>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  payDiff_setValue("modalType", "negative");
                }}
              >
                Ajouter
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default TransactionsAvalider;
