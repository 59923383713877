import React, { useEffect, useState } from "react";
import "./topnav.scss";
import { RiNotification4Line } from "react-icons/ri";
import Dropdown from "../DropdownMenu/DropdownMenu";
import { data } from "../../constants";
import { Link, useOutletContext } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import CrownIcon from "../../assets/CrownIcon";
import FileViewer from "../FileViewer";
import { useLocation } from "react-router-dom";
import { UserTypes } from "../../util/context";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../util/hooks/useAxios";
import { Company, CountNotif, User } from "../../interfaces";
import { ErrorLogger } from "../../util/errorLogger";
import {
  setErrorMessage,
  setReloadTickets,
} from "../../store/reducers/utils/utilSlice";
import { toast, ToastContainer } from "react-toastify";
import FilePicker from "../FilePicker";
import { deleteFile } from "../../pages/ClientInfo/InformationClient";
import { Socket } from "socket.io-client";

type CreateTicketFormValues = {
  text?: string | null;
};

const TopNav = ({ socket }: { socket?: any }) => {
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [createTicketModal, setCreateTicketModal] = useState<boolean>(false);
  const [attachement, setAttachement] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [stateErrorMessage, setStateErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  let api = useAxios();

  const location = useLocation();

  const {
    watch,
    control: createControl,
    register: createRegister,
    handleSubmit: createHandleSubmit,
    reset: createReset,
    formState: { errors: createErrors },
  } = useForm<CreateTicketFormValues>({});

  // create ticket form
  const { ref: textRef, ...text } = createRegister("text");

  const resetTicketCreation = () => {
    createReset({
      text: null,
    });
    setAttachement(null);
  };

  const dispatch = useDispatch();

  const createTicket: SubmitHandler<CreateTicketFormValues> = async (
    form: CreateTicketFormValues
  ) => {
    try {
      setErrorMessage(null);
      if (!form.text) {
        setStateErrorMessage({
          type: "not_valid",
          message: "Veuillez remplir tous les champs nécessaires du formulaire",
        });
        return;
      }
      setLoading(true);
      let payload: any = {
        company_id: creds.company_id,
        user_id: creds.user_id,
        status: 106,
        messages: {
          text: form.text,
          user_id: creds.user_id,
        },
      };

      const { data } = await api.post(`/api/Ticket/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.id && attachement) {
        const messageFormData = new FormData();
        messageFormData.append("id", data.messages[0].id);
        messageFormData.append("attachement", attachement);

        await api.post(`/api/Message/Update`, messageFormData, {
          headers: {
            "x-access-token": creds.token,
          },
        });
      }

      if (socket.socket) {
        await socket.socket.current.emit("new-ticket", data.messages[0]);
      }

      resetTicketCreation();
      setLoading(false);
      setCreateTicketModal(false);
      dispatch(setReloadTickets(true));
      if (data.id) {
        toast.success("Votre ticket a été créé avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("creating email", error);
      resetTicketCreation();
      setLoading(false);
      setCreateTicketModal(false);
      dispatch(setReloadTickets(true));
    }
  };

  const handleAttachementChange = (event: { target: { files: any } }) => {
    setAttachement(event.target.files![0]);
  };

  return (
    <>
      <ToastContainer />
      <div className="topnav">
        {location.pathname.includes("tickets") &&
          creds.role === UserTypes.Client && (
            <button
              type="button"
              className="btn btn-primary create-button"
              onClick={() => setCreateTicketModal(true)}
            >
              <CrownIcon />
              Créer un ticket
            </button>
          )}

        <div
          className={`topnav__right-item ${
            creds.role !== UserTypes.Client ? "notif-drop-wrapper" : ""
          }`}
        >
          <Dropdown icon={<RiNotification4Line />} />
        </div>
      </div>
      <div className="openbtn text-center">
        {/*create ticket modal*/}
        <Modal
          className="modal-primary modal-dialog-centered "
          isOpen={createTicketModal}
          toggle={() => {
            setCreateTicketModal(false);
            resetTicketCreation();
            setErrorMessage(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCreateTicketModal(false);
              resetTicketCreation();
              setErrorMessage(null);
            }}
          >
            Créer un nouveau ticket
          </ModalHeader>
          <form onSubmit={createHandleSubmit(createTicket)}>
            <ModalBody>
              <div className="content-form-block">
                <FormGroup className="form-icon icon-end">
                  <Row className="align-items-center">
                    <Col lg={12} md={12} className="label-line">
                      <Label for="subject">Votre message</Label>
                    </Col>
                    <Col lg={12} sm={12} className="label-line">
                      <Input
                        id="subject"
                        innerRef={textRef}
                        {...text}
                        type="textarea"
                        className="form-primary text-area-custom ticket-creation-textarea"
                        rows="15"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="proofOfIdentity">Pièce jointe</Label>
                  <FilePicker
                    className="form-icon icon-start form-file file-primary"
                    onChange={handleAttachementChange}
                    state={attachement}
                    fileStagedDelete={() => setAttachement(null)}
                    setUrl={setUrl}
                    setViewModal={setViewModal}
                  />
                </FormGroup>
                {stateErrorMessage?.type === "not_valid" && (
                  <Alert color="danger">{stateErrorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setCreateTicketModal(false);
                  resetTicketCreation();
                  setErrorMessage(null);
                }}
              >
                Annuler
              </Button>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Ouvrir un ticket"
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default TopNav;
