import React, { useEffect, useState } from "react";

import "./GestionDocuments.scss";

import { data } from "../../../constants";
import PdfIcons from "../../../assets/images/svg/pdf-icon.svg";
import {
  Card,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Company, IS3, User } from "../../../interfaces";
import FileViewer from "../../../components/FileViewer";
import LetterMission from "../../ClientInfo/LetterMission";
import { toast, ToastContainer } from "react-toastify";
import OtherDocs from "../../../components/OtherDocs";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../util/hooks/useAxios";
import { fetchAllCompanies } from "../../../store/reducers/companies-list/companiesSlice";
import { GedDocTypes, UserTypes } from "../../../util/context";
import FilePicker from "../../../components/FilePicker";
import {
  deleteFile,
  deleteFileFromList,
} from "../../ClientInfo/InformationClient";
import { ErrorLogger } from "../../../util/errorLogger";
import { ReactSVG } from "react-svg";

export interface GestionDocProps {}

type CustomCompany = Omit<
  Partial<Company>,
  "listOfBeneficialOwners" | "kbis" | "status" | "listOfBeneficialOwners" | "m0"
> & {
  listOfBeneficialOwners: IS3[] | null;
  kbis: IS3 | null;
  status: IS3 | null;
  m0: IS3 | null;
};

type CustomIS3 = IS3 & { id: string };

const GestionDocuments = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: CustomCompany;
  client?: User;
  callback?: any;
}) => {
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [lettreModal, setLettreModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [companyListOfBeneficialOwners, setCompanyListOfBeneficialOwners] =
    useState() as any;
  const [companyKbis, setCompanyKbis] = useState<any>();
  const [companyStatus, setCompanyStatus] = useState<any>();
  const [companyM0, setCompanyM0] = useState<any>();

  const [gedFiscal, setGedFiscal] = useState<any>([]);
  const [gedComptable, setGedComptable] = useState<any>([]);
  const [gedSocial, setGedSocial] = useState<any>([]);
  const [gedAutres, setGedAutres] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [
    companyListOfBeneficialOwnersLoading,
    setCompanyListOfBeneficialOwnersLoading,
  ] = useState<boolean>(false);
  const [companyKbisLoading, setCompanyKbisLoading] = useState<boolean>(false);
  const [companyStatusLoading, setCompanyStatusLoading] =
    useState<boolean>(false);
  const [companyM0Loading, setCompanyM0Loading] = useState<boolean>(false);

  const [gedFiscalLoading, setGedFiscalLoading] = useState<boolean>(false);
  const [gedComptableLoading, setGedComptableLoading] =
    useState<boolean>(false);
  const [gedSocialLoading, setGedSocialLoading] = useState<boolean>(false);
  const [gedAutresLoading, setGedAutresLoading] = useState<boolean>(false);

  let api = useAxios();
  const dispatch = useDispatch();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const callbackWrapper = async () => {
    if (creds.role !== UserTypes.Client) {
      return dispatch(fetchAllCompanies());
    } else {
      return callback();
    }
  };

  const gedFiller = (fillerCompany: CustomCompany) => {
    if (fillerCompany.ged_docs) {
      setGedFiscal(
        fillerCompany.ged_docs
          .filter(
            (elt) =>
              JSON.parse(elt.additionalData as any).type === "company" &&
              JSON.parse(elt.additionalData as any).subType === "Fiscal"
          )
          .reduce(
            (acc, curr) => [...acc, { ...(curr.file as IS3), id: curr.id }],
            [] as CustomIS3[]
          )
      );
      setGedComptable(
        fillerCompany.ged_docs
          .filter(
            (elt) =>
              JSON.parse(elt.additionalData as any).type === "company" &&
              JSON.parse(elt.additionalData as any).subType === "Comptable"
          )
          .reduce(
            (acc, curr) => [...acc, { ...(curr.file as IS3), id: curr.id }],
            [] as CustomIS3[]
          )
      );
      setGedSocial(
        fillerCompany.ged_docs
          .filter(
            (elt) =>
              JSON.parse(elt.additionalData as any).type === "company" &&
              JSON.parse(elt.additionalData as any).subType === "Social"
          )
          .reduce(
            (acc, curr) => [...acc, { ...(curr.file as IS3), id: curr.id }],
            [] as CustomIS3[]
          )
      );
      setGedAutres(
        fillerCompany.ged_docs
          .filter(
            (elt) =>
              JSON.parse(elt.additionalData as any).type === "company" &&
              JSON.parse(elt.additionalData as any).subType === "Autres"
          )
          .reduce(
            (acc, curr) => [...acc, { ...(curr.file as IS3), id: curr.id }],
            [] as CustomIS3[]
          )
      );
    }
  };

  useEffect(() => {
    if (reload) {
      callbackWrapper();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (company?.id) {
      setCompanyKbis(company?.kbis);
      setCompanyListOfBeneficialOwners(company?.listOfBeneficialOwners || []);
      setCompanyStatus(company?.status || []);
      setCompanyM0(company?.m0);
      gedFiller(company!);
    }
  }, [company]);

  const updateCompany = async (payload: any, attribute: string) => {
    try {
      switch (attribute) {
        case "kbis":
          setCompanyKbisLoading(true);
          break;
        case "status":
          setCompanyStatusLoading(true);
          break;
        case "listOfBeneficialOwners":
          setCompanyListOfBeneficialOwnersLoading(true);
          break;
        case "m0":
          setCompanyM0Loading(true);
          break;

        default:
          break;
      }
      const formData = new FormData();

      if (Array.isArray(payload)) {
        for (let elt of payload) {
          formData.append(attribute, elt);
        }
      } else {
        formData.append(attribute, payload);
      }

      formData.append("id", company?.id as string);

      await api.post(`/api/Company/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setReload(true);
      switch (attribute) {
        case "kbis":
          setCompanyKbisLoading(false);
          break;
        case "status":
          setCompanyStatusLoading(false);
          break;
        case "listOfBeneficialOwners":
          setCompanyListOfBeneficialOwnersLoading(false);
          break;
        case "m0":
          setCompanyM0Loading(false);
          break;

        default:
          break;
      }
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company files", error);
      setReload(true);
      switch (attribute) {
        case "kbis":
          setCompanyKbisLoading(false);
          break;
        case "status":
          setCompanyStatusLoading(false);
          break;
        case "listOfBeneficialOwners":
          setCompanyListOfBeneficialOwnersLoading(false);
          break;
        case "m0":
          setCompanyM0Loading(false);
          break;
        default:
          break;
      }
    }
  };

  const handleBenifListChange = async (event: any) => {
    try {
      const oldState = [];
      for (let file of event.target.files as any) {
        oldState.push(file);
      }
      await updateCompany(oldState, "listOfBeneficialOwners");
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const handleKbisChange = async (event: any) => {
    try {
      await updateCompany(event.target.files![0], "kbis");
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const handleCompanyStatusChange = async (event: any) => {
    try {
      const oldState = [];
      for (let file of event.target.files as any) {
        oldState.push(file);
      }
      await updateCompany(oldState, "status");
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const handleCompanyM0Change = async (event: any) => {
    try {
      await updateCompany(event.target.files![0], "m0");
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const saveGEDFile = async (payload: any, attribute: string) => {
    try {
      switch (attribute) {
        case "Fiscal":
          setGedFiscalLoading(true);
          break;
        case "Comptable":
          setGedComptableLoading(true);
          break;
        case "Social":
          setGedSocialLoading(true);
          break;
        case "Autres":
          setGedAutresLoading(true);
          break;

        default:
          break;
      }
      const formData = new FormData();

      if (Array.isArray(payload)) {
        for (let elt of payload) {
          formData.append("file", elt);
        }
      } else {
        formData.append("file", payload);
      }

      formData.append("company_id", company?.id as string);

      console.log(payload.name);

      formData.append(
        "name",
        payload.name || `ged_doc_${context}_${Date.now()}`
      );

      formData.append(
        "additionalData",
        JSON.stringify({
          type: "company",
          subType: attribute,
        }) as any
      );

      await api.post(`/api/GedDoc/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setReload(true);
      switch (attribute) {
        case "Fiscal":
          setGedFiscalLoading(false);
          break;
        case "Comptable":
          setGedComptableLoading(false);
          break;
        case "Social":
          setGedSocialLoading(false);
          break;
        case "Autres":
          setGedAutresLoading(false);
          break;

        default:
          break;
      }
      toast.success("Votre ajout a été effectué avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company files", error);
      setReload(true);
      switch (attribute) {
        case "Fiscal":
          setGedFiscalLoading(false);
          break;
        case "Comptable":
          setGedComptableLoading(false);
          break;
        case "Social":
          setGedSocialLoading(false);
          break;
        case "Autres":
          setGedAutresLoading(false);
          break;

        default:
          break;
      }
    }
  };

  const deleteGedFile = async (id: any, attribute: string) => {
    try {
      switch (attribute) {
        case "Fiscal":
          setGedFiscalLoading(true);
          break;
        case "Comptable":
          setGedComptableLoading(true);
          break;
        case "Social":
          setGedSocialLoading(true);
          break;
        case "Autres":
          setGedAutresLoading(true);
          break;

        default:
          break;
      }
      const { data } = await api.post(
        `/api/GedDoc/Delete`,
        {
          id: id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.deleted && data.deleted.length > 0) {
        toast.success("Votre suppression a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      switch (attribute) {
        case "Fiscal":
          setGedFiscalLoading(false);
          break;
        case "Comptable":
          setGedComptableLoading(false);
          break;
        case "Social":
          setGedSocialLoading(false);
          break;
        case "Autres":
          setGedAutresLoading(false);
          break;

        default:
          break;
      }
    } catch (error: any) {
      ErrorLogger("updating company files", error);
      setReload(true);
      switch (attribute) {
        case "Fiscal":
          setGedFiscalLoading(false);
          break;
        case "Comptable":
          setGedComptableLoading(false);
          break;
        case "Social":
          setGedSocialLoading(false);
          break;
        case "Autres":
          setGedAutresLoading(false);
          break;

        default:
          break;
      }
    }
  };

  const handleFiscalChange = async (event: any) => {
    try {
      for (let file of event.target.files as any) {
        await saveGEDFile(file, "Fiscal");
      }
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const handleComptableChange = async (event: any) => {
    try {
      for (let file of event.target.files as any) {
        await saveGEDFile(file, "Comptable");
      }
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const handleSocialChange = async (event: any) => {
    try {
      for (let file of event.target.files as any) {
        await saveGEDFile(file, "Social");
      }
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const handleAutresChange = async (event: any) => {
    try {
      for (let file of event.target.files as any) {
        await saveGEDFile(file, "Autres");
      }
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="section-information-societe">
          <Row>
            <h3>Dossier permanent</h3>
            <Col md={3} sm={12} className="pe-lg-3">
              <FormGroup>
                <Label for="proofOfIdentity">
                  Liste des bénéficiaires effectifs
                </Label>
                <FilePicker
                  className="form-icon icon-start form-file file-secondary"
                  onChange={handleBenifListChange}
                  state={companyListOfBeneficialOwners}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    setCompanyListOfBeneficialOwnersLoading(true);
                    await deleteFileFromList(
                      [elt.key],
                      "listOfBeneficialOwners",
                      "Company",
                      company?.id!,
                      creds.token,
                      api
                    );
                    await callbackWrapper();
                    setReload(true);
                    setCompanyListOfBeneficialOwnersLoading(false);
                    toast.success(
                      "Votre supression a été effectuée avec succès!",
                      {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setCompanyListOfBeneficialOwners((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== file?.name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  loader={companyListOfBeneficialOwnersLoading}
                />
              </FormGroup>
            </Col>
            <Col md={3} sm={12} className="px-md-3">
              <FormGroup>
                <Label for="proofOfIdentity">KBIS ou avis SIREN</Label>
                <FilePicker
                  className="form-icon icon-start form-file file-secondary"
                  onChange={handleKbisChange}
                  state={companyKbis}
                  fileReadyDelete={async () => {
                    try {
                      setCompanyKbisLoading(true);
                      await deleteFile(
                        "Company",
                        company?.id!,
                        "kbis",
                        creds.token,
                        api
                      );
                      await callbackWrapper();
                      setReload(true);
                      setCompanyKbisLoading(false);
                      toast.success(
                        "Votre supression a été effectuée avec succès!",
                        {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    } catch (error: any) {
                      console.table("deleting file : ", error);
                    }
                  }}
                  fileStagedDelete={() => setCompanyKbis(null)}
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  loader={companyKbisLoading}
                />
              </FormGroup>
            </Col>
            <Col md={3} sm={12} className="ps-lg-3">
              <FormGroup>
                <Label for="proofOfIdentity">Status</Label>
                <FilePicker
                  className="form-icon icon-start form-file file-secondary"
                  onChange={handleCompanyStatusChange}
                  state={companyStatus}
                  renderType={"array"}
                  fileReadyDelete={async (elt: { key: string }) => {
                    try {
                      setCompanyStatusLoading(true);
                      await deleteFileFromList(
                        [elt.key],
                        "status",
                        "Company",
                        company?.id!,
                        creds.token,
                        api
                      );
                      await callbackWrapper();
                      setReload(true);
                      setCompanyStatusLoading(false);
                      toast.success(
                        "Votre supression a été effectuée avec succès!",
                        {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    } catch (error: any) {
                      console.table("deleting file : ", error);
                    }
                  }}
                  fileStagedDelete={(file: { name: any }) =>
                    setCompanyStatus((prevState: any) => {
                      return prevState.filter(
                        (elt: { name: any }) => elt.name !== file?.name
                      );
                    })
                  }
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  isMultiple={true}
                  loader={companyStatusLoading}
                />
              </FormGroup>
            </Col>
            <Col md={3} sm={12} className="ps-lg-3">
              <FormGroup>
                <Label for="proofOfIdentity">M0</Label>
                <FilePicker
                  className="form-icon icon-start form-file file-secondary"
                  onChange={handleCompanyM0Change}
                  state={companyM0}
                  fileReadyDelete={async () => {
                    try {
                      setCompanyM0Loading(true);
                      await deleteFile(
                        "Company",
                        company?.id!,
                        "m0",
                        creds.token,
                        api
                      );
                      await callbackWrapper();
                      setReload(true);
                      setCompanyM0Loading(false);
                      toast.success(
                        "Votre supression a été effectuée avec succès!",
                        {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                    } catch (error: any) {
                      console.table("deleting file : ", error);
                    }
                  }}
                  fileStagedDelete={() => setCompanyM0(null)}
                  setUrl={setUrl}
                  setViewModal={setViewModal}
                  loader={companyM0Loading}
                />
              </FormGroup>
            </Col>

            {String(company?.readyToSign) === "true" && (
              <Col md={3} sm={12} className="ps-lg-4">
                <Label>Lettre de mission</Label>
                <div
                  className="document-item"
                  onClick={() => {
                    setLettreModal(true);
                  }}
                >
                  <span className="doc-icon">
                    {<ReactSVG src={PdfIcons} />}
                    {/* <img src={PdfIcons} alt="icon" /> */}
                  </span>
                  <span className="doc-name">Voir</span>
                </div>
              </Col>
            )}
          </Row>
          <div className="mt-lg-5">
            <h3>Dossier annuel</h3>
            <Row>
              <Col md={3} sm={12} className="px-md-3">
                <FormGroup>
                  <Label for="proofOfIdentity">Fiscal</Label>
                  <FilePicker
                    className="form-icon icon-start form-file file-secondary"
                    onChange={handleFiscalChange}
                    state={gedFiscal}
                    renderType={"array"}
                    fileReadyDelete={async (elt: { key: any; id: any }) => {
                      const foundElt = gedFiscal.find(
                        (file: { key: any }) => file.key === elt.key
                      );

                      if (foundElt) {
                        setGedFiscalLoading(true);
                        await deleteGedFile(foundElt.id, "Fiscal");
                        await callbackWrapper();
                        setReload(true);
                        setGedFiscalLoading(false);
                      }
                    }}
                    fileStagedDelete={(file: { name: any }) =>
                      setGedFiscal((prevState: any) => {
                        return prevState.filter(
                          (elt: { name: any }) => elt.name !== file?.name
                        );
                      })
                    }
                    setUrl={setUrl}
                    setViewModal={setViewModal}
                    isMultiple={true}
                    loader={gedFiscalLoading}
                  />
                </FormGroup>
              </Col>
              <Col md={3} sm={12} className="px-md-3">
                <FormGroup>
                  <Label for="proofOfIdentity">Comptable</Label>
                  <FilePicker
                    className="form-icon icon-start form-file file-secondary"
                    onChange={handleComptableChange}
                    state={gedComptable}
                    renderType={"array"}
                    fileReadyDelete={async (elt: { key: any; id: any }) => {
                      const foundElt = gedComptable.find(
                        (file: { key: any }) => file.key === elt.key
                      );

                      if (foundElt) {
                        setGedComptableLoading(true);
                        await deleteGedFile(foundElt.id, "Comptable");
                        await callbackWrapper();
                        setReload(true);
                        setGedComptableLoading(false);
                      }
                    }}
                    fileStagedDelete={(file: { name: any }) =>
                      setGedComptable((prevState: any) => {
                        return prevState.filter(
                          (elt: { name: any }) => elt.name !== file?.name
                        );
                      })
                    }
                    setUrl={setUrl}
                    setViewModal={setViewModal}
                    isMultiple={true}
                    loader={gedComptableLoading}
                  />
                </FormGroup>
              </Col>
              <Col md={3} sm={12} className="px-md-3">
                <FormGroup>
                  <Label for="proofOfIdentity">Social</Label>
                  <FilePicker
                    className="form-icon icon-start form-file file-secondary"
                    onChange={handleSocialChange}
                    state={gedSocial}
                    renderType={"array"}
                    fileReadyDelete={async (elt: { key: any; id: any }) => {
                      const foundElt = gedSocial.find(
                        (file: { key: any }) => file.key === elt.key
                      );

                      if (foundElt) {
                        setGedSocialLoading(true);
                        await deleteGedFile(foundElt.id, "Social");
                        await callbackWrapper();
                        setReload(true);
                        setGedSocialLoading(false);
                      }
                    }}
                    fileStagedDelete={(file: { name: any }) =>
                      setGedSocial((prevState: any) => {
                        return prevState.filter(
                          (elt: { name: any }) => elt.name !== file?.name
                        );
                      })
                    }
                    setUrl={setUrl}
                    setViewModal={setViewModal}
                    isMultiple={true}
                    loader={gedSocialLoading}
                  />
                </FormGroup>
              </Col>
              <Col md={3} sm={12} className="px-md-3">
                <FormGroup>
                  <Label for="proofOfIdentity">Autres</Label>
                  <FilePicker
                    className="form-icon icon-start form-file file-secondary"
                    onChange={handleAutresChange}
                    state={gedAutres}
                    renderType={"array"}
                    fileReadyDelete={async (elt: { key: any; id: any }) => {
                      const foundElt = gedAutres.find(
                        (file: { key: any }) => file.key === elt.key
                      );

                      if (foundElt) {
                        setGedAutresLoading(true);
                        await deleteGedFile(foundElt.id, "Autres");
                        dispatch(fetchAllCompanies());
                        setReload(true);
                        setGedAutresLoading(false);
                      }
                    }}
                    fileStagedDelete={(file: { name: any }) =>
                      setGedAutres((prevState: any) => {
                        return prevState.filter(
                          (elt: { name: any }) => elt.name !== file?.name
                        );
                      })
                    }
                    setUrl={setUrl}
                    setViewModal={setViewModal}
                    isMultiple={true}
                    loader={gedAutresLoading}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>

          {/* <OtherDocs
              modelId={company?.id!}
              data={
                company?.ged_docs && company.ged_docs.length > 0
                  ? company.ged_docs
                  : []
              }
              setRelaod={setReload}
              token={creds.token}
              api={api}
              additionalData={{ type: GedDocTypes.Company }}
            /> */}
        </div>
      </div>
      <div className="openbtn text-center">
        <Modal
          isOpen={lettreModal}
          toggle={() => {
            setLettreModal(false);
          }}
          className="modal-secondary modal-dialog-centered"
        >
          <ModalHeader
            toggle={() => {
              setLettreModal(false);
            }}
          >
            Vérification de la catégorisation
          </ModalHeader>
          <ModalBody>
            <LetterMission
              companyName={company && (company.name || "")}
              companyAddress={company && (company.headOffice || "")}
              companyForm={company && (company.legalForm || "")}
              companyTVA={company && company.subjectToVAT ? "" : "non"}
              userName={
                client && `${client.firstName ?? ""} ${client.lastName ?? ""}`
              }
            />
          </ModalBody>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default GestionDocuments;
