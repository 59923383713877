import React, { FC } from "react";
import {
  Company,
  ICategory,
  ISubCategory,
  ITransaction,
  User,
} from "../../interfaces";
import "./Tabs.scss";

export type ImmobStateHandler = {
  isImmobFire: boolean;
  setIsImmobFire: any;
  isImmobSubCateg: ISubCategory | null;
  setIsImmobSubCateg: any;
  isImmobTrans: ITransaction | null;
  setIsImmobTrans: any;
} | null;

type TabsProps = {
  tabs: {
    label?: string;
    image?: string;
    index: number;
    Component: FC<{
      index: number;
      context?: string;
      pack?: string;
      company?: Company | {};
      client?: User | {};
      callback?: () => void | null;
      reloadParent?: any;
      reload?: boolean;
      immobStateHandler?: ImmobStateHandler;
    }>;
  }[];
  selectedTab: number;
  onClick: (index: number) => void;
  orientation?: "horizontal" | "vertical";
  className?: string;
  context?: string;
  pack?: string;
  company?: Company;
  client?: User;
  callback?: any;
  reloadParent?: any;
  reload?: boolean;
  immobStateHandler?: ImmobStateHandler;
};

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab number
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 */
const Tabs: FC<TabsProps> = ({
  className = "tabs-component",
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = "horizontal",
  context = "",
  pack = "pack-BIC",
  company = {},
  client = {},
  callback = null,
  reload = false,
  immobStateHandler = null,
  reloadParent = null,
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);

  return (
    <div
      className={
        orientation === "vertical" ? className + " vertical" : className
      }
    >
      <div className="tablist" role="tablist" aria-orientation={orientation}>
        {tabs.map((tab) => (
          <button
            className={selectedTab === tab.index ? "btn active" : " btn"}
            onClick={() => onClick(tab.index)}
            key={tab.index}
            type="button"
            role="tab"
            aria-selected={selectedTab === tab.index}
            aria-controls={`tabpanel-${tab.index}`}
            tabIndex={selectedTab === tab.index ? 0 : -1}
            id={`btn-${tab.index}`}
          >
            {tab.image && tab.image !== "" && (
              <span className="icon-tabs">
                {" "}
                <img src={tab.image} />
              </span>
            )}
            <span className="label-span">{tab.label}</span>
          </button>
        ))}
      </div>
      <div
        role="tabpanel"
        aria-labelledby={`btn-${selectedTab}`}
        id={`tabpanel-${selectedTab}`}
        className="tabpanel"
      >
        {Panel && (
          <Panel.Component
            index={selectedTab}
            context={context}
            company={company}
            client={client}
            callback={callback}
            reloadParent={reloadParent}
            reload={reload}
            immobStateHandler={immobStateHandler}
            pack={pack}
          />
        )}
      </div>
    </div>
  );
};
export default Tabs;
