import React, { useEffect, useState } from "react";
import "./Mailing.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import UserInfo from "../../components/user-info/UserInfo";
import { data } from "../../constants";
import { RiCalendar2Line, RiDownload2Fill } from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import AttacheIcon from "../../assets/AttacheIcon";
import Select from "react-select";

import { BootyPagination } from "../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import UserIcon from "../../assets/images/svg/user-orange.svg";
import UserIconLead from "../../assets/images/svg/user-lead.svg";
import UserProsp from "../../assets/images/svg/user-pres.svg";
import EmailIcon from "../../assets/images/svg/mail-orange.svg";
import KeyIcon from "../../assets/images/svg/key-orange.svg";
import PhoneIcon from "../../assets/images/svg/phone-orange.svg";
import LabelIcon from "../../assets/images/svg/banq-icon.svg";

import {
  Company,
  IApplication,
  IEmail,
  IPermission,
  ProspectProps,
  User,
} from "../../interfaces";

import { IoMdClose, IoMdCreate } from "react-icons/io";
import EmailIconBlue from "../../assets/images/svg/mail-blue.svg";
import PhoneIconBleu from "../../assets/PhoneIcon";
import CheckIcon from "../../assets/CheckIcon";
import Badge from "../../components/badge/Status";
import Status from "../../components/badge/Status";
import LoadBoxIcon from "../../assets/LoadBoxIcon";
import ViewIcon from "../../assets/ViewIcon";
import { useDispatch, useSelector } from "react-redux";
import { OptionType, UserTypes } from "../../util/context";
import config from "../../config";
import axios from "axios";
import {
  setCounter,
  setErrorMessage,
} from "../../store/reducers/utils/utilSlice";
import { ErrorLogger } from "../../util/errorLogger";
import moment from "moment";
import "moment/locale/fr";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PlusIconGig from "../../assets/PlusIconGig";
import FileIconUpload from "../../assets/FileIconUpload";
import parse from "html-react-parser";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import { useFormatter } from "../../util/hooks/useFormatter";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import { toast, ToastContainer } from "react-toastify";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

type SearchEmailsFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  company?: OptionType | null;
};

type CreateEmailsFormValues = {
  subject?: string | null;
  body?: string | null;
  company?: OptionType | null;
  attachements?: any;
};

export interface ClientsProps {}
const Mailing: React.FC<ClientsProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [emails, setEmails] = useState<Array<IEmail>>([]);
  const [loading, setLoading] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  const [singleEmail, setSingleEmail] = useState<IEmail | null>();
  const [chosenCompanies, setChosenCompanies] = useState<Company[]>([]);

  const [sendLoading, setSendLoading] = useState(false);

  const [stateErrorMessage, setStateErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const onEditToggle = (email: IEmail) => {
    setSingleEmail(email);
    setEditOpen(true);
  };
  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const orderStatus: any = {
    Lead: "primary",
    Interessé: "warning",
    valider: "success",
    refund: "danger",
  };
  const { dateSort } = useFormatter();

  const columns: any = [
    {
      name: "Nº Email",
      selector: (row: any) => row.id.toUpperCase(),
      sortable: true,
    },
    {
      name: "Entreprise",
      selector: (row: any) => row.company.name || row.company.email,
      sortable: true,
    },
    {
      name: "Sujet",
      selector: (row: any) => row.subject,
    },
    {
      name: "Date de création",
      selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      sortFunction: dateSort,
    },
    {
      name: "Client",
      selector: (row: any) =>
        companiesList.find((comp) => comp.id === row.company.id)?.application
          ?.name,
      sortable: true,
    },
    {
      button: true,
      cell: (row: IEmail) => (
        <div className="table-action">
          <button className="btn btn-secondaryColor" onClick={() => onEditToggle(row)}>
            <ViewIcon />
          </button>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { clientsList } = useSelector(
    (state: { clientsList: Company[] }) => state.clientsList
  ) as unknown as { clientsList: Company[] };

  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const { user } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };
  let api = useAxios();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    // await getEmails(page);
    setCurrentPage(page);
  };

  const handleBodyChange = async (body: any) => {
    setEmailBody(body);
  };

  const getEmails = async () => {
    try {
      let payload =
        creds.role === UserTypes.Cabinet
          ? {
              where: {
                company_id: clientsList.reduce(
                  (acc, curr) => [...acc, curr.id],
                  [] as Array<string>
                ),
              },
              perPage: 20,
              pageIndex: currentPage,
            }
          : {
              where: {
                company_id: companiesList.reduce(
                  (acc, curr) => [...acc, curr.id],
                  [] as Array<string>
                ),
              },
              perPage: 20,
              pageIndex: currentPage,
            };
      const { data } = await api.post(`/api/Email/All`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      setEmails(
        data.data.sort((a: { createdAt: number }, b: { createdAt: number }) => {
          return moment(b.createdAt).diff(moment(a.createdAt));
        })
      );
      setTotalRows(data.count);
    } catch (error: any) {
      ErrorLogger("getting emails", error);
    }
  };

  useEffect(() => {
    getEmails();
  }, [currentPage]);

  useEffect(() => {
    if (creds.token) {
      getEmails();
    }
  }, []);

  useEffect(() => {
    if (!open) {
      getEmails();
    }
  }, [open]);

  const resetSearchForm = () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      company: null,
    });
    getEmails();
  };

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchEmailsFormValues>({});

  const {
    watch,
    control: createControl,
    register: createRegister,
    handleSubmit: createHandleSubmit,
    reset: createReset,
    formState: { errors: createErrors },
  } = useForm<CreateEmailsFormValues>({});

  const { attachements: fileAttachements } = watch();

  const searchEmails: SubmitHandler<SearchEmailsFormValues> = async (
    form: SearchEmailsFormValues
  ) => {
    try {
      setLoading(true);
      let payload: any = {};
      if (form.dateFrom || form.dateTo)
        payload.createdAt = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };

      if (form.company) payload.company_id = form.company.value;

      const { data } = await api.post(
        `/api/Email/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setEmails(data.data);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("searching emails in mailing", error);
    }
  };

  const createEmail: SubmitHandler<CreateEmailsFormValues> = async (
    form: CreateEmailsFormValues
  ) => {
    try {
      if (!form.subject || !chosenCompanies.length || emailBody === "") {
        setStateErrorMessage({
          type: "not_valid",
          message: "Veuillez remplir tous les champs nécessaires du formulaire",
        });
        return;
      }
      setSendLoading(true);
      const data = new FormData();

      if (form.subject) data.append("subject", form.subject);
      data.append("body", emailBody);

      if (form.attachements && form.attachements.length) {
        for (let index = 0; index < form.attachements.length; index++) {
          const element = form.attachements[index];
          data.append(`attachement`, element);
        }
      }

      for (let reciever of chosenCompanies) {
        data.set("company_id", reciever.id);
        await api.post(`/api/Email/Create`, data, {
          headers: {
            "x-access-token": creds.token,
          },
        });
      }
      setSendLoading(false);
      resetEmailCreation();
      setEmailBody("");
      setOpen(false);
      toast.success("Votre email a été envoyé avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      setSendLoading(false);
      ErrorLogger("creating email", error);
    }
  };

  const resetEmailCreation = () => {
    createReset({
      subject: null,
      company: null,
      body: null,
      attachements: null,
    });
    setChosenCompanies([]);
  };

  // search email form
  const { ref: dateToRef, ...dateTo } = searchRegister("dateTo");
  const { ref: dateFromRef, ...dateFrom } = searchRegister("dateFrom");

  // create email form
  const { ref: subjectRef, ...subject } = createRegister("subject");
  const { ref: bodyRef, ...body } = createRegister("body");
  const { ref: attachementsRef, ...attachements } =
    createRegister("attachements");

  return (
    <>
      <ToastContainer />
      <div className="page page-mailing">
        <span>
          <button className="btn  btn-add-mail" onClick={() => ontoggle()}>
            <PlusIconGig />
          </button>
        </span>
        <div className="top-content">
          <Row>
            <Col lg={8} md={12}>
              <BannerTop banner={data.mailing} />
            </Col>
            <Col lg={4} md={12}>
              <UserInfo user={user} />
            </Col>
          </Row>
        </div>
        <Card className="card-filter">
          <form onSubmit={searchHandleSubmit(searchEmails)}>
            <Row className="align-items-end">
              <Col lg={9} md={12}>
                <div className="filterInner">
                  <Row>
                    <Col lg={4} sm={6}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="dated">Date de début</Label>
                        <Controller
                          control={searchControl}
                          name="dateFrom"
                          render={({ field }) => (
                            <DatePicker
                              placeholderText="Date de début"
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        {/* <Input
                        id="dated"
                        {...dateFrom}
                        innerRef={dateFromRef}
                        placeholder="Date de début"
                        type="date"
                        className="form-secondary"
                      /> */}
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={CalenderIconBlue} alt="icon" /> */}
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col lg={4} sm={6}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="datef">Date de fin</Label>
                        <Controller
                          control={searchControl}
                          name="dateTo"
                          render={({ field }) => (
                            <DatePicker
                              placeholderText="Date de fin"
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        {/* <Input
                        id="datef"
                        {...dateTo}
                        innerRef={dateToRef}
                        placeholder="Date de fin"
                        type="date"
                        className="form-secondary"
                      /> */}
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={CalenderIconBlue} alt="icon" /> */}
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col lg={4} sm={12}>
                      <FormGroup>
                        <Label for="exampleEmail">Entreprise</Label>
                        <Controller
                          name="company"
                          control={searchControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={
                                creds.role === UserTypes.Cabinet
                                  ? clientsList.reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          label: curr.name || curr.email,
                                          value: curr.id,
                                        },
                                      ],
                                      [] as OptionType[]
                                    )
                                  : companiesList.reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          label: curr.name || curr.email,
                                          value: curr.id,
                                        },
                                      ],
                                      [] as OptionType[]
                                    )
                              }
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={3} md={12}>
                <div className="actionsFilter mb-3">
                  <Button color="secondary" type="submit">
                    <span>Filtrer</span>
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    type="button"
                    onClick={() => resetSearchForm()}
                  >
                    <span>Réinitialiser</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </Card>
        <div>
          <Card className="card-Table table-primary">
            <DataTable
              columns={columns}
              noDataComponent={<p>Il n'y a aucun data à afficher</p>}
              data={emails}
              pagination
              paginationComponent={(props) => {
                const customProps = { ...props, color: "primary" };
                return <BootyPagination {...customProps} />;
              }}
              progressPending={loading}
              progressComponent={
                <>
                  <Spinner color="light" type="grow">
                    Loading...
                  </Spinner>
                  <Spinner color="light" type="grow">
                    Loading...
                  </Spinner>
                  <Spinner color="light" type="grow">
                    Loading...
                  </Spinner>
                </>
              }
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              paginationPerPage={20}
            />

            <div className="openbtn text-center">
              {/*view email modal*/}
              <Modal
                className="modal-secondary modal-dialog-centered "
                isOpen={editOpen}
                toggle={() => {
                  setSingleEmail(null);
                  setEditOpen(false);
                }}
              >
                <ModalHeader
                  toggle={() => {
                    setSingleEmail(null);
                    setEditOpen(false);
                  }}
                >
                  {singleEmail?.id}
                </ModalHeader>
                <ModalBody>
                  <div className="content-form-block">
                    <div className="block-show-mail">
                      <p className="box-line-show">
                        <span className="label-form">Sujet : </span>
                        <strong>{singleEmail?.subject}</strong>
                      </p>
                      <p className="box-line-show mb-4">
                        <span className="label-form">À : </span>
                        <strong>
                          {singleEmail?.company.name ||
                            singleEmail?.company.email}
                        </strong>
                      </p>
                      <div className="form-control form-secondary text-show mb-3">
                        {parse(`${singleEmail?.body}`)}
                      </div>
                      {singleEmail?.attachement && (
                        <a
                          className="file-upload-show form-primary  email-attach"
                          href={singleEmail?.attachement.url}
                          target="_blank"
                        >
                          <FileIconUpload /> {singleEmail?.attachement.key}
                        </a>
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>

              {/*create email modal*/}
              <Modal
                className="modal-warning modal-dialog-centered "
                isOpen={open}
                toggle={() => {
                  setOpen(false);
                  resetEmailCreation();
                }}
              >
                <ModalHeader
                  toggle={() => {
                    setOpen(false);
                    resetEmailCreation();
                  }}
                >
                  Envoyer un email
                </ModalHeader>
                <form onSubmit={createHandleSubmit(createEmail)}>
                  <ModalBody>
                    <div className="content-form-block">
                      <FormGroup className="form-icon icon-end">
                        <Row className="align-items-center">
                          <Col lg={2} md={12} className="label-line">
                            <Label for="subject">Sujet : </Label>
                          </Col>
                          <Col lg={10} sm={12} className="label-line">
                            <Input
                              id="subject"
                              innerRef={subjectRef}
                              {...subject}
                              type="text"
                              className="form-warning"
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                      {stateErrorMessage?.type === "not_valid" && (
                        <Alert color="danger">
                          {stateErrorMessage?.message}
                        </Alert>
                      )}
                      <FormGroup>
                        <Row className="align-items-center">
                          <Col lg={2} md={12}>
                            <Label for="ass">À : </Label>
                          </Col>
                          <Col lg={10} sm={12}>
                            <Select
                              options={companiesList.reduce(
                                (acc, curr) => [
                                  ...acc,
                                  {
                                    label: curr.name || curr.email,
                                    value: curr.id,
                                  },
                                ],
                                [] as OptionType[]
                              )}
                              value={null}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-warning"
                              onChange={(item) => {
                                const selectedComp = companiesList.find(
                                  (elt) => elt.id === item?.value
                                );
                                setChosenCompanies((prevState: Company[]) => {
                                  const exists = prevState.findIndex(
                                    (company) => company.id === item?.value
                                  );
                                  if (exists > -1) {
                                    return prevState;
                                  } else {
                                    return [
                                      ...prevState,
                                      selectedComp as Company,
                                    ];
                                  }
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-end">
                          <Col lg={10} sm={12}>
                            <div className="liste-select-form">
                              {chosenCompanies.length > 0 &&
                                chosenCompanies.map((elt, index) => (
                                  <span
                                    className="tags-item tags-warning"
                                    key={index}
                                  >
                                    <span>{elt.name || elt.email}</span>
                                    <IoMdClose
                                      onClick={() => {
                                        setChosenCompanies((prevState) => {
                                          return prevState.filter(
                                            (comp) => comp.id !== elt.id
                                          );
                                        });
                                      }}
                                    />
                                  </span>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </FormGroup>
                      {/* <FormGroup className="form-icon icon-end">
                      <Row className="align-items-center">
                        <Col lg={10} sm={12} className="label-line">
                          <Input
                            id="body"
                            innerRef={bodyRef}
                            {...body}
                            placeholder="Body"
                            type="text"
                            className="form-warning"
                          />
                        </Col>
                      </Row>
                    </FormGroup> */}
                      <FormGroup>
                        <ReactQuill
                          className="form-warning"
                          onChange={handleBodyChange}
                          defaultValue={emailBody}
                          theme={"snow"}
                        />
                      </FormGroup>
                      <FormGroup className="form-file file-warning">
                        <span className="label-file">
                          Déposez la pièce jointe ici
                        </span>
                        <label className="file-box ">
                          <RiDownload2Fill />
                          <Input
                            id="attachements"
                            innerRef={attachementsRef}
                            {...attachements}
                            placeholder="..."
                            className="form-default"
                            type="file"
                          />
                          <span> Télécharger d'ici</span>
                        </label>
                      </FormGroup>
                      <div className="list-Files email-attach">
                        {fileAttachements && fileAttachements[0] && (
                          <span className="file-box-item">
                            <AttacheIcon />
                            <pre>{fileAttachements[0].name}</pre>
                          </span>
                        )}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="warning" type="submit">
                      {!sendLoading ? (
                        "Envoyer"
                      ) : (
                        <Spinner color="light" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      )}
                    </Button>
                  </ModalFooter>
                </form>
              </Modal>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Mailing;
