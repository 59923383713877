import React, { useEffect, useState } from "react";
import "./InformationSociete.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { BootyPagination } from "../../../components/table/pagination";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import juridiqueIcons from "../../../assets/images/svg/juridique-icon.svg";
import pointerIcons from "../../../assets/images/svg/pointer-icon.svg";
import packMoneyIcons from "../../../assets/images/svg/pack-money.svg";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import { RiCalendar2Line, RiDownload2Fill } from "react-icons/ri";
import AttacheIcon from "../../../assets/AttacheIcon";
import { Company, IS3, User } from "../../../interfaces";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ErrorLogger } from "../../../util/errorLogger";
import {
  AppPacks,
  OptionType,
  UserTypes,
  VAT_REGIME_OPTIONS,
} from "../../../util/context";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import config from "../../../config";
import { fetchAllCompanies } from "../../../store/reducers/companies-list/companiesSlice";
import { toast, ToastContainer } from "react-toastify";
import {
  deleteFile,
  deleteFileFromList,
} from "../../ClientInfo/InformationClient";
import FileViewer from "../../../components/FileViewer";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import FilePicker from "../../../components/FilePicker";
import { callbackWrapper } from "../../ClientInfo/GestionDocuments/GestionDocuments";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

type GeneralFormValues = Omit<
  Partial<Company>,
  "regimeTVA" | "kbis" | "declarationTVADate" | "dateOfRegistration"
> & {
  regimeTVA: OptionType | null;
  kbis: IS3 | null;
  declarationTVADate: string | null;
  dateOfRegistration: string | null;
};

const InformationSociete = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [companyKbis, setCompanyKbis] = useState<any>();
  // const [companyListOfBeneficialOwners, setCompanyListOfBeneficialOwners] =
  //   useState([]) as any;
  const [companyListOfBeneficialOwners, setCompanyListOfBeneficialOwners] =
    useState() as any;
  const [companyStatus, setCompanyStatus] = useState<any>();
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [loading, setLoading] = useState<boolean>(false);

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: object }) => state.user
  ) as { user: User };

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const {
    kbis: _kbis,
    status: _status,
    listOfBeneficialOwners: _listOfBeneficialOwners,
  } = watch();

  const getCompany = () => {
    try {
      setCurrentCompany(company);
      reset({
        legalForm: company?.legalForm,
        name: company?.name,
        siren: company?.siren,
        practicalActivity: company?.practicalActivity,
        headOffice: company?.headOffice,
        email: company?.email,
        shareCapital: company?.shareCapital,
        ape: company?.ape,
        subjectToVAT: company?.subjectToVAT,
        regimeTVA:
          VAT_REGIME_OPTIONS.find((elt) => elt.value === company?.regimeTVA) ||
          null,
        readyToSign: company?.readyToSign,
        Bilan: company?.Bilan,
        letterMission: company?.letterMission,
        declarationTVADate: company?.declarationTVADate
          ? moment(company?.declarationTVADate).format()
          : null,
        dateOfRegistration: company?.dateOfRegistration
          ? moment(company?.dateOfRegistration).format()
          : null,
      });
      setCompanyKbis(company?.kbis);
      setCompanyListOfBeneficialOwners(company?.listOfBeneficialOwners || []);
      setCompanyStatus(company?.status);
    } catch (error: any) {
      ErrorLogger("getting company info", error);
    }
  };

  useEffect(() => {
    getCompany();
  }, [company]);

  const { ref: legalFormRef, ...legalForm } = register("legalForm");
  const { ref: nameRef, ...name } = register("name");
  const { ref: sirenRef, ...siren } = register("siren");
  const { ref: practicalActivityRef, ...practicalActivity } =
    register("practicalActivity");
  const { ref: headOfficeRef, ...headOffice } = register("headOffice");
  const { ref: declarationTVADateRef, ...declarationTVADate } =
    register("declarationTVADate");
  const { ref: dateOfRegistrationRef, ...dateOfRegistration } =
    register("dateOfRegistration");
  const { ref: shareCapitalRef, ...shareCapital } = register("shareCapital");
  const { ref: apeRef, ...ape } = register("ape");
  const { ref: subjectToVATRef, ...subjectToVAT } = register("subjectToVAT");
  const { ref: BilanRef, ...Bilan } = register("Bilan");
  const { ref: letterMissionRef, ...letterMission } = register("letterMission");

  let api = useAxios();

  const updateCompany: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();
      console.log(form)

      if (connectedUser?.role !== UserTypes.Client) {
        formData.append("id", id as string);
      } else {
        formData.append("id", company?.id as string);
      }

      if (form.Bilan) {
        formData.append("Bilan", String(form.Bilan));
      }

      if (String(form.subjectToVAT) !== String(currentCompany?.subjectToVAT)) {
        formData.append("subjectToVAT", String(form.subjectToVAT));
      }

      if (
        String(form.letterMission) !== String(currentCompany?.letterMission)
      ) {
        formData.append("letterMission", String(form.letterMission));
      }

      if (form.regimeTVA && form.regimeTVA?.value !== currentCompany?.regimeTVA) {
        formData.append("regimeTVA", String(form.regimeTVA?.value));
      }

      if (form.ape !== currentCompany?.ape) {
        formData.append("ape", form.ape as string);
      }

      if (form.headOffice !== currentCompany?.headOffice) {
        formData.append("headOffice", form.headOffice as string);
      }

      if (form.legalForm !== currentCompany?.legalForm) {
        formData.append("legalForm", form.legalForm as string);
      }

      if (form.name !== currentCompany?.name) {
        formData.append("name", form.name as string);
      }

      if (form.practicalActivity !== currentCompany?.practicalActivity) {
        formData.append("practicalActivity", form.practicalActivity as string);
      }

      if (form.shareCapital !== currentCompany?.shareCapital) {
        formData.append("shareCapital", form.shareCapital as string);
      }

      if (form.siren !== currentCompany?.siren) {
        formData.append("siren", form.siren as string);
      }

      if (
        moment(form.dateOfRegistration).format("DD/MM/YYYY") !==
        moment(currentCompany?.dateOfRegistration).format("DD/MM/YYYY")
      ) {
        formData.append(
          "dateOfRegistration",
          moment(form.dateOfRegistration).format("YYYY-MM-DD hh:mm") as string
        );
      }

      if (
        moment(form.declarationTVADate).format("DD/MM/YYYY") !==
        moment(currentCompany?.declarationTVADate).format("DD/MM/YYYY")
      ) {
        formData.append(
          "declarationTVADate",
          moment(form.declarationTVADate).format("YYYY-MM-DD hh:mm") as string
        );
      }

      await api.post(`/api/Company/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await callbackWrapper(connectedUser!, callback);

      setLoading(false);
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  const sendReadyToSign = async () => {
    try {
      setLoading(true);
      await api.post(
        `/api/Company/Update`,
        {
          id: company?.id,
          readyToSign: true,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      dispatch(fetchAllCompanies());
      setLoading(false);
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="section-information-societe">
          <h3>Informations société</h3>
          <form onSubmit={handleSubmit(updateCompany)}>
            <Row>
              <Col md={4} sm={12} className="pe-lg-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="fj">Forme juridique</Label>
                  <Input
                    id="fj"
                    innerRef={legalFormRef}
                    {...legalForm}
                    placeholder="Forme juridique"
                    type="text"
                    className="form-secondary"
                  />
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={juridiqueIcons} alt="icon" /> */}
                    {<ReactSVG src={juridiqueIcons} />}
                  </button>
                </FormGroup>
              </Col>
              <Col md={4} sm={12} className="px-md-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="nc">Nom comercial</Label>
                  <Input
                    id="nc"
                    innerRef={nameRef}
                    {...name}
                    placeholder="Nom comercial"
                    type="text"
                    className="form-secondary"
                  />
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={juridiqueIcons} alt="icon" /> */}
                    {<ReactSVG src={juridiqueIcons} />}
                  </button>
                </FormGroup>
              </Col>
              <Col md={4} sm={12} className="ps-lg-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="n-siren">Numéro de SIREN</Label>
                  <Input
                    id="n-siren"
                    innerRef={sirenRef}
                    {...siren}
                    placeholder="Nº de SIREN"
                    type="text"
                    className="form-secondary"
                  />
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={juridiqueIcons} alt="icon" /> */}
                    {<ReactSVG src={juridiqueIcons} />}
                  </button>
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="form-icon icon-start">
                  <Label for="cpwd">L'activité de l'entreprise</Label>
                  <Input
                    className="form-secondary text-area-custom"
                    type="textarea"
                    rows="35"
                    innerRef={practicalActivityRef}
                    {...practicalActivity}
                  />
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className="pe-lg-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="adress">Adresse</Label>
                  <Input
                    id="adress"
                    innerRef={headOfficeRef}
                    {...headOffice}
                    placeholder="Adresse"
                    type="text"
                    className="form-secondary"
                  />
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={pointerIcons} alt="icon" /> */}
                    {<ReactSVG src={juridiqueIcons} />}
                  </button>
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className="ps-lg-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="cpsocial">Capital social</Label>
                  <Input
                    id="cpsocial"
                    innerRef={shareCapitalRef}
                    {...shareCapital}
                    placeholder="Capital social"
                    type="text"
                    className="form-secondary"
                  />
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={packMoneyIcons} alt="icon" /> */}
                    {<ReactSVG src={juridiqueIcons} />}
                  </button>
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className=" pe-lg-4">
                <FormGroup className="form-icon icon-end">
                  <Label for="dateOfRegistration">Date d'immatriculation</Label>
                  <Controller
                    control={control}
                    name="dateOfRegistration"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          onChange={(date: any) => field.onChange(date)}
                          selected={field.value ? new Date(field.value) : null}
                          className="form-control form-secondary"
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                        />
                      );
                    }}
                  />
                  {/* <Input
                    id="dateOfRegistration"
                    innerRef={dateOfRegistrationRef}
                    {...dateOfRegistration}
                    type="date"
                    className="form-secondary"
                  /> */}
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={CalenderIconBlue} alt="icon" /> */}
                    {<ReactSVG src={juridiqueIcons} />}
                  </button>
                </FormGroup>
              </Col>
              <Col md={6} sm={12} className="ps-lg-4">
                <FormGroup className="form-icon icon-start">
                  <Label for="cqpe">Code Ape</Label>
                  <Input
                    id="cqpe"
                    innerRef={apeRef}
                    {...ape}
                    placeholder="Code Ape"
                    type="text"
                    className="form-secondary"
                  />
                  <button type="button" className="icon icon-secondary icon-secondary-fill">
                    {/* <img src={juridiqueIcons} alt="icon" /> */}
                    {<ReactSVG src={CalenderIconBlue} />}
                  </button>
                </FormGroup>
              </Col>
            </Row>

            {company?.pack !== AppPacks.Entrepreneur &&
              company?.pack !== AppPacks.BNCAssoc && (
                <>
                  <h3>TVA</h3>

                  <Row>
                    <Col md={6} sm={12} className="ps-lg-4 tva-info-col">
                      {getValues("subjectToVAT") !== undefined ? (
                        <div className="form-line mt-5 ">
                          <Label for="regimtva" className="me-4">
                            Assujetti à la TVA ?
                          </Label>
                          <div className="check-box check-secondary">
                            <input
                              type="radio"
                              id="radio-tva-1"
                              ref={subjectToVATRef}
                              {...subjectToVAT}
                              value={"false"}
                              defaultChecked={
                                String(getValues("subjectToVAT")) === "false"
                              }
                            />
                            <label htmlFor="radio-tva-1">Non</label>
                          </div>
                          <div className="check-box check-secondary">
                            <input
                              type="radio"
                              id="radio-tva-2"
                              ref={subjectToVATRef}
                              {...subjectToVAT}
                              value={"true"}
                              defaultChecked={
                                String(getValues("subjectToVAT")) === "true"
                              }
                            />
                            <label htmlFor="radio-tva-2">Oui</label>
                          </div>
                        </div>
                      ) : (
                        <Spinner color="info" type="border" size={"sm"}>
                          Loading...
                        </Spinner>
                      )}
                    </Col>
                    {String(getValues("subjectToVAT")) === "true" && (
                      <>
                        <Col md={4} sm={12} className="px-lg-4">
                          <FormGroup>
                            <Label for="regimtva">Regime TVA</Label>
                            <Controller
                              name="regimeTVA"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={
                                    String(company?.subjectToVAT) === "true"
                                      ? (VAT_REGIME_OPTIONS.filter(
                                          (elt) => elt.value !== "Non"
                                        ) as unknown as OptionType[])
                                      : (VAT_REGIME_OPTIONS as unknown as OptionType[])
                                  }
                                  closeMenuOnSelect={true}
                                  classNamePrefix="select"
                                  className="custom-select form-secondary"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4} sm={12} className="ps-lg-4">
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              )}

            {/* Bilan & Letter Mission */}
            {creds.role && creds?.role !== UserTypes.Client ? (
              <>
                <h3 className="mt-lg-5">Lettre de mission</h3>
                {company?.readyToSign ? (
                  <>
                    {getValues("letterMission") !== undefined ? (
                      <Row>
                        <Col md={6} sm={12}>
                          <div className="form-line ">
                            <Label for="regimtva" className="me-4">
                              Lettre de mission signée ?
                            </Label>
                            <div className="check-box check-secondary">
                              <input
                                type="radio"
                                id="radio-lettre-1"
                                ref={letterMissionRef}
                                {...letterMission}
                                value={"false"}
                                defaultChecked={
                                  String(getValues("letterMission")) === "false"
                                }
                              />
                              <label htmlFor="radio-lettre-1">Non</label>
                            </div>
                            <div className="check-box check-secondary">
                              <input
                                type="radio"
                                id="radio-lettre-2"
                                ref={letterMissionRef}
                                {...letterMission}
                                value={"true"}
                                defaultChecked={
                                  String(getValues("letterMission")) === "true"
                                }
                              />
                              <label htmlFor="radio-lettre-2">Oui</label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Spinner color="info" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    )}
                  </>
                ) : (
                  <>
                    <Row>
                      <Col md={6} sm={12}>
                        <div className="form-line ">
                          <Button
                            type="button"
                            color="secondary"
                            outline
                            disabled={loading}
                            onClick={async () => await sendReadyToSign()}
                          >
                            Déclencher la lettre de mission
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}

                <h3 className="mt-lg-5">Bilan</h3>

                {getValues("Bilan") !== undefined ? (
                  <Row>
                    <Col md={6} sm={12}>
                      <div className="form-line ">
                        <Label for="regimtva" className="me-4">
                          Activer l'onglet Bilan ?
                        </Label>
                        <div className="check-box check-secondary">
                          <input
                            type="radio"
                            id="radio-bilan-1"
                            ref={BilanRef}
                            {...Bilan}
                            value={"false"}
                            defaultChecked={
                              String(getValues("Bilan")) === "false"
                            }
                          />
                          <label htmlFor="radio-bilan-1">Non</label>
                        </div>
                        <div className="check-box check-secondary">
                          <input
                            type="radio"
                            id="radio-bilan-2"
                            ref={BilanRef}
                            {...Bilan}
                            value={"true"}
                            defaultChecked={
                              String(getValues("Bilan")) === "true"
                            }
                          />
                          <label htmlFor="radio-bilan-2">Oui</label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Spinner color="info" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                )}
              </>
            ) : (
              <></>
            )}

            <div className="text-center py-3 bottom-actions">
              {/* <Button type="button"
                color="secondary"
                outline
                onClick={function noRefCheck() {}}
              >
                Reinitialiser
              </Button> */}
              <Button color="secondary" type="submit" disabled={loading}>
                {loading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Enregistrer"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default InformationSociete;
