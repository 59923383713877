import { IIndemnity, IVehicule } from "../../interfaces";

const amountCalculator = (
  distance: number,
  prevDistance: number,
  rules: { coeff: number; addj: number }[],
  ranges: number[]
) => {
  let amount = 0;
  if (distance <= ranges[0]) {
    amount = Math.abs(distance - prevDistance) * rules[0].coeff + rules[0].addj;
  }
  if (distance >= ranges[0] && distance < ranges[1]) {
    if (prevDistance < ranges[0]) {
      amount =
        Math.abs(distance - ranges[0]) * rules[1].coeff +
        rules[1].addj +
        Math.abs(ranges[0] - prevDistance) * rules[0].coeff +
        rules[0].addj;
    } else {
      amount =
        Math.abs(distance - prevDistance) * rules[1].coeff;
    }
  }
  if (distance >= ranges[1]) {
    if (prevDistance < ranges[0]) {
      amount =
        Math.abs(distance - ranges[1]) * rules[2].coeff +
        rules[2].addj +
        Math.abs(ranges[1] - ranges[0]) * rules[1].coeff +
        rules[1].addj +
        Math.abs(ranges[0] - prevDistance) * rules[0].coeff +
        rules[0].addj;
    } else {
      amount =
        Math.abs(distance - ranges[1]) * rules[2].coeff +
        rules[2].addj +
        Math.abs(ranges[1] - prevDistance) * rules[1].coeff;
    }
  }
  return amount;
};

const amountFinder = (
  distance: number,
  vehicule: IVehicule,
  prevDistance: number
) => {
  let amount = 0;

  switch (vehicule.type) {
    case "car":
      if (vehicule.hp! <= 3) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.502, addj: 0 },
            { coeff: 0.3, addj: 1007 },
            { coeff: 0.3, addj: 0 },
          ],
          [5000, 20000]
        );
      }
      if (vehicule.hp === 4) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.575, addj: 0 },
            { coeff: 0.323, addj: 1262 },
            { coeff: 0.387, addj: 0 },
          ],
          [5000, 20000]
        );
      }
      if (vehicule.hp === 5) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.603, addj: 0 },
            { coeff: 0.339, addj: 1320 },
            { coeff: 0.405, addj: 0 },
          ],
          [5000, 20000]
        );
      }
      if (vehicule.hp === 6) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.631, addj: 0 },
            { coeff: 0.355, addj: 1382 },
            { coeff: 0.425, addj: 0 },
          ],
          [5000, 20000]
        );
      }
      if (vehicule.hp! >= 7) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.661, addj: 0 },
            { coeff: 0.374, addj: 1435 },
            { coeff: 0.446, addj: 0 },
          ],
          [5000, 20000]
        );
      }
      break;

    case "motorcycle":
      if (vehicule.hp! <= 2 && vehicule.hp! >= 1) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.375, addj: 0 },
            { coeff: 0.094, addj: 845 },
            { coeff: 0.234, addj: 0 },
          ],
          [3000, 6000]
        );
      }
      if ([3, 4, 5].includes(vehicule.hp!)) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.444, addj: 0 },
            { coeff: 0.078, addj: 1099 },
            { coeff: 0.261, addj: 0 },
          ],
          [3000, 6000]
        );
      }
      if (vehicule.hp! >= 5) {
        amount = amountCalculator(
          distance,
          prevDistance,
          [
            { coeff: 0.575, addj: 0 },
            { coeff: 0.075, addj: 1502 },
            { coeff: 0.325, addj: 0 },
          ],
          [3000, 6000]
        );
      }
      break;

    case "moped":
      amount = amountCalculator(
        distance,
        prevDistance,
        [
          { coeff: 0.299, addj: 0 },
          { coeff: 0.07, addj: 458 },
          { coeff: 0.162, addj: 0 },
        ],
        [3000, 6000]
      );
      break;

    default:
      break;
  }

  return amount;
};

const useIndemnity = () => {
  const calculateAmount = (
    vehicule: IVehicule,
    prevIndemnities: IIndemnity[],
    distance: number
  ) => {
    let totDistance =
      distance +
      prevIndemnities.reduce((acc, curr) => acc + (curr.kms || 0), 0);

    const amount = amountFinder(
      totDistance,
      vehicule,
      prevIndemnities.reduce((acc, curr) => acc + (curr.kms || 0), 0)
    );

    return amount;
  };

  return {
    calculateAmount,
  };
};

export { useIndemnity };
