import React, { useEffect, useState } from "react";
import "./TransactionsArchive.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { BootyPagination } from "../../../components/table/pagination";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import { Company, ITransaction } from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import axios from "axios";
import moment from "moment";
import {
  setCounter,
  setErrorMessage,
} from "../../../store/reducers/utils/utilSlice";
import UserOrangeIcon from "../../../assets/UserOrangeIcon";
import { FaEye } from "react-icons/fa";
import { useFormatter } from "../../../util/hooks/useFormatter";
import FileViewer from "../../../components/FileViewer";
import ArchiveIcon from "../../../assets/ArchiveIcon";
import { AppPacks, PayDiffKeys, UserTypes } from "../../../util/context";
import useAxios from "../../../util/hooks/useAxios";
import DeArchiveIcon from "../../../assets/DeArchiveIcon";
moment.updateLocale("fr", {});

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

const TransactionsArchive = ({
  context,
  company,
}: {
  context?: string;
  company?: Company;
}) => {
  const [open, setOpen] = useState(false);
  const [transactionId, setTransactionId] = useState<string | null>();
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const ontoggle = (id: string) => {
    setTransactionId(id);
    setOpen(true);
  };

  const { setDecimalDigits } = useFormatter();
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };
  let api = useAxios();

  const columns = React.useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: { date: Date }) => moment(row.date).format("DD/MM/YYYY"),
      },
      {
        name: "Transaction",
        selector: (row: any) => row.label,
        sortable: true,
      },
      {
        name: "Catégorie",
        selector: (row: any) => (row.sub_category ? row.sub_category.name : ""),
        sortable: true,
        cell: (row: any) => (
          <div className="file-table">
            <div className="list-Files transaction-file">
              <span className="file-box-item category">
                {row.sub_category ? row.sub_category.name : ""}
              </span>
            </div>
          </div>
        ),
      },
      {
        name: "Justificatif",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => (
          <>
            <div className="file-table">
              <div className="list-Files transaction-file">
                {row.document && (
                  <>
                    <span
                      className="file-box-item justif-viewer"
                      onClick={() => {
                        setUrl(row.document.url);
                        setViewModal(true);
                      }}
                    >
                      {/* <span>{row.document.key}</span> */}
                      <FaEye size={30} />
                    </span>
                  </>
                )}
              </div>
            </div>
          </>
        ),
      },
      {
        name: "Montant",
        selector: (row: any) => row.amount,
        sortable: true,
        cell: (row: any) => (
          <span
            className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      {
        name: "Tva",
        omit:
          company?.pack === AppPacks.Entrepreneur ||
          company?.pack === AppPacks.BNCAssoc ,
        cell: (row: ITransaction) =>
          row.vat ? `${parseFloat(row.vat).toFixed(2)} € TVA` : "",
        sortable: true,
      },
      {
        name: "Statut",
        selector: (row: any) =>
          company?.pack === AppPacks.Entrepreneur
            ? "Comptabilisée"
            : "Archivée",
        sortable: true,
      },
      {
        name:
          creds.role === UserTypes.Client
            ? company?.pack !== AppPacks.Entrepreneur
              ? "Désarchiver"
              : "Décomptabiliser"
            : "Action",
        button: true,
        cell: (row: ITransaction) => (
          <>
            <button
              className="btn btn-orange"
              onClick={async () => await deArchiveTransaction(row)}
            >
              <DeArchiveIcon />
            </button>
          </>
        ),
      },
    ],
    [company]
  );

  const [transactionsToValid, setTransactionsToValid] = useState<
    ITransaction[]
  >([]);

  const deArchiveTransaction = async (row: ITransaction) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id: row.id,
          status: 101,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== row.id)
      );

      if (
        row.sub_category.key === PayDiffKeys.OUT101 ||
        row.sub_category.key === PayDiffKeys.OUT100
      ) {
        await api.post(
          `/api/Transaction/UpdateWhere`,
          {
            where: {
              diffPay_id: row.id,
            },
            data: {
              status: 101,
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }
    } catch (error: any) {
      ErrorLogger("de-archiving a transaction", error);
    }
  };

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    // await getTransactions(page);
    setCurrentPage(page);
  };

  const getTransactions = async () => {
    try {
      const { data } = await api.post(
        `/api/Transaction/all`,
        {
          where: {
            status:
              company && company.pack === AppPacks.Entrepreneur ? 104 : 102,
            companyId: context,
            diffPay_id: {
              isNull: true,
            },
          },
          perPage: 20,
          pageIndex: currentPage,
          order: [
            ["date", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: transactionsData } = data;

      setTransactionsToValid(
        transactionsData.sort((a: { date: number }, b: { date: number }) => {
          return moment(b.date).diff(moment(a.date));
        })
      );
      setTotalRows(data.count);
    } catch (error: any) {
      ErrorLogger("getting non valid transactions", error);
    }
  };

  useEffect(() => {
    getTransactions();
  }, [currentPage]);

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <>
      <DataTable
        columns={columns}
        data={transactionsToValid}
        noDataComponent={<p>Il n'y a aucun data à afficher</p>}
        pagination
        paginationComponent={(props) => {
          const customProps = { ...props, color: "primary" };
          return <BootyPagination {...customProps} />;
        }}
        paginationServer
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        paginationPerPage={20}
      />
      <div className="openbtn text-center">
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </>
  );
};

export default TransactionsArchive;
