import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../../config";
import { setCounter } from "../../store/reducers/utils/utilSlice";

const { API_URL } = config[process.env.NODE_ENV];

const useAxios = () => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: { "x-access-token": creds.token },
  });

  const dispatch = useDispatch();

  axiosInstance.interceptors.response.use(
    async (res: any) => {
      return res;
    },
    (error: any) => {
      if (
        (creds.token &&
          creds.token !== "" &&
          !error.response.data.auth &&
          error.response.data.message &&
          error.response.data.message
            .toLowerCase()
            .includes("token rejected")) || 
            error.response.data.message &&
        error.response.data.message
          .toLowerCase()
          .includes("failed to authenticate token.")
      ) {
        dispatch(setCounter(4));
        toast.warn(
          "Votre session a expiré, vous serez redirigé vers la page de connexion",
          {
            closeButton: false,
            position: "top-right",
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            toastId: "toast-2022",
          }
        );
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxios;
